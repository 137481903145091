<h1 class="flex align-items-center flex-shrink-0">
	Suivi de Communication Locale
	<print-button-simple [track]="true" [track_mag_id]="mag_id"></print-button-simple>
</h1>

<!-- vertical -->
<p-table
	[value]="[1]"
	responsiveLayout="scroll"
	class="col-fixed mb-2"
	styleClass="p-datatable-sm p-datatable-gridlines"
>
	<ng-template pTemplate="body">
		<tr>
			<td class="cell-th">Année</td>
			<td class="text-right">
				<annee-selector
					name="annee"
					[(ngModel)]="annee"
					[nullIfInvalid]="true"
					[autoSelectCurrent]="true"
					[reverseOrder]="true"
					[minYear]="minYear"
					(ngModelChange)="anneeChange($event)"
					class="hide-print"
				></annee-selector>
				<span class="show-print">{{annee}}</span>
			</td>
		</tr>
		<tr>
			<td class="cell-th">Magasin</td>
			<td class="text-right">
				<magasin-selector
					#magasinSelector
					name="magasin"
					[(ngModel)]="mag_id"
					[nullOption]="false"
					[nullIfInvalid]="false"
					[mesMagasins]="false"
					[autoSelectFirst]="true"
					[date_debut]="dateDebut"
					[date_fin]="dateFin"
					(ngModelChange)="paramChanged()"
					[silentInitialChangeIfSame]="true"
					class="hide-print"
				></magasin-selector>
				<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
			</td>
		</tr>

		<ng-container *ngIf="budget">
			<tr>
				<td class="cell-th">CA Facturé prévu</td>
				<td class="text-right">
					<div class="p-input-icon-right input-container hide-print">
						<i class="pi ff-reset">€</i>
						<input
							type="text"
							pInputText
							name="buc_ca_prevu"
							gzInputNumberFormatter
							[(ngModel)]="budget.buc_ca_prevu"
							[currency]="true"
							[decimals]="2"
							class="text-right"
							(onDebounce)="caPrevuChanged()"
							[debounce]="500"
						>
					</div>
					<number-display
						[value]="budget.buc_ca_prevu"
						type="currency"
						class="show-print"
					></number-display>
				</td>
			</tr>

			<tr>
				<td class="cell-th">Budget de communication</td>
				<td class=" text-right">
					<number-display
						[value]="budget.buc_ca_prevu * taux_budget_communication"
						type="currency"
					></number-display>
				</td>
			</tr>

			<tr>
				<td class="cell-th">Budget de communication nationale</td>
				<td class=" text-right">
					<number-display
						[value]="budget.buc_ca_prevu * taux_budget_communication_nationale "
						type="currency"
					></number-display>
				</td>
			</tr>

			<tr>
				<td class="cell-th">Budget de communication locale</td>
				<td class=" text-right">
					<div class="p-input-icon-right input-container hide-print">
						<i class="pi ff-reset">€</i>
						<input
							type="text"
							pInputText
							name="buc_budget"
							gzInputNumberFormatter
							[(ngModel)]="budget.buc_budget"
							[currency]="true"
							[decimals]="2"
							class="text-right"
							(onDebounce)="saisieChanged()"
							[debounce]="500"
						>
					</div>
					<number-display
						[value]="budget.buc_budget"
						type="currency"
						class="show-print"
					></number-display>
				</td>
			</tr>
		</ng-container>


	</ng-template>
</p-table>

<table-communications-locales
	[mag_id]="mag_id"
	[annee]="annee"
	[buc_budget]="budget.buc_budget"
	*ngIf="mag_id && budget && budget.mag_id && budget.buc_annee"
></table-communications-locales>
