<h1 class="flex align-items-center flex-shrink-0">
	{{title}}
	<print-button-simple [track]="true" [track_mag_id]="params.mag_id"></print-button-simple>
</h1>

<!-- vertical -->
<div class="col-fixed flex-shrink-0 show-print">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed"
		styleClass="p-datatable-sm p-datatable-gridlines"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Mois</td>
				<td class="text-right">
					<span class="show-print">{{dateRange[0] | date: 'MM/YYYY'}}</span>
				</td>
			</tr>
			<tr>
				<td class="cell-th">Magasin</td>
				<td class="text-right">
					<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
				</td>
			</tr>
			<tr *ngIf="params['mode'] == 'vendeur'">
				<td class="cell-th">Vendeur</td>
				<td class="text-right">
					<span class="show-print">{{vendeurSelector.getEntityName()}}</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
	<div class="col-fixed" *ngIf="faitsMarquants">
		<div class="font-bold">Faits marquants</div>
		<pre>{{faitsMarquants}}</pre>
	</div>
</div>

<!-- horizontal -->
<div class="formgrid grid p-fluid flex-shrink-0 hide-print">
	<div class="field col-fixed">
		<label>Mois</label>
		<daterange-selector
			#dateRangeSelector
			class="hide-print"
			singleMode="mois"
			label=""
			[dateRange]="dateRange"
			[maxDate]="maxDate"
			(onDateChange)="onDateChange($event)"
			[disabled]="loading"
		></daterange-selector>
		<span class="show-print">{{dateRange[0] | date: 'MM/YYYY'}}</span>
	</div>
	<div class="field col-fixed">
		<label>Magasin</label>
		<magasin-selector
			#magasinSelector
			name="magasin"
			[(ngModel)]="params.mag_id"
			[nullOption]="false"
			[nullIfInvalid]="false"
			[mesMagasins]="false"
			[date_debut]="dateRange[0]"
			[date_fin]="dateRange[1]"
			[autoSelectFirst]="true"
			[silentInitialChangeIfSame]="true"
			[disabled]="loading"
			class="hide-print"
		></magasin-selector>
		<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
	</div>
	<div class="field col-fixed" [ngClass]="{'hide-print': !params.uti_id}">
		<label>Vendeur</label>
		<utilisateur-selector
			#vendeurSelector
			name="vendeur"
			[(ngModel)]="params.uti_id"
			[nullOption]="true"
			[nullIfInvalid]="true"
			[mag_id]="params.mag_id"
			[disabled]="loading"
			placeholder="Tous les vendeurs"
			(ngModelChange)="paramChanged()"
			class="hide-print"
		></utilisateur-selector>
		<span class="show-print">{{vendeurSelector.getEntityName()}}</span>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text hide-print"
			[disabled]="loading"
			[loading]="loading"
			(click)="load()"
		></button>
	</div>
	<div class="field col-12">
		<label class="block" for="faits_marquant">Faits marquants</label>
		<textarea
			pInputTextarea
			name="faits_marquant"
			class="max-w-50rem"
			[(ngModel)]="faitsMarquants"
			[autoResize]="true"
			[rows]="5"
		></textarea>
	</div>

</div>

<!-- vertical -->
<p-table
	[value]="[1]"
	[autoLayout]="true"
	responsiveLayout="scroll"
	class="col-fixed"
	styleClass="p-datatable-sm p-datatable-gridlines"
	*ngIf="value"
>
	<ng-template pTemplate="header">
		<tr>
			<th class="text-center">Indicateur</th>
			<th class="text-center col-label">{{dateRange[0] | date: 'MMMM YYYY' |titlecase}}</th>
			<th class="text-center col-label">Cumul Annuel (arrêté au mois sélectionné)</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body">
		<ng-container *ngFor="let indicateur of indicateurs">
			<ng-container *ngVar="indicateur.ind_code as ind_code">
				<tr *ngIf="indicateur.regroupements.includes(params.mode)">
					<td class="cell-th col-label">
						<indicateur-label-display [ind_code]="ind_code"></indicateur-label-display>
					</td>
					<td class="text-right" *ngIf="!(params.uti_id && ['nb_avis_google', 'note_google'].includes(ind_code))">
						<indicateur-value-display [ind_code]="ind_code" [value]="value['mois'][ind_code]"></indicateur-value-display>
					</td>
					<td class="text-right cell-input" *ngIf="params.uti_id && ['nb_avis_google', 'note_google'].includes(ind_code)">
						<input
							type="text"
							pInputText
							name="{{ind_code}}"
							gzInputNumberFormatter
							[(ngModel)]="value[ind_code]"
							[currency]="true"
							[decimals]="2"
							class="text-right hide-print"
						>
						<number-display
							[value]="value[ind_code]"
							class="show-print"
						></number-display>
					</td>
					<td class="text-right" *ngIf="!(params.mode == 'vendeur' && ['nb_avis_google', 'note_google'].includes(ind_code))">
						<indicateur-value-display [ind_code]="ind_code" [value]="value['cumul'][ind_code]"></indicateur-value-display>
					</td>
					<td class="disabled" *ngIf="params.mode == 'vendeur' && ['nb_avis_google', 'note_google'].includes(ind_code)">
					</td>
				</tr>
			</ng-container>
		</ng-container>
	</ng-template>
</p-table>


<!-- horizontal -->
<!-- <p-table
	[value]="[1]"
	[autoLayout]="true"
	responsiveLayout="scroll"
	scrollDirection="both"
	[scrollable]="true"
	scrollHeight="flex"
	class="col-fixed hide-print"
	styleClass="p-datatable-sm p-datatable-gridlines table-hover"
	*ngIf="value"
>
	<ng-template pTemplate="header">
		<tr>
			<th class="col-label" pFrozenColumn></th>
			<ng-container *ngFor="let indicateur of indicateurs">
				<th class="text-center col-indicateur" *ngIf="indicateur.regroupements.includes(params.mode)">
					<indicateur-label-display [ind_code]="indicateur.ind_code"></indicateur-label-display>
				</th>
			</ng-container>
		</tr>
	</ng-template>

	<ng-template pTemplate="body">
		<tr>
			<td class="cell-th" pFrozenColumn>
				{{dateRange[0] | date: 'MMMM YYYY' |titlecase}}
			</td>
			<ng-container *ngFor="let indicateur of indicateurs">
				<ng-container *ngVar="indicateur.ind_code as ind_code">
					<td class="text-right cell-input" *ngIf="indicateur.regroupements.includes(params.mode)">
						<ng-container *ngIf="!(params.uti_id && ['nb_avis_google', 'note_google'].includes(ind_code))">
							<indicateur-value-display [ind_code]="ind_code" [value]="value['mois'][ind_code]"></indicateur-value-display>
						</ng-container>
						<ng-container *ngIf="params.uti_id && ['nb_avis_google', 'note_google'].includes(ind_code)">
							<input
								type="text"
								pInputText
								name="{{ind_code}}"
								gzInputNumberFormatter
								[(ngModel)]="value[ind_code]"
								[currency]="true"
								[decimals]="2"
								class="text-right"
							>
							<number-display
								[value]="value[ind_code]"
								class="show-print"
							></number-display>
						</ng-container>
					</td>
				</ng-container>
			</ng-container>
		</tr>

		<tr>
			<td class="cell-th" pFrozenColumn>
				Cumul Annuel (arrêté au mois sélectionné)
			</td>
			<ng-container *ngFor="let indicateur of indicateurs">
				<ng-container *ngVar="indicateur.ind_code as ind_code">
					<td
						class="text-right cell-input"
						[ngClass]="{'disabled': params.mode == 'vendeur' && ['nb_avis_google', 'note_google'].includes(ind_code)}"
						*ngIf="indicateur.regroupements.includes(params.mode)"
					>
						<ng-container *ngIf="!(params.mode == 'vendeur' && ['nb_avis_google', 'note_google'].includes(ind_code))">
							<indicateur-value-display [ind_code]="ind_code" [value]="value['cumul'][ind_code]"></indicateur-value-display>
						</ng-container>
					</td>
				</ng-container>
			</ng-container>
		</tr>

	</ng-template>

</p-table>
 -->
