import { Injectable, OnDestroy } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, flatMap, catchError } from 'rxjs/operators';

import { DeclarationMensuelle } from '@app/declaration/declaration.model';
import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { prepareQueryParams, stringSort } from '@helpers/utils';

export type Magasin = {
	mag_id: number;
	ens_id: number;
	mag_uid: string;
	mag_nom: string;
	mag_nom_court: string;
	mag_region_commerciale: string;
	declaration_mensuelle?: DeclarationMensuelle;
	loading?: boolean;
}


@Injectable({ providedIn: 'root' })
export class MagasinService implements IEventListener {

	private mesMagasinsSubject = new BehaviorSubject<any>(null);

	constructor(
		private http: HttpClient,
		private eventManager: EventManagerService
	) {

		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.mesMagasinsSubject.next(null);
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	public getMagasinLabel(obj: any): string {
		let label;
		label = obj.mag_nom_court || obj.mag_nom;
		if (obj.mag_id) {
			label = `${label} (${obj.mag_id})`;
		}
		return label;
	}

	public prepareMagasin(magasin: any) {
		magasin.label = this.getMagasinLabel(magasin);
	}

	public get mesMagasins(): any {
		return this.mesMagasinsSubject.getValue();
	}

	public getDateButoir(mag_id: number) {
		return this.http.get<any>(`/magasins/${mag_id}/date_limite`)
		.pipe(
			map(
				(response: any) => {
					return response.date_limite;
				}
			)
		);
	}

	public getMesMagasins(): Observable<any> {
		return this.http.get<any>(`/mes_magasins`)
		.pipe(
			map(
				(response: any) => {
					this.mesMagasinsSubject.next(response);
					return response;
				}
			),
			catchError(
				(error: any) => {
					return throwError(error);
				}
			)
		);
	}

	public getList(params: any): Observable<any> {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/magasins_accessibles`, tmpParams);
	}

	public getRegionsCommerciales(): Observable<any> {
		return this.http.get<any>(`/regions_commerciales`);
	}

	public getMesRegionsCommerciales(): Observable<any> {
		return this.http.get<any>(`/mes_regions_commerciales`);
	}

	public regroupeMagasinParRegion(magasins: any, destinationAttr: string = 'magasins') {
		let regions: any[] = [];
		magasins.forEach((magasin: any) => {
			let index = regions.findIndex((one: any) => {return one.mag_region_commerciale == magasin.mag_region_commerciale; });
			if (index < 0) {
				let tmp: {[key: string]: any} = {
					mag_region_commerciale: magasin.mag_region_commerciale
				};
				tmp[destinationAttr] = [];
				regions.push(tmp);
				index = regions.length -1;
			}
			regions[index][destinationAttr].push(magasin);
		});

		regions.forEach((region: any) => {
			stringSort(region[destinationAttr], 'mag_nom_court');
		});
		stringSort(regions, 'mag_region_commerciale');

		return regions;
	}

}
