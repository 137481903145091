import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { ConfigService } from '@global/config.service';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { NumberDisplayModule } from '@helpers/number-display';
import { PrintButtonModule } from '@app/print-button';
import { ReportingService } from '@app/reporting/reporting.service';
import { StorageService } from '@global/storage.service';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';

import {
	endOfMonth,
	startOfMonth
} from '@helpers/utils';

@Component({
	selector: 'revue-mensuelle-activite-commerciale',
	templateUrl: './revue-mensuelle-activite-commerciale.html',
	providers: [],
})
export class RevueMensueleActiviteCommercialeComponent {

	@ViewChild('tableRevue') table: Table;

	value: any|null = null;
	loading: boolean;

	maxDate: Date;
	dateRange: Date[] = [];

	faitsMarquants: string = '';

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	params: {[key: string]: any} = {
		annee: null,
		mois: null,
		mag_id: null,
		uti_id: null,
		mode: 'magasin'
	}

	indicateurs: any = [
		{ind_code: 'nb_entrees', regroupements:['magasin', 'vendeur']},
		{ind_code: 'nb_ventes', regroupements:['magasin', 'vendeur']},

		{ind_code: 'ca_prise_commande_htple', regroupements:['magasin', 'vendeur']},
		{ind_code: 'mt_devis_non_vendus_htple', regroupements:['magasin', 'vendeur']},

		{ind_code: 'taux_marge_commerciale', regroupements:['magasin', 'vendeur']},
		{ind_code: 'mt_marge_commerciale_ht', regroupements:['magasin', 'vendeur']},

		{ind_code: 'mt_facture_ht', regroupements:['magasin']},
		{ind_code: 'nb_factures', regroupements:['magasin']},

		{ind_code: 'taux_marge_facture', regroupements:['magasin']},
		{ind_code: 'mt_marge_facture_ht', regroupements:['magasin']},

		{ind_code: 'taux_nb_devis_nb_entrees', regroupements:['magasin', 'vendeur']},
		{ind_code: 'taux_nb_ventes_nb_devis', regroupements:['magasin', 'vendeur']},
		{ind_code: 'taux_nb_ventes_nb_entrees', regroupements:['magasin', 'vendeur']},

		{ind_code: 'avg_vente_htple', regroupements:['magasin', 'vendeur']},
		{ind_code: 'avg_vente_ttc', regroupements:['magasin', 'vendeur']},

		{ind_code: 'nb_sav', regroupements:['magasin']},
		{ind_code: 'mt_sav', regroupements:['magasin']},

		{ind_code: 'note_wizville_moyenne', regroupements:['magasin', 'vendeur']},
		{ind_code: 'note_wizville_q1', regroupements:['magasin', 'vendeur']},
		{ind_code: 'note_wizville_q2', regroupements:['magasin', 'vendeur']},
		{ind_code: 'note_wizville_q3', regroupements:['magasin', 'vendeur']},
		{ind_code: 'note_wizville_q4', regroupements:['magasin', 'vendeur']},

		{ind_code: 'nb_avis_google', regroupements:['magasin', 'vendeur']}, // magasin ou vendeur suivant la sélection
		{ind_code: 'note_google', regroupements:['magasin', 'vendeur']}, // magasin ou vendeur suivant la sélection
	];

	title: string = '';

	constructor(
		private configService: ConfigService,
		private reportingService: ReportingService,
		private storageService: StorageService,
	) {
		this.title = this.configService.getCurrentModuleTitle();
		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.load();
			}
		});

		this.maxDate = new Date();
		this.maxDate.setMonth(this.maxDate.getMonth() -1);
		this.maxDate = startOfMonth(this.maxDate);
	}

	ngOnInit() {
		this.getParamSet();
	}

	saveParamSet() {
		this.storageService.setForCurrentState('params', this.params);
	}

	getParamSet() {
		this.params =  this.storageService.getForCurrentState('params', this.params);
		if (this.params.annee && this.params.mois) {
			let d: Date = new Date();
			d = startOfMonth(d);
			d.setFullYear(this.params.annee);
			d.setMonth(this.params.mois -1);
			if (d >= this.maxDate) {
				this.dateRange[0] = this.maxDate;
			}
			else {
				this.dateRange[0] = d;
			}
		}
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		this.params.annee = event.dateRange[0].getFullYear();
		this.params.mois = event.dateRange[0].getMonth() +1;
		this.dateRange[1] = endOfMonth(event.dateRange[0]);
		this.paramChanged();
	}

	paramChanged() {
		this.params.mode = this.params.uti_id? 'vendeur' : 'magasin';
		this.debouncedLoad.next(true);
	}

	load() {
		if (this.params.mag_id) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);

			this.saveParamSet();
			this.reportingService.getRevueMensuelleActiviteCommerciale(this.params, this.params.mag_id).subscribe({
				next: (response: any) => {
					this.value = response;
				}
			})
			.add(() => { this.loading = false; });
		}

	}

}
@NgModule({
	declarations: [
		RevueMensueleActiviteCommercialeComponent
	],
	exports: [
		RevueMensueleActiviteCommercialeComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		TableModule,
		DaterangeSelectorModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		PrintButtonModule,
		UtilisateurSelectorModule,
	],
})
export class RevueMensueleActiviteCommercialeModule { }
