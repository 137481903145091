import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AnalyticsService } from '@global/analytics.service';
import { ConfigService } from '@global/config.service';
import { IEventListener, EventManagerService } from '@global/event-manager.service';
import {
	calculateTaux,
	contrastingColor,
	createDownloadFromHttpResponse,
	cssVarValue,
	numericSort,
	percentTauxAndEvo,
	prepareQueryParams,
} from '@helpers/utils';


@Injectable({ providedIn: 'root' })
export class IndicateurService implements IEventListener {

	private indicateursSubject = new BehaviorSubject<any>(null);

	colors: any;

	constructor(
		private analyticsService: AnalyticsService,
		private http: HttpClient,
		private configService: ConfigService,
		private eventManager: EventManagerService
	) {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.indicateursSubject.next(null);
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	public get indicateurs(): any {
		return this.indicateursSubject.getValue();
	}

	getIndicateurs(): Observable<any> {
		return this.http.get<any>(`/indicateurs`)
		.pipe(
			map(
				(response: any) => {
					response.forEach((one: any) =>{
						one.ind_format = one.ind_format.toLowerCase();
					});
					this.indicateursSubject.next(response);
					return response;
				}
			),
			catchError(
				(error: any) => {
					return throwError(error);
				}
			)
		);
	}

	find(ind_code: string) {
		let tmpIndCode = ind_code;
		let indicateur = this.indicateurs.find((one: any) => {return one.ind_code == tmpIndCode;});
		if (!indicateur) {
			// test en enlevant le trigramme
			tmpIndCode = tmpIndCode.substring(4);
			indicateur = this.indicateurs.find((one: any) => {return one.ind_code == tmpIndCode;});
		}
		return indicateur;
	}

	prepColors() {
		this.colors = this.configService.config.couleurs_indicateurs;
		for(const ind_code in this.colors) {
			numericSort(this.colors[ind_code], 'seuil');
			this.colors[ind_code].reverse();
		}
	}

	getColorVar(ind_code: string, value: number, defaultCssVar?: boolean|string): string|null {
		if (!this.colors) {
			this.prepColors();
		}
		if (this.colors[ind_code]) {
			for (let i = 0; i < this.colors[ind_code].length; i++) {
				if (value/100 >= this.colors[ind_code][i].seuil) {
					return this.colors[ind_code][i].couleur;
				}
			}
		}
		if (defaultCssVar) {
			if (typeof defaultCssVar == 'string') {
				return defaultCssVar;
			}
			return '--primary-color';
		}
		return null;
	}

	getColorValue(ind_code: string, value: number, defaultCssVar?: boolean|string) {
		const cssVar  = this.getColorVar(ind_code, value, defaultCssVar);
		if (cssVar) {
			return cssVarValue(cssVar);
		}
		return null;
	}

	getCockpit(params: any) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_cockpit_valeurs', tmpParams.params);
		return this.http.get<any>(`/cockpit/blocs_infos`, tmpParams);
	}

	getEvolutionCA(params: any) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_cockpit_evolution_ca', tmpParams.params);
		return this.http.get<any>(`/cockpit/evolution_ca`, tmpParams);
	}

	prepareClassementMagasins(magasins: any[]) {
		magasins.forEach((magasin: any) => {
			magasin = this.prepareClassementMagasin(magasin);
		});
	}

	prepareClassementMagasin(magasin: any) {
		// calcul du taux uniquement si un objectif est défini
		if (magasin.objectif_ca_prise_commande_htple) {
			calculateTaux(magasin, 'ca_prise_commande_htple', 'objectif_ca_prise_commande_htple', 'taux_mt_ventes_htple_mt_objectif_htple', true);
		}
		return magasin;
	}

	prepareClassementVendeurs(vendeurs: any[]) {
		vendeurs.forEach((vendeur: any) => {
			vendeur = this.prepareClassementVendeur(vendeur);
		});
	}

	prepareClassementVendeur(vendeur: any) {
		vendeur.pou_couleur_texte = vendeur.pou_couleur && vendeur.pou_couleur.startsWith('#')? contrastingColor(vendeur.pou_couleur) : '';
		return vendeur;
	}

	getClassementMagasins(params: any) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_classement_magasins', tmpParams.params);
		return this.http.get<any>(`/classement/magasin`, tmpParams)
		.pipe(map(
			(response: any) => {
				this.prepareClassementMagasins(response.magasins);
				this.prepareClassementMagasin(response.total);
				return response;
			}
		));
	}

	exportClassementMagasin(params: any) {
		let tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_classement_magasins', tmpParams.params);
		return this.http.get<any>(`/classement/magasin/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	getClassementVendeurs(params: any) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_classement_vendeurs', tmpParams.params);
		return this.http.get<any>(`/classement/vendeur`, tmpParams)
		.pipe(map(
			(response: any) => {
				this.prepareClassementVendeurs(response.vendeurs);
				return response;
			}
		));
	}

	exportClassementVendeur(params: any) {
		let tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_classement_vendeurs', tmpParams.params);
		return this.http.get<any>(`/classement/vendeur/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	getClassementSatCli(params: any) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_classement_satcli', tmpParams.params);
		return this.http.get<any>(`/classement/satisfaction_client`, tmpParams);
	}

	exportClassementSatCli(params: any) {
		let tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_classement_satcli', tmpParams.params);
		return this.http.get<any>(`/classement/satisfaction_client/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	exportClassementSatCliGraphique = (params: any) => {
		let tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_graphique_satcli', tmpParams.params);
		return this.http.get<any>(`/classement/satisfaction_client/export_graphique`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	getIndicateursDigital(params: any, mag_id: number) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_indicateurs_digital', tmpParams.params);
		return this.http.get<any>(`/magasins/${mag_id}/indicateurs_digital`, tmpParams)
		.pipe(
			map((response: any) => {
				response.forEach((one: any) => {
					percentTauxAndEvo(one);
				});
				return response;
			})
		);
	}

	exportIndicateursDigital(params: any, mag_id: number) {
		let tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_indicateurs_digital', tmpParams.params);
		return this.http.get<any>(`/magasins/${mag_id}/indicateurs_digital/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	getRdvConfirmes(params: any, mag_id: number) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_rdv_confirmes', tmpParams.params);
		return this.http.get<any>(`/magasins/${mag_id}/rdv_confirmes`, tmpParams);
	}

}
