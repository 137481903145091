import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { prepareQueryParams } from '@helpers/utils';


@Injectable({ providedIn: 'root' })
export class UtilisateurService {



	constructor(
		private http: HttpClient,
	) {

	}


	getVendeurs(mag_id: number): Observable<any> {
		return this.http.get<any>(`/magasins/${mag_id}/vendeurs`);
	}

	getCollaborateurs(mag_id: number): Observable<any> {
		return this.http.get<any>(`/magasins/${mag_id}/collaborateurs`);
	}

	getVendeursPeriode(params: any) {
		let tmpParams = prepareQueryParams(params)
		return this.http.get<any>(`/cockpit/vendeurs`, tmpParams);
	}

}
