import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { BehaviorSubject, forkJoin } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from '@app/primeng-overrides/table';

import { ConfigService } from '@global/config.service';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { GlobalModule } from '@global/global.module';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule, MagasinSelectorComponent } from '@app/magasin/magasin-selector';
import { NumberDisplayModule } from '@helpers/number-display';
import { PrintButtonModule } from '@app/print-button';
import { ReportingService } from '@app/reporting/reporting.service';
import { Top10Module } from '@app/reporting/top10';
import { TopParMilieuModule } from '@app/reporting/top-par-milieu';

import { StorageService } from '@global/storage.service';

import {
	mergeArray,
	simpleDateToString,
	startOfDay,
} from '@helpers/utils';

@Component({
	selector: 'stats-par-code-postal',
	templateUrl: './stats-par-code-postal.html',

})
export class StatsParCodePostalComponent {

	@ViewChild('magasinSelector') magasinSelector: MagasinSelectorComponent;

	loading: boolean = false;
	loaded: boolean = false;

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	dateRange: Date[] = [];
	minDate: Date = startOfDay(new Date(2023, 0, 1));
	mag_id: number;

	today: Date = new Date();
	_top10Devis: any[] = [];
	top10Devis: any[] = [];
	_top10Ventes: any[] = [];
	top10Ventes: any[] = [];
	topMilieux: any[] = [];

	title: string = '';

	constructor(
		private configService: ConfigService,
		private reportingService: ReportingService,
		private storageService: StorageService,
	) {
		this.title = this.configService.getCurrentModuleTitle();

		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				if (!this.loaded) {
					this.loaded = true;
				}
				else {
					this.load();
				}
			}
		});
	}

	ngOnInit() {
		this.getParamSet();
	}

	getParamSet() {
		this.mag_id = this.storageService.getForCurrentState('mag_id');
	}

	saveParamSet() {
		this.storageService.setForCurrentState('mag_id', this.mag_id);
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		this.paramChanged();
	}

	paramChanged() {
		this.load();
	}


	load() {
		if (this.mag_id) {

			this.saveParamSet();
			let tmpParams = {
				mag_id: this.mag_id,
				date_debut: simpleDateToString(this.dateRange[0], true),
				date_fin: simpleDateToString(this.dateRange[1], true)
			};

			this.loading = true;
			this.loaded = false;

			forkJoin(
				this.getTop10Devis(tmpParams),
				this.getTop10Ventes(tmpParams),
			).subscribe({
				next: (response: any) => {
					this.topMilieux = mergeArray(this._top10Devis, this._top10Ventes, ['ope_code_postal_client', 'ope_ville_client']);
				}
			}).add(() => {this.loading = false;})
		}
	}

	getTop10Devis(params: any) {
		let tmpParams: any = Object.assign({champ: 'devis'}, params);
		return this.reportingService.getTop10(tmpParams, this.mag_id)
		.pipe(
			map(
				(response: any) => {
					this._top10Devis = response;
					this.top10Devis = this._top10Devis.slice(0, 10);
					response.forEach((item: any) => {
						item['taux'] = item['taux_devis'] *100;
					})
				}
			)
		);
	}

	getTop10Ventes(params: any) {
		let tmpParams: any = Object.assign({champ: 'vente'}, params);
		return this.reportingService.getTop10(tmpParams, this.mag_id).pipe(
			map(
				(response: any) => {
					this._top10Ventes = response;
					this.top10Ventes = this._top10Ventes.slice(0, 10);
					response.forEach((item: any) => {
						item['taux'] = item['taux_ventes'] *100;
					})
				}
			)
		);
	}

}
@NgModule({
	declarations: [
		StatsParCodePostalComponent
	],
	exports: [
		StatsParCodePostalComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		InputNumberModule,
		TableModule,
		GlobalModule,
		DaterangeSelectorModule,
		IndicateurValueDisplayModule,
		IndicateurTooltipModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		PrintButtonModule,
		TopParMilieuModule,
		Top10Module,
	],
})
export class StatsParCodePostalModule { }
