import { Component, NgModule, ViewChild  } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { DaterangeSelectorModule } from '@app/daterange-selector';
import { GlobalModule } from '@global/global.module';

import { AuthService } from '@app/auth/auth.service';
import { ConfigService } from '@global/config.service';
import { IndicateurService } from '@app/indicateur/indicateur.service';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';

import { RegionCommercialeSelectorModule } from '@app/magasin/region-commerciale-selector';

import { StorageService } from '@global/storage.service';

import {
	endOfDay,
	simpleDateToString,
	startOfDay,
} from '@helpers/utils';

@Component({
	selector: 'classement-magasins',
	templateUrl: './classement-magasins.html',
	providers: [],
})
export class ClassementMagasinsComponent {

	@ViewChild('tableClassement') table: Table;

	values: any[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = 999;
	exportLoading: boolean;

	total: any;

	dateRange: Date[] = [];
	maxDate: Date = new Date();

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	params: {[key: string]: any} = {
		region_commerciale: null,
	}

	indicateurs = [
		'ca_prise_commande_htple',
		'objectif_ca_prise_commande_htple',
		'ecart_realisation_objectif',
		'taux_mt_ventes_htple_mt_objectif_htple'
	];

	droitsCanExport = [
		'direction_reseau',
		'manager',
		'chef_ventes',
		'centrale',
		'informatique',
		'animateur_region'
	];
	canExport: boolean = false;

	title: string = '';

	constructor(
		private authService: AuthService,
		private configService: ConfigService,
		private indicateurService: IndicateurService,
		private storageService: StorageService,
	) {
		this.title = this.configService.getCurrentModuleTitle();
		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.refresh();
			}
		});
	}

	ngOnInit() {
		this.getParamSet();
		this.canExport = this.authService.checkIfHasRight(this.droitsCanExport);
	}

	saveParamSet() {
		this.storageService.setForCurrentState('region_commerciale', this.params.region_commerciale);
	}

	getParamSet() {
		this.params.region_commerciale =  this.storageService.getForCurrentState('region_commerciale', null);
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		this.paramChanged();
	}

	paramChanged() {
		this.debouncedLoad.next(true);
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	load($event: any) {
		// // https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);

		let tmpParams = Object.assign({
			date_debut: simpleDateToString(startOfDay(this.dateRange[0])),
			date_fin: simpleDateToString(endOfDay(this.dateRange[1])),
		}, $event, this.params);

		this.saveParamSet();
		this.indicateurService.getClassementMagasins(tmpParams).subscribe({
			next: (response: any) => {
				// this.totalRecords = response.total;
				this.values = response.magasins;
				this.total = response.total;
			}
		})
		.add(() => { this.loading = false; });
	}

	export() {
		this.exportLoading = true;

		const tmpParams = Object.assign({
			date_debut: simpleDateToString(startOfDay(this.dateRange[0])),
			date_fin: simpleDateToString(endOfDay(this.dateRange[1])),
		}, this.params);

		this.indicateurService.exportClassementMagasin(tmpParams)
		.subscribe()
		.add(() => {
			this.exportLoading = false;
		});
	}

}
@NgModule({
	declarations: [
		ClassementMagasinsComponent
	],
	exports: [
		ClassementMagasinsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		TableModule,
		DaterangeSelectorModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		RegionCommercialeSelectorModule,
	],
})
export class ClassementMagasinsModule { }
