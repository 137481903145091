import { NgModule, Component, Input, Output, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	NgForm,
	FormsModule,
	Validators,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule, Dropdown } from '@app/primeng-overrides/dropdown';


import { ChallengeService, Challenge } from '@app/challenge/challenge.service';
import { hasTouch } from '@helpers/utils';

@Component({
	selector: 'challenge-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ChallengeSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[showClear]="nullOption"
			optionValue="cha_id"
			[filter]="options.length > 5"
			[autofocusFilter]="!hasTouch"
			filterBy="cha_libelle,cha_reference_externe"
			[filterPlaceholder]="filterPlaceholder"
			appendTo="body"
		>
			<ng-template let-item pTemplate="selectedItem">
				<div class="selector-item">
					<div>{{item.cha_libelle || item.label}}
						<span *ngIf="!item.cha_date_fin">
							({{item.cha_date_debut |date:'dd/MM/yy'}})
						</span>
						<span *ngIf="item.cha_date_fin">
							({{item.cha_date_debut|date:'dd/MM/yy'}} -> {{item.cha_date_fin|date:'dd/MM/yy'}})
						</span>
					</div>
				</div>
			</ng-template>
			<ng-template let-item pTemplate="item">
				<div class="selector-item">
					<div>{{item.cha_libelle || item.label}}
						<span *ngIf="!item.cha_date_fin">
							({{item.cha_date_debut |date:'dd/MM/yy'}})
						</span>
						<span *ngIf="item.cha_date_fin">
							({{item.cha_date_debut|date:'dd/MM/yy'}} -> {{item.cha_date_fin|date:'dd/MM/yy'}})
						</span>
					</div>
				</div>
			</ng-template>
		</p-dropdown>
	`
})
export class ChallengeSelectorComponent implements OnInit, ControlValueAccessor {

	@Input('value') innerValue: number|null|undefined;
	@Input() name: string;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() filterPlaceholder: string = 'Filtrer...';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() autoSelectIfSingle: boolean;
	@Input() autoSelectFirst: boolean;
	@Input() silentInitialChangeIfSame: boolean;

	@Output() onSelectionChange: EventEmitter<any> = new EventEmitter();

	filters: {[key: string]: any} = {};
	options: any[] = [];
	initialized: boolean = false;
	hasTouch: boolean = hasTouch();

	constructor(private challengeService: ChallengeService) {

	}

	ngOnInit() {
		this.challengeService.getChallenges()
		.subscribe({
			next: (response: any) => {
				this.setOptions(response);
			}
		});
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: number|null|undefined) {
		this.innerValue = value;
		this.onChange(value);
		this.onSelectionChange.emit(this.getSelectedEntity());
		this.onTouched();
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(value: number|null|undefined) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

	getSelectedEntity() {
		const found = this.options.find(one => { return one.cha_id == this.innerValue; });
		if (found) {
			return found
		}
		return null;
	}

	setOptions(options: any[]) {
		this.options = options;
		setTimeout(() => {
			this.setInitialValue();
		}, 0)
	}

	setInitialValue() {
		const incoming = this.innerValue;
		if (this.options.length) {
			if (this.options.findIndex(one => {return one.cha_id == this.innerValue; }) < 0) {
				if (this.nullIfInvalid) {
					this.innerValue = null;
				}
				if (this.autoSelectFirst || this.autoSelectIfSingle && this.options.length === 1) {
					this.innerValue = this.options[0].cha_id;
				}
			}
		}
		if (incoming != this.innerValue || !this.silentInitialChangeIfSame) {
			this.value = this.innerValue;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [ChallengeSelectorComponent],
	declarations: [ChallengeSelectorComponent]
})
export class ChallengeSelectorModule { }
