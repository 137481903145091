import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { EvenementService } from '@app/evenement/evenement.service';
import { EventManagerService } from '@global/event-manager.service';
import { GlobalModule } from '@global/global.module';

import { StorageService } from '@global/storage.service';
import {
	endOfDay,
	simpleDateToString,
	startOfDay,
} from '@helpers/utils';

@Component({
	selector: 'rdv-confirmes-dialog',
	templateUrl: './rdv-confirmes-dialog.html',
	providers: []
})
export class RdvConfirmesDialogComponent {

	@ViewChild('tableRdv', {static: true}) table: Table;

	values: any[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = 10;
	exportLoading: boolean;

	dateRange: Date[] = [];
	mag_id: number;

	params: {[key: string]: any} = {
		search: null,
	}

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private evenementService: EvenementService,
		private eventManager: EventManagerService,
		private storageService: StorageService,
	) {

		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.refresh();
			}
		});

		this.dateRange = (this.config.data.dateRange)? this.config.data.dateRange : [];
		this.mag_id = this.config.data.mag_id;

	}

	ngOnInit(): void {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.dialogRef.close();
		});
		// this.getParamSet();
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}


	getParamSet() {

	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		this.paramChanged();
	}

	saveParamSet() {

	}

	paramChanged() {
		this.debouncedLoad.next(true);
	}

	reset() {
		this.params.search = null;
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	load($event: any) {
		// il faut une date de fin
		if (this.dateRange && this.dateRange[1]) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);
			// this.saveParamSet();

			let tmpParams = Object.assign({
				date_debut: simpleDateToString(startOfDay(this.dateRange[0])),
				date_fin: simpleDateToString(endOfDay(this.dateRange[1])),
			}, $event, this.params);

			this.loading = true;
			this.evenementService.getRdvConfirmes(tmpParams, this.mag_id)
			.subscribe({
				next: (response: any) => {
					this.totalRecords = response.total;
					this.values = response.rdv;
				}
			})
			.add(() => { this.loading = false; });
		}
	}

}
@NgModule({
	declarations: [
		RdvConfirmesDialogComponent
	],
	exports: [
		RdvConfirmesDialogComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		TableModule,
		GlobalModule,
		DaterangeSelectorModule,
	],
})
export class RdvConfirmesDialogModule { }
