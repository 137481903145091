<h1 class="flex align-items-center flex-shrink-0">
	{{title}}
	<print-button-simple [track]="true" [track_mag_id]="mag_id"></print-button-simple>
</h1>


<div class="grid">
 	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Magasin</td>
				<td class="text-right">
					<magasin-selector
						#magasinSelector
						name="magasin"
						[(ngModel)]="mag_id"
						[nullOption]="false"
						[nullIfInvalid]="false"
						[mesMagasins]="false"
						[autoSelectFirst]="true"
						[silentInitialChangeIfSame]="true"
						(ngModelChange)="paramChanged()"
						class="hide-print"
					></magasin-selector>
					<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
				</td>
			</tr>
			<tr *ngIf="simulation">
				<td class="cell-th">Nombre d'entrées mensuel moyen ({{anneeMoins1}})</td>
				<td class="text-right">
					<input
						type="text"
						pInputText
						name="nb_entrees_moyen"
						gzInputNumberFormatter
						[(ngModel)]="saisies.nb_entrees_moyen"
						class="text-right hide-print"
						(onDebounce)="paramChanged()"
						[debounce]="500"
					>
					<number-display
						[value]="saisies.nb_entrees_moyen"
						class="show-print"
					></number-display>
				</td>
			</tr>
			<tr *ngIf="simulation">
				<td class="cell-th">Panier moyen de mon magasin ({{anneeMoins1}})</td>
				<td class=" text-right">
					<div class="p-input-icon-right input-container hide-print">
						<i class="pi ff-reset">€</i>
						<input
							type="text"
							pInputText
							name="panier_moyen"
							gzInputNumberFormatter
							[(ngModel)]="saisies.panier_moyen"
							[currency]="true"
							[decimals]="2"
							class="text-right"
							(onDebounce)="paramChanged()"
							[debounce]="500"
						>
					</div>
					<number-display
						[value]="saisies.panier_moyen"
						type="currency"
						class="show-print"
					></number-display>
				</td>
			</tr>
		</ng-template>
	</p-table>

	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
		*ngIf="simulation"
	>
		<ng-template pTemplate="header">
			<tr>
				<th></th>
				<th class="text-center col-label">Analyse avec Ratios Cible</th>
				<th class="text-center col-label">Analyse avec Ratios Performeurs</th>
				<th class="text-center col-label">Analyse avec Ratios Personnalisés</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body">
			<tr class="text-sm font-italic">
				<td class="nowrap">Ratio devis/entrées</td>
				<td class="text-right">
					<indicateur-value-display ind_code="taux_nb_devis_nb_entrees" [value]="config.ratio_devis_entree_cible *100"></indicateur-value-display>
				</td>
				<td class="text-right">
					<indicateur-value-display ind_code="taux_nb_devis_nb_entrees" [value]="config.ratio_devis_entree_performeurs *100"></indicateur-value-display>
				</td>
				<td class="text-right cell-input">
					<div class="p-input-icon-right input-container hide-print">
						<i class="pi ff-reset">%</i>
						<input
							type="text"
							pInputText
							name="taux_nb_devis_nb_entrees_personnalise"
							gzInputNumberFormatter
							[decimals]="0"
							[(ngModel)]="saisies.ratio_nb_devis_nb_entrees_personnalise"
							class="text-right hide-print"
							(onDebounce)="paramChanged()"
							[debounce]="500"
						>
					</div>
					<number-display
						[value]="saisies.ratio_nb_devis_nb_entrees_personnalise"
						class="show-print"
					></number-display>
			</tr>
			<tr>
				<td class="cell-th nowrap">Objectifs nb devis</td>
				<td class="text-right">
					<indicateur-value-display ind_code="nb_devis" [value]="simulation.objectif_nb_devis_cible"></indicateur-value-display>
				</td>
				<td class="text-right">
					<indicateur-value-display ind_code="nb_devis" [value]="simulation.objectif_nb_devis_performeurs"></indicateur-value-display>
				</td>
				<td class="text-right">
					<indicateur-value-display ind_code="nb_devis" [value]="simulation.objectif_nb_devis_personnalise"></indicateur-value-display>
				</td>
			</tr>
			<tr class="text-sm font-italic">
				<td class=" nowrap">Ratio ventes/devis</td>
				<td class="text-right">
					<indicateur-value-display ind_code="taux_nb_ventes_nb_devis" [value]="config.ratio_vente_devis_cible *100"></indicateur-value-display>
				</td>
				<td class="text-right">
					<indicateur-value-display ind_code="taux_nb_ventes_nb_devis" [value]="config.ratio_vente_devis_performeurs *100"></indicateur-value-display>
				</td>
				<td class="text-right cell-input">
					<div class="p-input-icon-right input-container hide-print">
						<i class="pi ff-reset">%</i>
						<input
							type="text"
							pInputText
							name="taux_nb_ventes_nb_devis_personnalise"
							gzInputNumberFormatter
							[decimals]="0"
							[(ngModel)]="saisies.ratio_nb_ventes_nb_devis_personnalise"
							class="text-right hide-print"
							(onDebounce)="paramChanged()"
							[debounce]="500"
						>
					</div>
					<number-display
						[value]="saisies.ratio_nb_ventes_nb_devis_personnalise"
						class="show-print"
					></number-display>
			</tr>
			<tr>
				<td class="cell-th nowrap">Objectifs nb ventes</td>
				<td class="text-right">
					<indicateur-value-display ind_code="nb_ventes" [value]="simulation.objectif_nb_ventes_cible"></indicateur-value-display>
				</td>
				<td class="text-right">
					<indicateur-value-display ind_code="nb_ventes" [value]="simulation.objectif_nb_ventes_performeurs"></indicateur-value-display>
				</td>
				<td class="text-right">
					<indicateur-value-display ind_code="nb_ventes" [value]="simulation.objectif_nb_ventes_personnalise"></indicateur-value-display>
				</td>
			</tr>
			<tr>
				<td class="cell-th nowrap">CA potentiel mensuel</td>
				<td class="text-right">
					<indicateur-value-display ind_code="ca_prise_commande_htple" [value]="simulation.ca_potentiel_mensuel_cible"></indicateur-value-display>
				</td>
				<td class="text-right">
					<indicateur-value-display ind_code="ca_prise_commande_htple" [value]="simulation.ca_potentiel_mensuel_performeurs"></indicateur-value-display>
				</td>
				<td class="text-right">
					<indicateur-value-display ind_code="ca_prise_commande_htple" [value]="simulation.ca_potentiel_mensuel_personnalise"></indicateur-value-display>
				</td>
			</tr>
			<tr>
				<td class="cell-th nowrap">CA potentiel annuel</td>
				<td class="text-right">
					<indicateur-value-display ind_code="ca_prise_commande_htple" [value]="simulation.ca_potentiel_annuel_cible"></indicateur-value-display>
				</td>
				<td class="text-right">
					<indicateur-value-display ind_code="ca_prise_commande_htple" [value]="simulation.ca_potentiel_annuel_performeurs"></indicateur-value-display>
				</td>
				<td class="text-right">
					<indicateur-value-display ind_code="ca_prise_commande_htple" [value]="simulation.ca_potentiel_annuel_personnalise"></indicateur-value-display>
				</td>
			</tr>
		</ng-template>
	</p-table>
</div>
