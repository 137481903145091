import { Component, NgModule, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { AuthService } from '@app/auth/auth.service';
import { ChallengeSelectorModule } from '@app/challenge/challenge-selector';
import { ConfigService } from '@global/config.service';
import { DaterangeSelectorModule, DaterangeSelectorComponent } from '@app/daterange-selector';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurService } from '@app/indicateur/indicateur.service';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { RegionCommercialeSelectorModule } from '@app/magasin/region-commerciale-selector';

import { StorageService } from '@global/storage.service';

import {
	endOfDay,
	simpleDateToString,
	startOfDay,
} from '@helpers/utils';

@Component({
	selector: 'classement-vendeurs',
	templateUrl: './classement-vendeurs.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassementVendeursComponent {

	@ViewChild('tableClassement') table: Table;
	@ViewChild('dateRangeSelector') dateRangeSelector: DaterangeSelectorComponent;

	values: any[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = 20;
	exportLoading: boolean;

	dateRange: Date[] = [];
	minDate: Date;
	defaultMinDate: Date;
	maxDate: Date = new Date();
	defaultMaxDate: Date = new Date();

	disableDateRangeModeChange: boolean = false;

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	params: {[key: string]: any} = {
		region_commerciale: null,
		cha_id: null
	}

	droitsCanExport = [
		'direction_reseau',
		'manager',
		'chef_ventes',
		'centrale',
		'informatique',
		'animateur_region'
	];
	canExport: boolean = false;

	title: string = '';

	constructor(
		private authService: AuthService,
		private changeDetectorRef: ChangeDetectorRef,
		private configService: ConfigService,
		private indicateurService: IndicateurService,
		private storageService: StorageService,
	) {
		this.title = this.configService.getCurrentModuleTitle();
		this.defaultMinDate = new Date(this.configService.config.annee_reference, 0, 1);

		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.refresh();
			}
		});
	}

	ngOnInit() {
		this.getParamSet();
		this.canExport = this.authService.checkIfHasRight(this.droitsCanExport);
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (tableParams[prop]) this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
		}
		this.params.region_commerciale =  this.storageService.getForCurrentState('region_commerciale', null);
	}

	saveParamSet() {
		this.storageService.setForCurrentState('region_commerciale', this.params.region_commerciale);
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);
		}
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		this.paramChanged();
	}

	paramChanged() {
		this.debouncedLoad.next(true);
	}

	onChallengeChange(event: any) {
		if (!event) {
			this.minDate = this.defaultMinDate;
			this.maxDate = this.defaultMaxDate;
			this.disableDateRangeModeChange = false;
			this.dateRangeSelector.resetDates();
			this.paramChanged();
		}
		else {
			this.disableDateRangeModeChange = true;
			this.minDate = event.cha_date_debut;
			if (event.cha_date_fin) {
				this.maxDate = event.cha_date_fin;
				if (this.maxDate > this.defaultMaxDate) {
					this.maxDate = this.defaultMaxDate;
				}
			}
			else {
				this.maxDate = this.defaultMaxDate;
			}
			this.dateRangeSelector.mode = 'libre';
			this.dateRangeSelector.setDates([event.cha_date_debut, event.cha_date_fin]);
		}
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	load($event: any) {

		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);

		let tmpParams = Object.assign({
			date_debut: simpleDateToString(startOfDay(this.dateRange[0])),
			date_fin: simpleDateToString(endOfDay(this.dateRange[1])),
		}, $event, this.params);

		this.saveParamSet();
		this.indicateurService.getClassementVendeurs(tmpParams)
		.subscribe({
			next: (response: any) => {
				this.totalRecords = response.total;
				this.values = response.vendeurs;
			}
		})
		.add(() => {
			this.loading = false;
			this.changeDetectorRef.markForCheck();
		})
	}

	export() {
		this.exportLoading = true;

		const tmpParams = Object.assign({
			date_debut: simpleDateToString(startOfDay(this.dateRange[0])),
			date_fin: simpleDateToString(endOfDay(this.dateRange[1])),
		}, this.params);

		this.indicateurService.exportClassementVendeur(tmpParams)
		.subscribe()
		.add(() => {
			this.exportLoading = false;
			this.changeDetectorRef.markForCheck();
		});
	}

}
@NgModule({
	declarations: [
		ClassementVendeursComponent
	],
	exports: [
		ClassementVendeursComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		TableModule,
		ChallengeSelectorModule,
		DaterangeSelectorModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		RegionCommercialeSelectorModule,
	],
})
export class ClassementVendeursModule { }
