import { StateService, Transition } from '@uirouter/core';

import { AuthService } from '@app/auth/auth.service';
import { ConfigService } from '@global/config.service';
import { MagasinService } from '@app/magasin/magasin.service';
import { IndicateurService } from '@app/indicateur/indicateur.service';

export const startRefreshTokenTimerResolve = {
	token: 'startRefreshTokenTimer',
	deps: [AuthService],
	resolveFn: (authService: AuthService) => {
		authService.startRefreshTokenTimer()
		return true;
	}
}

export const configResolve = {
	token: 'config',
	deps: [AuthService, ConfigService],
	resolveFn: (authService: AuthService, configService: ConfigService): Promise<any>|void =>  {
		if (authService.user)	{
			return configService.getConfig().toPromise()
			.then(
				(response: any) => {
					return true;
				},
				(error: any) => {
					return authService.logout({force: true});
				}
			);
		}

	}
}

export const mesMagasinsResolve = {
	token: 'mes_magasins',
	deps: [AuthService, MagasinService],
	resolveFn: (authService: AuthService, magasinService: MagasinService): Promise<any>|void =>  {
		if (authService.user)	{
			return magasinService.getMesMagasins().toPromise()
			.then(
				(response: any) => {
					return true;
				},
				(error: any) => {
					return authService.logout({force: true});
				}
			);
		}
	}
}

export const indicateursResolve = {
	token: 'indicateurs',
	deps: [AuthService, IndicateurService, 'config'],
	resolveFn: (authService: AuthService, indicateurService: IndicateurService, config: any): Promise<any>|void =>  {
		if (authService.user) {
			return indicateurService.getIndicateurs().toPromise()
			.then(
				(response: any) => {
					return true;
				},
				(error: any) => {
					return authService.logout({force: true});
				}
			);
		}
	}
}
