<form class="" #entreeLibreForm="ngForm">
	<div class="formgrid grid p-fluid">
		<div class="field col-fixed">
			<label for="range">Date</label>
			<div class="flex">
				<p-calendar
					name="eve_date_evenement"
					[(ngModel)]="evenement.eve_date_evenement"
					[showTime]="true"
					[minDate]="minDate"
					[maxDate]="maxDate"
					[showIcon]="true"
					[readonlyInput]="hasTouch"
					appendTo="body"
					[required]="true"
				></p-calendar>
			</div>
		</div>

		<div class="field col-fixed" *ngIf="!force_vendeur">
			<label for="range">Collaborateur</label>
			<utilisateur-selector
				name="uti_vendeur"
				[(ngModel)]="evenement.uti_vendeur"
				[nullIfInvalid]="false"
				[required]="true"
				[mag_id]="mag_id"
				[disabled]="loading"
			></utilisateur-selector>
		</div>
		<div class="field ">
			<label for="range">Client</label>
			<input
				type="text"
				pInputText
				name="eve_client"
				[(ngModel)]="evenement.eve_client"
				[disabled]="loading"
				placeholder="Prénom NOM (optionnel)"
			>
		</div>
	</div>



	<div class="field p-field-checkbox flex flex-column justify-content-end" *ngIf="evenement.eve_entree">
		<div>
			<p-checkbox
				name="eve_prise_rdv"
				inputId="eve_prise_rdv"
				[(ngModel)]="evenement.eve_prise_rdv"
				[binary]="true"
			></p-checkbox>
			<label for="eve_prise_rdv">Inclure dans Nouveau RDV</label>
		</div>
	</div>

</form>

<div class="p-dialog-footer text-right">
	<button
		type="button"
		pButton
		class="btn p-button-sm p-button-text"
		label="Annuler"
		[disabled]="loading"
		(click)="cancel()"
	></button>
	<button
		type="button" pButton
		class="btn p-button-sm"
		label="Enregistrer"
		[disabled]="!entreeLibreForm.form.valid || loading"
		(click)="submit()"
	></button>
</div>
