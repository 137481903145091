import { NgModule, Component, Input, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	NgForm,
	FormsModule,
	Validators,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule, Dropdown } from '@app/primeng-overrides/dropdown';

import { ConfigService } from '@global/config.service';


@Component({
	selector: 'milieu-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MilieuSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[required]="required"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class MilieuSelectorComponent implements OnInit, ControlValueAccessor {

	@Input('value') innerValue: string|null|undefined;
	@Input() name: string;
	@Input() nullIfInvalid: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() disabled: boolean;
	@Input() required: boolean;

	options: any[] = [];

	constructor(private configService: ConfigService) {

	}

	ngOnInit() {
		this.options = this.configService.config.milieux;
		setTimeout(() => {this.setInitialValue();}, 0);
	}

	setInitialValue() {
		if (this.options.findIndex(one => {return one == this.innerValue; }) < 0) {
			if (this.nullIfInvalid) {
				this.innerValue = null;
			}
			else if (this.options.length){
				this.innerValue = this.options[0];
			}
		}
		this.value = this.innerValue;
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: string|null|undefined) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(value: string|null|undefined) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [MilieuSelectorComponent],
	declarations: [MilieuSelectorComponent]
})
export class MilieuSelectorModule { }
