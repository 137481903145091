import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule, Table } from '@app/primeng-overrides/table';
import { TooltipModule } from 'primeng/tooltip';

import { ConfigService } from '@global/config.service';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { ExportGraphiqueDialogComponent } from '@app/export-graphique-dialog';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurService } from '@app/indicateur/indicateur.service';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { RegionCommercialeSelectorModule } from '@app/magasin/region-commerciale-selector';
import { StorageService } from '@global/storage.service';

import {
	simpleDateToString,
	startOfDay,
	startOfMonth
} from '@helpers/utils';

@Component({
	selector: 'classement-satcli',
	templateUrl: './classement-satcli.html',
})
export class ClassementSatCliComponent {

	@ViewChild('tableClassement', {static: true}) table: Table;

	loading: boolean = false;
	exportLoading: boolean = false;

	values: any[] = [];
	totalRecords: number = 0;
	rows: number = 50;

	maxDate: Date;
	defaultDate: Date;
	selectedDate: Date;
	today: Date = new Date();

	optionsMode = [
		{label: 'Magasin', value:'magasin'},
		{label: 'Vendeur', value:'vendeur'},
	];

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	params: {[key: string]: any} = {
		region_commerciale: null,
		mode: 'magasin'
	}

	indicateursWizville: any[] = [
		{ind_code: 'note_wizville', modes:['magasin', 'vendeur']},
		{ind_code: 'satisfaction_q1', modes:['magasin', 'vendeur']},
		{ind_code: 'satisfaction_q2', modes:['magasin', 'vendeur']},
		{ind_code: 'satisfaction_q3', modes:['magasin', 'vendeur']},
		{ind_code: 'satisfaction_q4', modes:['magasin', 'vendeur']},
		{ind_code: 'satisfaction_q3_nps', modes:['magasin', 'vendeur']},
		{ind_code: 'satisfaction_q4_nps', modes:['magasin', 'vendeur']},
		{ind_code: 'nb_avis_wizville', modes:['magasin', 'vendeur']},
		{ind_code: 'taux_reponse_wizville', modes:['magasin']},
		{ind_code: 'delai_traitement_insatisfaction_jours', modes:['magasin'], tooltipIcon: true},
		{ind_code: 'taux_insatisfaction', modes:['magasin', 'vendeur']},
	]

	indicateursGoogle: any[] = [
		{ind_code: 'note_google', modes:['magasin'], tooltipIcon: true},
		{ind_code: 'nb_avis_google', modes:['magasin']},
		{ind_code: 'nb_avis_google_total', modes:['magasin']},
		{ind_code: 'taux_reponse_google', modes:['magasin']},
	];

	indicateurs: any[] = [...this.indicateursWizville, ...this.indicateursGoogle];

	objectifsNationaux: any;

	title: string = '';

	constructor(
		private configService: ConfigService,
		private dialogService: DialogService,
		private indicateurService: IndicateurService,
		private storageService: StorageService,
	) {
		this.title = this.configService.getCurrentModuleTitle();
		this.maxDate = startOfMonth(new Date());
		this.maxDate.setMonth(this.maxDate.getMonth() -1);
		this.defaultDate = new Date(this.maxDate);
		// les indicateurs stacli étant renseignés le 1er pour le mois précédent,
		// si on est le premier du mois, mettre 2 mois en arrière pour la date par défaut
		if (this.today.getDate() < 2) {
			this.defaultDate.setMonth(this.defaultDate.getMonth() -1);
		}

		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.refresh();
			}
		});
	}

	ngOnInit() {
		this.getParamSet();
	}

	saveParamSet() {
		this.storageService.setForCurrentState('region_commerciale', this.params.region_commerciale);
		this.storageService.setForCurrentState('mode', this.params.mode);
		if (this.table) {
			let tableParams = {
				first: this.table.first,
				rows: this.table.rows,
				sortField: this.table.sortField,
				sortOrder: this.table.sortOrder
			};
			this.storageService.setForCurrentState('tableParams', tableParams, true);
		}
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (tableParams[prop]) this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
			if (tableParams.sortField) this.table.sortField = tableParams.sortField;
			if (tableParams.sortOrder) this.table.sortOrder = tableParams.sortOrder;
		}
		else {
			this.table.sortField = 'note_wizville';
			this.table.sortOrder = -1;
		}
		this.params.region_commerciale =  this.storageService.getForCurrentState('region_commerciale', null);
		this.params.mode =  this.storageService.getForCurrentState('mode', 'magasin');
	}

	onDateChange(event: any) {
		this.selectedDate = event.dateRange[0];
		this.paramChanged();
	}

	paramChanged() {
		this.debouncedLoad.next(true);
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	load(event?: any) {
		// // https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);
		this.values = [];

		let tmpParams = Object.assign({
			date: simpleDateToString(startOfDay(this.selectedDate)),
		}, event, this.params);

		const annee = this.selectedDate.getFullYear();

		this.saveParamSet();
		this.indicateurService.getClassementSatCli(tmpParams)
		.subscribe({
			next: (response: any) => {
				this.totalRecords = response.total;
				this.values = response.classement;
				this.objectifsNationaux = response.objectifs_nationaux;
			},
		})
		.add(() => { this.loading = false; });
	}

	isIndicateurWizville(ind_code: string) {
		return this.indicateursWizville.some((one: any) => {return one.ind_code == ind_code;});
	}

	isIndicateurGoogle(ind_code: string) {
		return this.indicateursGoogle.some((one: any) => {return one.ind_code == ind_code;});
	}

	goalReached(value: number, ind_code: string): number {
		const key = `ona_${ind_code}`;
		if (this.objectifsNationaux && typeof this.objectifsNationaux[key] != 'undefined') {
			return (value >= this.objectifsNationaux[key]) ? 1 : -1;
		}
		return 0;
	}

	export() {
		this.exportLoading = true;
		let tmpParams = Object.assign({
			date: simpleDateToString(startOfDay(this.selectedDate)),
		}, this.params);
		this.indicateurService.exportClassementSatCli(tmpParams)
		.subscribe()
		.add(() => {
			this.exportLoading = false;
		});
	}

	showExportGraphiqueDialog() {
		const ref = this.dialogService.open(ExportGraphiqueDialogComponent, {
			data: {
				// mag_id: this.mag_id,
				maxDate: this.maxDate,
				exportFunc: this.indicateurService.exportClassementSatCliGraphique
			},
			header: 'Export Graphiques SATCLI',
		});

		ref.onClose.subscribe((dialogResult: any) =>{

		});
	}

}
@NgModule({
	declarations: [
		ClassementSatCliComponent
	],
	exports: [
		ClassementSatCliComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		SelectButtonModule,
		TableModule,
		TooltipModule,
		DaterangeSelectorModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		RegionCommercialeSelectorModule,
	],
})
export class ClassementSatCliModule { }
