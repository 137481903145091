<div class="formgrid grid p-fluid">

	<div class="field col-fixed">
		<label for="range">Vendeur</label>
		<utilisateur-selector
			name="uti_vendeur"
			[(ngModel)]="uti_vendeur"
			[nullIfInvalid]="false"
			[required]="true"
			[mag_id]="mag_id"
			[disabled]="loading"
		></utilisateur-selector>
	</div>

</div>

<div class="p-dialog-footer text-right">
	<button pButton type="button" class="btn p-button-sm" label="Enregistrer" [disabled]="loading" (click)="submit()"></button>
	<button pButton type="button" class="btn p-button-sm p-button-text" label="Annuler" [disabled]="loading" (click)="cancel()"></button>
</div>

