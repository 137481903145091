import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';

import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';

import { EventManagerService, IEventListener } from '@global/event-manager.service';

@Component({
	selector: 'vendeur-dialog',
	templateUrl: './vendeur-dialog.html'
})
export class VendeurDialogComponent implements OnInit, IEventListener {

	mag_id: number;
	uti_vendeur: any;
	loading: boolean;

	constructor(
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private config: DynamicDialogConfig,
	) {
		this.mag_id = this.config.data.mag_id;
		this.uti_vendeur = this.config.data.uti_vendeur;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		this.dialogRef.close(this.uti_vendeur);
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		UtilisateurSelectorModule,
	],
	exports: [VendeurDialogComponent],
	declarations: [VendeurDialogComponent]
})
export class VendeurDialogModule { }

