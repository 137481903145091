<form class="" #exportGraphiqueForm="ngForm">
	<div class="formgrid grid p-fluid">
		<div class="field col-fixed">
			<label for="range">Magasin</label>
			<magasin-selector
				name="magasin"
				[(ngModel)]="mag_id"
				[required]="true"
				[mesMagasins]="false"
				[nullIfInvalid]="false"
				[autoSelectFirst]="true"
				[disabled]="loading"
			></magasin-selector>
		</div>
	</div>

</form>

<div class="p-dialog-footer text-right">
	<button
		type="button"
		pButton
		class="btn p-button-sm p-button-text"
		label="Annuler"
		[disabled]="loading"
		(click)="cancel()"
	></button>
	<button
		type="button"
		pButton
		class="btn p-button-sm"
		icon="pi pi-download"
		label="Exporter"
		[disabled]="!exportGraphiqueForm.form.valid"
		[loading]="loading"
		(click)="submit()"
	></button>
</div>
