import { Component, NgModule, OnInit, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';

import { ConfigService } from '@global/config.service';
import { StorageService } from '@global/storage.service';
import {
	endOfDay,
	endOfMonth,
	hasTouch,
	startOfDay,
	startOfMonth,
} from '@helpers/utils';

@Component({
	selector: 'daterange-selector',
	templateUrl: './daterange-selector.html',
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DaterangeSelectorComponent implements OnInit {

	@Input() label: string = 'Période';
	@Input() dateRange: Date[] = [];
	@Input() singleMode: 'annee'|'mois'|'libre';
	@Input() modes: string[];
	@Input() rangeMode: boolean;
	@Input() disabled: boolean;
	@Input() disableModeChange: boolean;
	@Input() useStorage: boolean;
	@Input() storagePrefix: string = '';
	@Input() minDate: Date;
	@Input() maxDate: Date = new Date();
	@Input() defaultStart: Date = startOfDay(new Date());
	@Input() defaultEnd: Date = endOfDay(new Date());
	@Input() usePivot: boolean = true;
	@Input() showPreviousCurrentMonth: boolean = false;

	@Output() onModeChange: EventEmitter<any> = new EventEmitter();
	@Output() onDateChange: EventEmitter<any> = new EventEmitter();

	hasTouch: boolean = hasTouch();

	dateRangeControl: Date[] = [];
	singleMonth: Date;
	mode: string = 'libre';
	today: Date = new Date();
	datePivot: Date = new Date();
	dateFormat: string;

	availableModes = [
		{label: 'Année', value: 'annee'},
		{label: 'Mois', value: 'mois'},
		{label: 'Libre', value: 'libre'}
	];

	options: any[] = [];

	constructor(
		private configService: ConfigService,
		private storageService: StorageService,
	) {
		this.datePivot.setDate(this.configService.config.pivot_no_jour);
	}

	ngOnInit() {
		if (!this.modes) {
			this.options = [...this.availableModes];
		}
		else {
			this.options = this.availableModes.filter((mode: any) => {
				return this.modes.includes(mode.value);
			})
		}

		// initialize outside angular to avoid an ExpressionChangedAfterItHasBeenCheckedError
		Promise.resolve(null).then(() => {
			if (!this.minDate) {
				this.minDate = new Date(this.configService.config.annee_reference, 0, 1);
			}
			if (this.usePivot && this.today <= this.datePivot) {
				this.defaultStart.setMonth(this.defaultStart.getMonth() -1);
			}
			this.defaultStart = startOfMonth(this.defaultStart);

			let tmpRangeDates;
			if (this.singleMode) {
				this.mode = this.singleMode;
			}
			if (this.useStorage) {
				this.mode = this.storageService.getForCurrentState(`${this.storagePrefix}mode`) || this.mode;
				tmpRangeDates = this.storageService.getForCurrentState(`${this.storagePrefix}dateRange`);
			}
			else {
				tmpRangeDates = this.dateRange;
			}
			this.setDateFormat();
			this.setDates(tmpRangeDates);
		});
	}

	changeMode() {
		this.setDateFormat();
		this.setDates(this.dateRange);
	}

	get sameMonth() {
		return this.dateRange[0]
			&& this.dateRange[1]
			&& this.dateRange[0].getFullYear() == this.dateRange[1].getFullYear()
			&& this.dateRange[0].getMonth() == this.dateRange[1].getMonth()

	}

	setDateFormat() {
		switch (this.mode) {
			case 'annee':
				this.dateFormat = 'yy';
				break;
			case 'mois':
				this.dateFormat = 'mm/yy';
				break;
			case 'libre':
				this.dateFormat = 'dd/mm/yy';
				break;
		}
	}

	setDates(dateRange?: any) {
		let tmpDebut = new Date(this.defaultStart);
		if (this.mode == 'libre') {
			if (this.usePivot && this.today <= this.datePivot) {
				this.defaultEnd = endOfMonth(this.defaultStart);
			}
			else {
				this.defaultEnd = new Date();
			}
		}
		else if (this.mode == 'mois') {
			this.defaultEnd = endOfMonth(this.defaultStart);
		}
		else if (this.mode == 'annee') {
			this.defaultEnd = new Date(this.defaultStart.getFullYear() +1, 0, -1);
		}
		this.defaultEnd = endOfDay(this.defaultEnd);

		let tmpFin = new Date(this.defaultEnd);
		if (dateRange) {
			if (dateRange[0]) {
				tmpDebut = new Date(dateRange[0]);
			}
			if (dateRange[1]) {
				if (dateRange[1] < tmpDebut) {
					tmpFin = endOfMonth(tmpDebut);
				}
				else {
					tmpFin = new Date(dateRange[1]);
				}
			}
			if (this.mode == 'annee') {
				let annee = tmpDebut.getFullYear();
				tmpDebut = new Date(annee, 0, 1, 0, 0 , 0);
				tmpFin = new Date(annee, 11, 31, 23, 59, 59);
			}
			else if (this.mode == 'mois') {
				tmpDebut = startOfMonth(tmpDebut);
				if (!this.rangeMode) {
					tmpFin = endOfMonth(tmpDebut);
				}
				else {
					tmpFin = endOfMonth(tmpFin);
				}
			}
			else {
				if (tmpFin > this.maxDate) {
					tmpFin = new Date(this.maxDate);
				}
				if (tmpFin < tmpDebut) {
					tmpFin = endOfDay(tmpDebut);
				}
			}

		}
		this.dateRange = [tmpDebut, endOfDay(tmpFin)];
		this.dateRangeControl = [...this.dateRange];
		if (this.useStorage) {
			this.saveParamSet();
		}
		this.onDateChange.emit({mode: this.mode, dateRange: this.dateRange});
	}

	resetDates() {
		this.setDates()
	}

	isResetable() {
		if (
			!this.dateRange
			|| this.dateRange.length != 2
			|| this.dateRange[1] == null
			|| this.dateRange[0].getTime() !== this.defaultStart.getTime()
			|| this.dateRange[1].getTime() !== this.defaultEnd.getTime()
		) {
			return true;
		}
		return false;
	}

	saveParamSet() {
		this.storageService.setForCurrentState(`${this.storagePrefix}mode`, this.mode);
		this.storageService.setForCurrentState(`${this.storagePrefix}dateRange`, this.dateRange);
	}

	selectCurrentMonth(modifier: number = 0) {
		let tmp = new Date(this.today);
		if (modifier != 0) {
			tmp.setMonth(tmp.getMonth() + modifier);
		}
		this.setDates([startOfMonth(tmp), endOfMonth(tmp)]);
	}

}
@NgModule({
	declarations: [
		DaterangeSelectorComponent
	],
	exports: [
		DaterangeSelectorComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		SelectButtonModule,
	],
})
export class DaterangeSelectorModule { }
