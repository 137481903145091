<div id="details-ventes" class="flex flex-column h-full">
	<div class="grid justify-content-between flex-shrink-0">
		<div class="col formgrid grid p-fluid">
			<div class="field col-fixed">
				<daterange-selector
					label="Période libre"
					[dateRange]="dateRange"
					singleMode="libre"
					[useStorage]="false"
					[disabled]="loading"
					(onDateChange)="onDateChange($event)"
				></daterange-selector>
			</div>

			<div class="field col-fixed">
				<label for="range">Magasin</label>
				<magasin-selector
					name="magasin"
					[(ngModel)]="mag_id"
					[required]="true"
					[mesMagasins]="false"
					[nullIfInvalid]="false"
					[disabled]="loading"
					(ngModelChange)="paramChanged()"
				></magasin-selector>
			</div>

			<div class="field col-fixed">
				<label for="range">Vendeur</label>
				<utilisateur-selector
					name="vendeur"
					[(ngModel)]="uti_id"
					[nullOption]="true"
					[nullIfInvalid]="true"
					[autoSelectIfSingle]="true"
					[mag_id]="mag_id"
					[periode]="true"
					[date_debut]="dateRange[0]"
					[date_fin]="dateRange[1]"
					[disabled]="loading"
					placeholder="Tous les vendeurs"
					(ngModelChange)="paramChanged()"
				></utilisateur-selector>
			</div>

			<div class="field col-fixed flex flex-column justify-content-end">
				<button
					type="button"
					pButton
					icon="pi pi-refresh"
					class="p-button-text"
					[loading]="loading"
					(click)="refresh()"
				></button>
			</div>

		</div>

		<div class="col-fixed formgrid grid p-fluid">

		</div>

	</div>

	<div class="min-table-height">
		<p-table
			#tableDetailVentes
			[value]="values"
			[lazy]="true"
			(onLazyLoad)="load($event)"
			[lazyLoadOnInit]="false"
			[loading]="loading"
			[paginator]="true"
			paginatorDropdownAppendTo="body"
			[rows]="rows"
			[rowsPerPageOptions]="[10,25,50]"
			[showCurrentPageReport]="true"
			[totalRecords]="totalRecords"
			currentPageReportTemplate="{totalRecords} élément(s)"
			[autoLayout]="true"
			responsiveLayout="scroll"
			scrollDirection="both"
			[scrollable]="true"
			scrollHeight="flex"
			styleClass="p-datatable-sm p-datatable-gridlines"
		>
			<ng-template pTemplate="header">
				<ng-container>
					<tr *ngIf="totalRecords != 0">
						<th class="text-center" pFrozenColumn>Date</th>
						<th class="text-center">Vendeur</th>
						<th class="text-center">Client/Milieu</th>
						<th class="text-center">N°&nbsp;Option WINNER</th>
						<th class="text-center">Montant HT&nbsp;HP&nbsp;HL</th>
						<th class="text-center">Prix d'achat&nbsp;HT</th>
						<th class="text-center">Montant budget&nbsp;TTC</th>
						<th class="text-center">Devis du</th>
						<th class="text-center">Date création fiche client</th>
					</tr>
				</ng-container>
			</ng-template>

			<ng-container>
				<ng-template pTemplate="emptymessage">
					<tr>
						<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-evenement let-rowIndex="rowIndex">
					<tr [ngClass]="{'eve-masque': evenement.eve_masque, 'readonly': evenement.readonly}">
						<td class="nowrap" pFrozenColumn>
							{{ evenement.eve_date_evenement | date: 'dd/MM/yyyy HH:mm'}}
						</td>
						<td class="" title="{{evenement.uti_prenom}} {{evenement.uti_nom}}">
							{{ evenement.uti_prenom }} {{ evenement.uti_nom }}
						</td>

						<td class="">
							<!-- client/milieu -->
							<div>{{ evenement.eve_client }}</div>
							<div class="flex align-items-baseline text-sm">
								<div *ngIf="evenement.eve_milieu">
									{{ evenement.eve_milieu }}
								</div>
								<div *ngIf="!evenement.eve_milieu">
									Aucun milieu
								</div>
							</div>
						</td>

						<td class="text-right nowrap">
							{{ evenement.eve_option_id }}
						</td>

						<td class="text-right nowrap">
							<!-- montant htple -->
							<number-display
								[value]="evenement.eve_mt_vente_total_htple"
								type="currency"
								numberFormat=".0-0"
							></number-display>
						</td>
						<td class="text-right nowrap">
							<number-display
								[value]="evenement.eve_mt_achat_ht"
								type="currency"
								numberFormat=".0-0"
							></number-display>
						</td>

						<td class="text-right nowrap">
							<number-display
								[value]="evenement.eve_mt_budget_ttc"
								type="currency"
								numberFormat=".0-0"
							></number-display>
						</td>

						<td class="nowrap text-center">
							{{ evenement.eve_date_devis | date: 'dd/MM/yyyy'}}
							<span *ngIf="evenement.eve_date_devis_diff">
								({{ evenement.eve_date_devis_diff }})
							</span>
						</td>

						<td class="nowrap text-center">
							{{ evenement.eve_date_creation_fiche_client | date: 'dd/MM/yyyy'}}
							<span *ngIf="evenement.eve_date_creation_fiche_client_diff">
								({{ evenement.eve_date_creation_fiche_client_diff }})
							</span>
						</td>

					</tr>
				</ng-template>
			</ng-container>
		</p-table>
	</div>
</div>
