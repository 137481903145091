import { Component, NgModule, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DropdownModule, Dropdown } from '@app/primeng-overrides/dropdown';

import { CommandService } from '@app/command/command.service';
import { GlobalModule } from '@global/global.module';
import { StorageService } from '@global/storage.service';
import { EventManagerService } from '@global/event-manager.service';


@Component({
	selector: 'commands',
	templateUrl: './commands.html',
	providers: [],
})
export class CommandsComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	loadingLogs: boolean = false;
	logs: string = '';

	refreshLogTimeout: number|undefined;

	commands = [
		{label: 'Import FOC', value: 'import-foc'},
		{label: 'Import BI', value: 'import-bi'},
		{label: 'Import BI Encaissements', value: 'import-bi-encaissement'},
	];

	selectedCommand: string;

	constructor(
		private commandService: CommandService,
		private eventManager: EventManagerService,
		private storageService: StorageService,
	) {

	}

	ngOnInit() {
		this.selectedCommand = this.storageService.getForCurrentState('command');
		if (this.selectedCommand) {
			this.refreshLog(false);
		}
	}

	ngOnDestroy() {
		this.stopRefreshLogTimer();
	}

	selectedCommandChange(event: any) {
		this.storageService.setForCurrentState('command', this.selectedCommand);
		this.refreshLog(false);
	}

	startRefreshLogTimer(): void {
		this.stopRefreshLogTimer();
		this.refreshLogTimeout = setTimeout(() => {
			this.refreshLog(true);
		}, 5000);
	}

	stopRefreshLogTimer() {
		clearTimeout(this.refreshLogTimeout);
	}

	executeCommand() {
		this.loading = true;
		let params = {
			commande: this.selectedCommand
		};
		this.refreshLog(true);
		this.commandService.execute(params)
		.subscribe({
			next: (response: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Commande exécutée'});
			},
		})
		.add(() => {
			this.refreshLog(false);
			this.loading = false;
		});
	}

	refreshLog(autoRefesh: boolean) {
		this.loadingLogs = true;
		this.commandService.getLogs(this.selectedCommand, {nb: 30})
		.subscribe({
			next: (response: any) => {
				this.logs = response;
				if (autoRefesh) {
					this.startRefreshLogTimer();
				}
				else {
					this.stopRefreshLogTimer();
				}
			}
		})
		.add(() => { this.loadingLogs = false; });
	}



}
@NgModule({
	declarations: [
		CommandsComponent
	],
	exports: [
		CommandsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		ButtonModule,
		DropdownModule,
	],
})
export class CommandsModule { }
