import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { ObjectifsService, DetailPlanActions } from '@app/objectifs/objectifs.service';
import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { hasTouch } from '@helpers/utils';

@Component({
	selector: 'detail-plan-actions-dialog',
	templateUrl: './detail-plan-actions-dialog.html'
})
export class DetailPlanActionsDialogComponent implements OnInit, IEventListener {

	detail: DetailPlanActions;

	loading: boolean = false;
	hasTouch: boolean = hasTouch();

	today: Date = new Date();
	minDate: Date;

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private objectifsService: ObjectifsService,
		private eventManager: EventManagerService,
	) {
		this.detail	= this.config.data.detailPlanActions;
		if (this.detail.aud_date_creation) {
			this.minDate = new Date(this.detail.aud_date_creation);
		}
		else {
			this.minDate = new Date(this.today);
		}
		this.minDate.setMonth(this.minDate.getMonth() -2);
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		this.loading = true;
		if (!this.detail.dpa_id) {
			this.objectifsService.postDetailPlanActions(this.detail)
			.subscribe({
				next: (response: any) => {
					// this.eventManager.emit('toast', {severity: 'success', summary: `Plan d'action créé`});
					this.dialogRef.close(this.detail);
				}
			})
			.add(() => { this.loading = false; });
		}
		else {
			this.objectifsService.putDetailPlanActions(this.detail)
			.subscribe({
				next: (response: any) => {
					// this.eventManager.emit('toast', {severity: 'success', summary: `Plan d'action modifié`});
					this.dialogRef.close(this.detail);
				}
			})
			.add(() => { this.loading = false; });
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		InputTextModule,
		InputTextareaModule,
	],
	exports: [DetailPlanActionsDialogComponent],
	declarations: [DetailPlanActionsDialogComponent]
})
export class DetailPlanActionsDialogModule { }

