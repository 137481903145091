import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { EvenementService, Evenement, OptionEvenement } from '@app/evenement/evenement.service';

import { EventManagerService, IEventListener } from '@global/event-manager.service';


@Component({
	selector: 'commentaire-dnv-dialog',
	templateUrl: './commentaire-dnv-dialog.html'
})
export class CommentaireDNVDialogComponent implements OnInit, IEventListener {

	loading: boolean;
	evenement: Evenement;
	optionEvenement: OptionEvenement;

	constructor(
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private config: DynamicDialogConfig,
		private evenementService: EvenementService,
	) {
		this.evenement = config.data.evenement;
		this.optionEvenement = this.evenement.option_evenement!;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		this.loading = true;
		if (typeof this.optionEvenement.ope_commentaire == 'string') {
			this.optionEvenement.ope_commentaire = this.optionEvenement.ope_commentaire.trim();
		}
		this.evenementService.putOptionEvenement(this.optionEvenement)
		.subscribe({
			next: (response: any) => {
				this.dialogRef.close(this.optionEvenement);
			}
		})
		.add(() => { this.loading = false; });
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextareaModule
	],
	exports: [CommentaireDNVDialogComponent],
	declarations: [CommentaireDNVDialogComponent]
})
export class CommentaireDNVDialogModule { }

