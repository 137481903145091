import { Component, NgModule, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TreeNode } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';

import { TreeTableModule, TreeTable } from '@app/primeng-overrides/treetable';
import { ObjectifsService, ObjectifMagasin, ObjectifVendeur } from '@app/objectifs';

import { GlobalModule } from '@global/global.module';
import { ConfigService } from '@global/config.service';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { NumberDisplayModule } from '@helpers/number-display';
import { AnneeSelectorModule } from '@app/annee-selector';
import { StorageService } from '@global/storage.service';
import {
	arrayOfMonths,
	capitalize,
	clone,
	isDefined,
	padString,
	stringSort,
	uid,
} from '@helpers/utils';

export type LigneTotal = {
	header: string;
	oma_ca_prise_commande_htple: number;
	objectifs_magasins: ObjectifMagasin[];
}

@Component({
	selector: 'objectifs-vendeur',
	templateUrl: './objectifs-vendeur.html',
	providers: [DatePipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectifsVendeurComponent {

	@ViewChild('tableObjectifs') table: TreeTable;

	columns: any[];
	frozen_columns: any[];
	objectifs: TreeNode[] = [];

	rangeMois: Date[] = [];

	loading: boolean = false;
	exportLoading: boolean = false;
	minYear: number;
	maxYear: number;
	annee: number;

	ind_code: string = 'ca_prise_commande_htple';

	valueBeforeEdit: any;

	search: string|undefined;
	searchableAttrs = [
		'uti_nom',
		'uti_prenom',
	];

	collapsedRows: {[key: string]: any} = {};

	tooltipMissingObjectifMagasin: string = 'Les objectifs magasins ne sont pas définis pour ce mois. Ils sont nécessaires pour pouvoir définir les objectifs vendeurs.';

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private datePipe: DatePipe,
		private objectifsService: ObjectifsService,
		private configService: ConfigService,
		private storageService: StorageService,
	) {
		this.minYear = configService.config.annee_reference;
		this.maxYear = new Date().getUTCFullYear() +1;
	}

	ngOnInit() {
		this.getParamSet();
	}

	indexTracker(index: number, item: any) {
		if (item.node && item.node.uid) return item.node.uid;
		return item.ove_mois || index;
	}

	saveParamSet() {
		this.storageService.set('annee', this.annee);
		this.storageService.setForCurrentState('collapsedRows', this.collapsedRows);
	}

	getParamSet() {
		this.annee = this.storageService.get('annee');
		this.collapsedRows = this.storageService.getForCurrentState('collapsedRows', {});
	}

	filter() {
		let hasVisible: boolean = false;

		this.objectifs.forEach((one :any) => {
			if (one.children) {
				one.data.hidden = !this.setVisibleState(one.children, this.search);
			}
		});
		this.objectifs = [...this.objectifs];
	}

	setVisibleState(nodes: any, search?: string): boolean {
		let hasVisible: boolean = false;
		nodes.forEach((one :any) => {
			if (this.patternMatch(one.data, search)) {
				one.data.hidden = false;
				hasVisible = true;
			}
			else {
				one.data.hidden = true;
			}
		});
		return hasVisible;
	}

	patternMatch(obj: any, search?: string) {
		search = search? search : '';
		const re = new RegExp(`.*${search}.*`, 'i');

		for (let i = 0; i < this.searchableAttrs.length; i++) {
			if (obj[this.searchableAttrs[i]] && re.test(obj[this.searchableAttrs[i]])) {
				return true;
			}
		}
		return false;
	}

	resetFilter() {
		this.search = undefined;
		this.filter();
	}

	onNodeExpand(event: any) {
		let mag_id = event.node.data.mag_id || undefined;
		if (mag_id && isDefined(this.collapsedRows[mag_id])) {
			delete this.collapsedRows[mag_id];
			this.saveParamSet();
		}
	}

	onNodeCollapse(event: any) {
		let mag_id = event.node.data.mag_id || undefined;
		if (mag_id && !isDefined(this.collapsedRows[mag_id])) {
			this.collapsedRows[mag_id] = mag_id;
			this.saveParamSet();
		}
	}

	load() {
		// // https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);
		this.saveParamSet();
		this.objectifsService.getObjectifsVendeursParMagasin({annee: this.annee})
		.subscribe({
			next: (response: any) => {
				this.prepareColumns();
				this.prepareMagasins(response);
				this.objectifs = this.convertEntitiesToNodes(response);
				// this.updateLigneTotal();
					this.filter();
			}
		})
		.add(() => {
			this.loading = false;
			this.changeDetectorRef.detectChanges();
		});
	}

	prepareColumns() {
		this.columns = [];

		this.frozen_columns = [
			{header: ' '}
		]

		this.rangeMois = arrayOfMonths(this.annee, 12);

		let nbMonths = this.rangeMois.length;
		for (let i = 0; i < nbMonths ; i++) {
			let oneMonth = this.rangeMois[i];
			const monthNumber = padString((oneMonth.getMonth() + 1).toString(), '0',  2);
			this.columns = this.columns.concat([
				{
					header: capitalize(this.datePipe.transform(oneMonth, 'MMMM'), true),
					code: this.ind_code,
					class: 'col-mois'
				}
			]);
		}
		this.columns.push({
			header: 'TOTAL',
			class: 'col-total'
		});
	}

	prepareMagasins(magasins: any[]) {
		magasins.forEach((magasin:any) => {
			magasin.restant = 0;
			magasin.total = 0;
			magasin.oma_ca_prise_commande_htple = 0;
			stringSort(magasin.vendeurs, 'uti_prenom');
			// calcul du restant pour chaque mois
			for (let i = 0; i < this.rangeMois.length; i++) {
				this.updateMagasinMois(magasin, magasin.vendeurs, i);
			}
			// objectif total du magasin
			magasin.objectifs_magasins.forEach((mois: any) => {
				magasin.oma_ca_prise_commande_htple += mois.oma_ca_prise_commande_htple;
			});
			// total saisi actuel
			this.updateMagasinTotal(magasin);
			magasin.vendeurs.forEach((vendeur: any) => {
				this.updateTotalVendeur(vendeur);
			});

		});

		// let ligneTotal = this.prepareLigneTotal();
		// magasins.push(ligneTotal);
	}

	prepareLigneTotal() {
		let ligneTotal: LigneTotal = {
			header: 'Total attribué',
			oma_ca_prise_commande_htple: 0,
			objectifs_magasins: []
		};
		for (let i = 0; i < this.rangeMois.length; i++) {
			let tmpObjectif: ObjectifMagasin = {
				oma_mois: i
			}
			ligneTotal.objectifs_magasins.push(tmpObjectif)
		}
		return ligneTotal;
	}

	convertEntitiesToNodes(entities: any[]) {
		return entities.map((entity: any) => {
			let tmpNodeData: {[key: string]: any} = {};
			if (entity.mag_id) {
				tmpNodeData.children = entity.vendeurs.map((vendeur: any) => {
					vendeur.uid = uid();
					return {
						data: clone(vendeur),
						leaf: true,
						uid: uid()
					} as TreeNode;
				})
				delete entity.vendeurs;
			}

			tmpNodeData.data = clone(entity);
			tmpNodeData.leaf = false;
			tmpNodeData.expanded = !isDefined(this.collapsedRows[entity.mag_id]);
			tmpNodeData.uid = uid();
			return tmpNodeData as TreeNode;
		});
	}

	updateAll(nodeMagasin: any, nodeVendeur: any, indexMois: number) {
		this.updateMagasinMois(
			nodeMagasin.data,
			nodeMagasin.children.map((one:any) => {return one.data;}),
			indexMois
		);
		this.updateMagasinTotal(nodeMagasin.data);
		this.updateTotalVendeur(nodeVendeur.data);
		// this.updateLigneTotal();

		this.changeDetectorRef.detectChanges();
	}

	updateAllById(mag_id: number, uti_id: number, indexMois: number) {
		// récupération du node du magasin
		let nodeMagasin = this.objectifs.find((one: any) => {
			return one.data.mag_id == mag_id;
		});
		if (nodeMagasin && nodeMagasin.children) {
			let nodeVendeur = nodeMagasin.children.find((one: any) => {return one.data.uti_id == uti_id;});
			this.updateAll(nodeMagasin, nodeVendeur, indexMois);
		}
	}

	updateMagasinMois(magasin: any, vendeurs: any[], indexMois: number) {
		magasin.objectifs_magasins[indexMois].total = 0;
		vendeurs.forEach((vendeur: any) => {
			if (
				vendeur.objectifs_vendeurs
				&& vendeur.objectifs_vendeurs[indexMois]
				&& vendeur.objectifs_vendeurs[indexMois].present // on ignore les mois où le vendeur est absent, même si un objectif a été saisi
				&& vendeur.objectifs_vendeurs[indexMois].ove_ca_prise_commande_htple
			) {
				magasin.objectifs_magasins[indexMois].total += vendeur.objectifs_vendeurs[indexMois].ove_ca_prise_commande_htple;
			}
		});
		if (!isDefined(magasin.objectifs_magasins[indexMois].oma_ca_prise_commande_htple) || magasin.objectifs_magasins[indexMois].oma_ca_prise_commande_htple == null) {
			magasin.objectifs_magasins[indexMois].restant = null;
		}
		else {
			magasin.objectifs_magasins[indexMois].restant = magasin.objectifs_magasins[indexMois].oma_ca_prise_commande_htple - magasin.objectifs_magasins[indexMois].total;
		}
	}

	updateMagasinTotal(nodeData: any) {
		nodeData.total = 0;
		nodeData.objectifs_magasins.forEach((mois: any) => {
			if (mois.total) nodeData.total += mois.total;
		});
		nodeData.restant = nodeData.oma_ca_prise_commande_htple - nodeData.total;
	}

	updateLigneTotal() {
		// la ligne total est toujours la dernière
		const ligneTotal = this.objectifs[this.objectifs.length -1].data;
		const magasins = this.objectifs.map((one:any) => {return one.data;});
		// reset grand total
		ligneTotal.oma_ca_prise_commande_htple = 0;
		for (let mois = 0; mois < 12; mois++) {
			// reset objectif mensuel
			ligneTotal.objectifs_magasins[mois].oma_ca_prise_commande_htple = 0;
			for (let nodeIndex = 0; nodeIndex < this.objectifs.length -1; nodeIndex++) {
				let magasin = magasins[nodeIndex];
				ligneTotal.objectifs_magasins[mois].oma_ca_prise_commande_htple += magasin.objectifs_magasins[mois].total;
			};
			ligneTotal.oma_ca_prise_commande_htple += ligneTotal.objectifs_magasins[mois].oma_ca_prise_commande_htple;
		}
	}

	updateTotalVendeur(nodeData: any) {
		nodeData.total = 0;
		nodeData.objectifs_vendeurs.forEach((mois: any) => {
			if (mois.ove_ca_prise_commande_htple) {
				// on ne prend en compte que les mois où le vendeur est présent
				if (mois.present) {
					nodeData.total += mois.ove_ca_prise_commande_htple;
				}
				else {
					// si le vendeur est absent alors qu'un objectif est défini, le mois est invalide
					mois.invalid = true;
				}
			}
		});
	}

	onEditComplete(event: any) {
		const valueBeforeEdit = this.valueBeforeEdit;
		if (event.data.ove_ca_prise_commande_htple != this.valueBeforeEdit) {
			this.objectifsService.putObjectifVendeur(event.data)
			.subscribe({
				next: (response: any) => {
					if (!event.data.present && event.data.ove_ca_prise_commande_htple == null) {
						event.data.readonly = true;
						event.data.invalid = false;
					}
					this.updateAllById(event.data.mag_id, event.data.uti_id, event.data.ove_mois -1);
				},
				error: (error: any) => {
					event.data.ove_ca_prise_commande_htple = valueBeforeEdit;
					this.updateAllById(event.data.mag_id, event.data.uti_id, event.data.ove_mois -1);
				}
			});
		}
	}

	onEditCancel(event: any) {
		event.data.ove_ca_prise_commande_htple = this.valueBeforeEdit;
	}

	onEditInit(event: any) {
		this.valueBeforeEdit = event.data.ove_ca_prise_commande_htple;
	}

	export() {
		this.exportLoading = true;

		this.objectifsService.exportObjectifsVendeursParMagasin({annee: this.annee})
		.subscribe()
		.add(() => {
			this.exportLoading = false;
			this.changeDetectorRef.detectChanges();
		});
	}

}
@NgModule({
	declarations: [
		ObjectifsVendeurComponent
	],
	exports: [
		ObjectifsVendeurComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		TooltipModule,
		TreeTableModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		AnneeSelectorModule,
		NumberDisplayModule,
	],
})
export class ObjectifsVendeurModule { }
