import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { StateDeclaration, StateService } from '@uirouter/core';

import { IEventListener, EventManagerService } from '@global/event-manager.service';


import { AuthService } from '@app/auth/auth.service';
import { ConfigService } from '@global/config.service';
import { MenuItem } from 'primeng/api';

import { clone } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class MenuService implements IEventListener {

	private toggleMenuSubject = new Subject<boolean>();
	public toggleMenu$ = this.toggleMenuSubject.asObservable();

	private toggleMobileMenuSubject = new Subject<boolean>();
	public toggleMobileMenu$ = this.toggleMobileMenuSubject.asObservable();

	private menuItemsSubject: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
	public menuItems$ = this.menuItemsSubject.asObservable();

	constructor(
		private authService: AuthService,
		private configService: ConfigService,
		private eventManager: EventManagerService,
		private stateService: StateService
	) {
		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.setMenuItems([]);
			}
		);
	}

	toggleMenu(toggle?: boolean): void {
		this.toggleMenuSubject.next(toggle);
	}

	toggleMobileMenu(toggle?: boolean): void {
		this.toggleMobileMenuSubject.next(toggle);
	}

	setMenuItems(items: any): void {
		const preppedItems = this.prepareItems(items);
		this.menuItemsSubject.next(preppedItems);
	}

	checkItemRight(item: any) {
		let hasRight = true;
		if (typeof item.droits != 'undefined') {
			hasRight = this.authService.checkIfHasRight(item.droits);
		}
		return hasRight;
	}

	prepareItems(items: any): MenuItem[] {
		const tmp = clone(items);

		for (let i = tmp.length - 1; i >= 0 ; i--) {

			let state: StateDeclaration|null = null;
			if (tmp[i].state) {
				if (tmp[i].state.abstract) {
					state = this.stateService.get(tmp[i].state.abstract);
				}
				else {
					state = this.stateService.get(tmp[i].state.name);
				}
			}

			// if user can't access this menu item,
			// or if module is disabled
			// remove it
			if (
				state && state.data && !this.checkItemRight(state.data)
				|| !this.checkItemRight(tmp[i])
				|| tmp[i].state && tmp[i].state.name && !this.configService.isModuleActif(tmp[i].state.name)
			) {
				tmp.splice(i, 1);
				continue;
			}

			if (tmp[i].state.name) {
				tmp[i].label = this.configService.getModuleMenuName(tmp[i].state.name, tmp[i].label);
			}

			if (tmp[i].items && tmp[i].items.length) { // has sublevels
				tmp[i].items = this.prepareItems(tmp[i].items);
				if (tmp[i].items.length > 1) {
					// tmp[i]['expanded'] = true; // the "expanded" state is managed in the menu definition
				}
				else if (tmp[i].items.length == 1 && !!!tmp[i].keepIfHasSingleChild) { // if only one sublevel, and parent is replaceable, replace the parent
					tmp.splice(i, 1, tmp[i].items[0]);
				}
				else if (tmp[i].items.length == 0) { // if no sublevel, delete the parent
					tmp.splice(i, 1);
				}
			}

		}

		return tmp as MenuItem[];
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}


}
