import { NgModule, Component, Input, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	NgForm,
	FormsModule,
	Validators,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule, Dropdown } from '@app/primeng-overrides/dropdown';

import { ConfigService } from '@global/config.service';
import { startOfDay } from '@helpers/utils';

@Component({
	selector: 'annee-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AnneeSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[required]="required"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class AnneeSelectorComponent implements OnInit, ControlValueAccessor {

	@Input('value') innerValue: number|null|undefined;
	@Input() name: string;
	@Input() nullIfInvalid: boolean;
	@Input() autoSelectCurrent: boolean;
	@Input() disabled: boolean;
	@Input() required: boolean;
	@Input() minYear: number;
	@Input() maxYear: number;
	@Input() range: number;
	@Input() reverseOrder: boolean;

	options: any[] = [];
	placeholder: string = 'Sélectionnez';

	constructor(private configService: ConfigService,) {

	}

	ngOnInit() {
		if (!this.minYear) this.minYear = this.configService.config.annee_reference;
		if (!this.maxYear) this.maxYear = new Date().getUTCFullYear();
		if (this.range) {
			this.maxYear = this.minYear + this.range;
		}
		this.prepareChoices();
		setTimeout(() => {
			this.setInitialValue();
		}, 0)
	}


	prepareChoices() {
		let tmpOptions = [];
		for(let i = this.minYear; i <= this.maxYear ; i++){
			tmpOptions.push({label: i.toString(), value: i});
		}
		if (this.reverseOrder) {
			tmpOptions.reverse();
		}
		this.options = [...tmpOptions];
	}

	setInitialValue() {
		if (this.autoSelectCurrent && !!!this.innerValue && this.options.length) {
			let today = new Date();
			today = startOfDay(today);
			let index = this.options.findIndex(one => {
				return one.value == today.getUTCFullYear();
			});
			if (index >= 0) {
				this.innerValue = this.options[index].value;
			}
			else {
				this.innerValue = this.options[this.options.length -1].value;
			}
		}
		else {
			if (this.options.findIndex(one => {return one.value == this.innerValue; }) < 0) {
				if (this.nullIfInvalid) {
					this.innerValue = null;
				}
				else if (this.options.length){
					this.innerValue = this.options[this.options.length -1].value;
				}
			}
		}
		this.value = this.innerValue;
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: number|null|undefined) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(value: number|null|undefined) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [AnneeSelectorComponent],
	declarations: [AnneeSelectorComponent]
})
export class AnneeSelectorModule { }
