import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { Observable } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

import { IndicateurService } from '@app/indicateur/indicateur.service';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';

import { EventManagerService, IEventListener } from '@global/event-manager.service';

@Component({
	selector: 'export-graphique-dialog',
	templateUrl: './export-graphique-dialog.html'
})
export class ExportGraphiqueDialogComponent implements OnInit, IEventListener {

	loading: boolean = false;
	mag_id: number;
	exportFunc: Function;

	constructor(
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private dialogConfig: DynamicDialogConfig,
		private indicateurService: IndicateurService,
	) {
		this.exportFunc = dialogConfig.data.exportFunc;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		this.loading = true;
		let params = {
			mag_id: this.mag_id,
		}

		this.exportFunc(params)
		.subscribe()
		.add(()=>{ this.loading = false; });
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		InputTextModule,
		MagasinSelectorModule,
	],
	exports: [ExportGraphiqueDialogComponent],
	declarations: [ExportGraphiqueDialogComponent]
})
export class ExportGraphiqueDialogModule { }

