<form class="" #detailForm="ngForm">
	<div class="formgrid grid p-fluid">
		<div class="field col-12">
			<label for="dpa_libelle">Libellé</label>
			<input
				type="text"
				pInputText
				name="dpa_libelle"
				[(ngModel)]="detail.dpa_libelle"
				maxlength="100"
				[disabled]="loading"
				[required]="true"
			>
		</div>

		<div class="field col-6">
			<label for="dpa_date_debut">Date</label>
			<div class="flex">
				<p-calendar
					name="dpa_date_debut"
					[(ngModel)]="detail.dpa_date_debut"
					[minDate]="minDate"
					[maxDate]="detail.dpa_date_fin"
					[showIcon]="true"
					[readonlyInput]="hasTouch"
					appendTo="body"
					[required]="true"
				></p-calendar>
			</div>
		</div>

		<div class="field col-6">
			<label for="dpa_date_fin">Date de fin</label>
			<div class="flex">
				<p-calendar
					name="dpa_date_fin"
					[(ngModel)]="detail.dpa_date_fin"
					[minDate]="detail.dpa_date_debut"
					[showIcon]="true"
					[readonlyInput]="hasTouch"
					[showButtonBar]="true"
					appendTo="body"
				></p-calendar>
				<button
					type="button"
					pButton
					icon="pi pi-times"
					class="p-button-text p-button-danger"
					[disabled]="loading"
					(click)="detail.dpa_date_fin = null"
					*ngIf="detail.dpa_date_fin"
				></button>
			</div>
		</div>

		<div class="field col-12">
			<label for="dpa_chiffre">Objectif chiffré</label>
			<input
				type="text"
				pInputText
				name="dpa_chiffre"
				[(ngModel)]="detail.dpa_chiffre"
				maxlength="100"
				[disabled]="loading"
			>
		</div>

		<div class="field col-12">
			<label for="dpa_personnes_impliquees">Personnes impliquées</label>
			<input
				type="text"
				pInputText
				name="dpa_personnes_impliquees"
				[(ngModel)]="detail.dpa_personnes_impliquees"
				[disabled]="loading"
			>
		</div>

		<div class="field col-12">
			<label for="dpa_detail">Détail de mise en oeuvre</label>
			<textarea
				pInputTextarea
				name="dpa_detail"
				[(ngModel)]="detail.dpa_detail"
				[autoResize]="true"
				[rows]="5"
			></textarea>
		</div>

	</div>

</form>

<div class="p-dialog-footer text-right">
	<button
		type="button"
		pButton
		class="btn p-button-sm p-button-text"
		label="Annuler"
		[disabled]="loading"
		(click)="cancel()"
	></button>
	<button
		type="button" pButton
		class="btn p-button-sm"
		label="Enregistrer"
		[disabled]="!detailForm.form.valid || loading"
		(click)="submit()"
	></button>
</div>
