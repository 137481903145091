import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from '@app/primeng-overrides/table';

import { ConfigService } from '@global/config.service';
import { GlobalModule } from '@global/global.module';
import { NumberDisplayModule } from '@helpers/number-display';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { PrintButtonModule } from '@app/print-button';
import {
	SaisiePreparationObjectifsCAAnnuel,
	SimulateurService,
	SimulationPreparationObjectifsCAAnnuel
} from '@app/simulateur/simulateur.service';
import { StatutVendeurSelectorModule } from '@app/simulateur/preparation-objectifs-ca-annuel/statut-vendeur-selector';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

import { enseigne } from '@environments/environment';

import {
	arrayOfMonths,
	capitalize,
	clone,
	simpleComparison,
	simpleDateToString
} from '@helpers/utils';

export class TeamMember {
	uti_id: number|null;
	nom: string;
	statut: string;
	ca_statut: number|null;
	simulation: number;

	constructor(vendeur?: any) {
		if (vendeur) {
			this.uti_id = vendeur.uti_id;
			this.nom = `${vendeur.uti_prenom} ${vendeur.uti_nom}`;
		}
	}
}

@Component({
	selector: 'preparation-objectifs-ca-annuel',
	templateUrl: './preparation-objectifs-ca-annuel.html',

})
export class SimulateurPreparationObjectifsCAAnnuelComponent {

	loading: boolean = false;

	debouncedSubmit: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	rangeMois: Date[] = arrayOfMonths(1970, 12);
	rangeMoisString: string[] = [];


	simulation: SimulationPreparationObjectifsCAAnnuel;
	saisies: SaisiePreparationObjectifsCAAnnuel = new SaisiePreparationObjectifsCAAnnuel();
	pristine: SaisiePreparationObjectifsCAAnnuel;
	mag_id: number|null = null;
	previous_mag_id: number = 0;

	config: any;

	today = new Date();
	todayString: string = simpleDateToString(this.today, true);
	anneeCourante: number;
	anneeNMoinsUn: number;
	moisCourant: number;
	moisCourantString: string|null;
	moisPrecedent: number;
	moisPrecedentString: string|null;

	totalSaisonnaliteCA: number = 0;
	totalSaisonnalitePercent: number = 0;

	vendeurs: any[] = [];
	teamMembers: TeamMember[] = [];

	totalEquipeStatut: number = 0;
	totalEquipeSimulation: number = 0;

	title: string = '';

	constructor(
		private configService: ConfigService,
		private confirmationService: ConfirmationService,
		private datePipe: DatePipe,
		private simulateurService: SimulateurService,
		private utilisateurService: UtilisateurService
	) {
		this.title = this.configService.getCurrentModuleTitle();
		this.config = this.configService.config['config_simulateurs'][enseigne]['preparation_objectifs_ca_annuel'];

		this.debouncedSubmit
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.submit();
			}
		});

		this.anneeCourante = this.today.getFullYear();
		this.anneeNMoinsUn = this.anneeCourante - 1;
		this.moisCourant = this.today.getMonth() +1;
		this.moisCourantString = this.datePipe.transform(`1970-${this.moisCourant}`, 'MMMM');
		this.moisPrecedent = (this.moisCourant - 1 != 12)? this.moisCourant-1 : 0;
		this.moisPrecedentString = this.datePipe.transform(`1970-${this.moisPrecedent}`, 'MMMM');

		let nbMonths = this.rangeMois.length;
		for (let i = 0; i < nbMonths ; i++) {
			let oneMonth = this.rangeMois[i];
			this.rangeMoisString.push(capitalize(this.datePipe.transform(oneMonth, 'MMMM'), true) as string);
		}
	}

	ngOnInit() {
		this.load();
	}

	dirty() {
		return !simpleComparison(this.pristine, this.saisies);
	}

	paramChanged() {
		// console.log('this.previous_mag_id', this.previous_mag_id, this.mag_id)
		if (this.previous_mag_id && this.mag_id != this.previous_mag_id) {

			this.confirmationService.confirm({
			defaultFocus: 'reject',
				message: 'Le changement de magasin va effacer les saisies. Souhaitez-vous continuer ?',
				accept: () => {
					this.load();
				},
				reject: () => {
					this.mag_id = this.previous_mag_id;
				}
			});

		}
	}

	load() {
		this.loading = true;
		forkJoin([this.loadSimulation(), this.loadUsers()])
		.subscribe(
			() => {
				// console.log('fork load')
				this.prepareSimulation();
				this.submit(true);
			}
		)
		.add(() => {
			this.loading = false;
		});
	}

	saisiesChanged(smth?: any) {
		// console.log('saisiesChanged', smth)
		this.debouncedSubmit.next(true);
	}

	loadSimulation() {
		// console.log('loadSimulation');
		return this.simulateurService.getPreparationObjectifsCAAnnuel(this.mag_id)
		.pipe(map(
			(response: any) => {
				// console.log('R loadSimulation', response)
				if (response) {
					this.simulation = response;
				}
			}
		));
	}

	submit(force: boolean = false) {
		if (this.mag_id && (force || this.dirty())) {
			this.loading = true;
			forkJoin([this.submitSimulation(), this.loadUsers()])
			.subscribe(
				() => {
					// console.log('fork submit')
					this.prepareSimulation();
				}
			)
			.add(() => {
				this.loading = false;
			});
		}
	}


	submitSimulation() {
		let tmpSaisies = clone(this.saisies);

		tmpSaisies.mag_id = this.mag_id;
		if (this.previous_mag_id && this.previous_mag_id != this.mag_id) {
			tmpSaisies.equipe = [];
		}

		tmpSaisies.objectif_taux_nb_ventes_nb_entrees = tmpSaisies.objectif_taux_nb_ventes_nb_entrees / 100;

		// console.log('submitSimulation');
		return this.simulateurService.postPreparationObjectifsCAAnnuel(tmpSaisies)
		.pipe(map(
			(response: any) => {
				// console.log('R submitSimulation', response)
				if (response) {
					this.simulation = response;
				}
			}
		));
	}

	loadUsers(): Observable<any> {
		if (this.mag_id) {
			let params = {
				mag_id: this.mag_id,
				date_debut: this.todayString,
				date_fin: this.todayString
			};

			return this.utilisateurService.getVendeursPeriode(params)
			.pipe(map(
				(response: any) => {
					// console.log('R loadUsers', response);
					this.vendeurs = response;
				}
			));
		}
		else {
			return of(false);
		}
	}

	prepareSimulation() {
		if (this.simulation) {
			// console.log('prep', this.simulation)

			this.saisies = clone(this.simulation.saisies);
			this.mag_id = this.saisies.mag_id;
			this.previous_mag_id = this.mag_id!;
			if (this.simulation['N'].taux_nb_ventes_nb_entrees) {
				this.simulation['N'].taux_nb_ventes_nb_entrees *= 100;
			}
			if (this.simulation['N-1'].taux_nb_ventes_nb_entrees) {
				this.simulation['N-1'].taux_nb_ventes_nb_entrees *= 100;
			}
			if (this.saisies.objectif_taux_nb_ventes_nb_entrees) {
				this.saisies.objectif_taux_nb_ventes_nb_entrees *= 100;
			}

			this.pristine = clone(this.saisies);
			this.prepareSaisonnalite();
			this.prepareEquipe();
		}
	}

	prepareSaisonnalite() {
		this.totalSaisonnaliteCA = 0;
		this.totalSaisonnalitePercent = 0;
		this.saisies.mois.forEach((mois: any) => {
			this.totalSaisonnaliteCA += mois.ca_prise_commande_htple;
			if (this.simulation['N-1'].ca_prise_commande_htple) {
				mois.saisonnalite = mois.ca_prise_commande_htple / this.simulation['N-1'].ca_prise_commande_htple *100;
			}
			this.totalSaisonnalitePercent += mois.saisonnalite;

		});
	}

	prepareEquipe() {
		this.vendeurs.forEach((vendeur: any) => {
			if (!this.saisies.equipe.find((member: any)=> vendeur.uti_id == member.uti_id )) {
				this.saisies.equipe.push(new TeamMember(vendeur));
			}
		});

		this.totalEquipeStatut = 0;
		this.totalEquipeSimulation = 0;

		this.saisies.equipe.forEach((member: TeamMember) => {
			if (member.statut) {
				this.totalEquipeStatut += this.getCAStatutValue(member.statut);
			}
			if (!member.ca_statut) {
				member.ca_statut = null;
			}
			if (member.simulation) {
				this.totalEquipeSimulation += member.simulation;
			}
		});

	}

	addTeamMember() {
		this.saisies.equipe.push(new TeamMember());
	}

	removeTeamMember(index: number) {
		// on ne peut retirer que les lignes personnalisées
		if (!this.saisies.equipe[index].uti_id) {
			this.saisies.equipe.splice(index, 1);
			this.saisiesChanged();
		}
	}

	getCAStatutValue(libelle: string) {
		if (this.config.statuts_vendeurs) {
			let tmp = this.config.statuts_vendeurs.find((one: any) => {return one.libelle == libelle;})
			return (tmp)? tmp.ca : null;
		}
		return null;
	}

	resetSaisonnalite() {
		this.saisies.objectif_ca_prise_commande_htple = null;
		this.saisies.mois.forEach((one: any) => {
			one.ca_prise_commande_htple = null;
		});
		this.submit();
	}


}
@NgModule({
	declarations: [
		SimulateurPreparationObjectifsCAAnnuelComponent
	],
	exports: [
		SimulateurPreparationObjectifsCAAnnuelComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		TableModule,
		GlobalModule,
		IndicateurValueDisplayModule,
		IndicateurTooltipModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		PrintButtonModule,
		StatutVendeurSelectorModule
	],
})
export class SimulateurPreparationObjectifsCAAnnuelModule { }
