<div class="mr-2" *ngIf="!singleMode">
	<label class="inline-block mb-2" for="mode" *ngIf="!!label">{{label}}</label>
	<p-selectButton
		name="mode"
		[options]="options"
		[(ngModel)]="mode"
		[disabled]="disabled || disableModeChange"
		(onChange)="changeMode()"
	></p-selectButton>
</div>

<div class=" flex flex-column justify-content-end">
	<div class="flex align-items-center">
		<span class="label" *ngIf="singleMode && !!label">{{label}}</span>
		<div class="flex flex-shrink-0 label" *ngIf="showPreviousCurrentMonth">
			<button
				type="button"
				pButton
				label="Mois précédent"
				class="p-button-sm p-button-text pt-0 pb-0 white-space-nowrap"
				(click)="selectCurrentMonth(-1)"
			></button>
			<button
				type="button"
				pButton
				label="Mois courant"
				class="p-button-sm p-button-text pt-0 pb-0"
				(click)="selectCurrentMonth()"
			></button>

		</div>
	</div>

	<div class="flex" *ngIf="!rangeMode && (mode == 'mois' ||mode == 'annee')">
		<p-calendar
			#dateRangeSelector
			[(ngModel)]="dateRangeControl[0]"
			selectionMode="single"
			[readonlyInput]="hasTouch"
			appendTo="body"
			[required]="true"
			[minDate]="minDate"
			[maxDate]="maxDate"
			[view]="mode == 'mois'? 'month': 'year'"
			[dateFormat]="dateFormat"
			[showIcon]="true"
			(onClose)="setDates(dateRangeControl)"
			[disabled]="disabled"
		>
			<ng-template pTemplate="footer">
				<div class="flex justify-content-between">
					<button
						type="button"
						class="p-button-sm"
						[label]="dateRangeSelector.getTranslation('today')"
						(keydown)="dateRangeSelector.onContainerButtonKeydown($event)"
						(click)="dateRangeSelector.onTodayButtonClick($event)"
						pButton
						pRipple
					></button>
					<button
						type="button"
						class="p-button-sm"
						label="OK"
						(keydown)="dateRangeSelector.onContainerButtonKeydown($event)"
						(click)="dateRangeSelector.toggle()"
						pButton
						pRipple
					></button>
				</div>
			</ng-template>
		</p-calendar>
	</div>

	<div class="flex" *ngIf="rangeMode && (mode == 'mois' || mode == 'annee') || mode == 'libre'">
		<p-calendar
			#dateRangeSelector
			[(ngModel)]="dateRangeControl"
			selectionMode="range"
			[readonlyInput]="hasTouch"
			appendTo="body"
			[view]="mode == 'mois'? 'month' : 'date'"
			[dateFormat]="dateFormat"
			[minDate]="minDate"
			[maxDate]="maxDate"
			[required]="true"
			[showIcon]="true"
			(onClose)="setDates(dateRangeControl)"
			[disabled]="disabled"
		>
			<ng-template pTemplate="footer">
				<div class="flex justify-content-between">
					<button
						type="button"
						class="p-button-sm"
						[label]="dateRangeSelector.getTranslation('clear')"
						(keydown)="dateRangeSelector.onContainerButtonKeydown($event)"
						(click)="dateRangeSelector.onClearButtonClick($event)"
						pButton
						pRipple
						*ngIf="mode != 'mois'"
					></button>
					<button
						type="button"
						class="p-button-sm"
						[label]="dateRangeSelector.getTranslation('today')"
						(keydown)="dateRangeSelector.onContainerButtonKeydown($event)"
						(click)="dateRangeSelector.onTodayButtonClick($event)"
						pButton
						pRipple
					></button>
					<button
						type="button"
						class="p-button-sm"
						label="OK"
						(keydown)="dateRangeSelector.onContainerButtonKeydown($event)"
						(click)="dateRangeSelector.toggle()"
						pButton
						pRipple
					></button>
				</div>
			</ng-template>

		</p-calendar>

		<button
			type="button"
			pButton
			icon="pi pi-times"
			class="p-button-text p-button-danger"
			[disabled]="disabled"
			(click)="resetDates()"
			*ngIf="isResetable()"
		></button>
	</div>
</div>
