import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BehaviorSubject, Subscription, of, Observable, throwError } from 'rxjs';
import { map, debounceTime, catchError, tap, switchMap } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MenuItem } from 'primeng/api';
import { MenuModule, Menu } from 'primeng/menu';
import { TableModule, Table } from '@app/primeng-overrides/table';
import { TooltipModule } from 'primeng/tooltip';

import { AuthService } from '@app/auth/auth.service';
import { CommentaireDNVDialogComponent } from '@app/evenement/commentaire-dnv-dialog';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { EntreeLibreDialogComponent } from '@app/evenement/entree-libre-dialog';
import { EvenementService, Evenement, OptionEvenement } from '@app/evenement/evenement.service';
import { EventManagerService } from '@global/event-manager.service';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { MilieuDialogComponent } from '@app/evenement/milieu-dialog';
import { NumberDisplayModule } from '@helpers/number-display';
import { PrintButtonModule } from '@app/print-button';
import { StatutOptionSelectorModule } from '@app/evenement/statut-option-selector';
import { StorageService } from '@global/storage.service';
import { TerminerAttenteDialogComponent } from '@app/evenement/terminer-attente-dialog';
import { TypeEvenementPipe } from '@app/evenement/type-evenement.pipe'
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';
import { VendeurDialogComponent } from '@app/evenement/vendeur-dialog';
import { WebSocketService, WebSocketMessage } from '@global/websocket.service';

import {
	clone,
	endOfDay,
	simpleComparison,
	simpleDateToString,
	startOfDay,
	createDownloadFromHttpResponse
} from '@helpers/utils';

export interface WebSocketMessageActiviteMagasin {
	mag_id: number;
	eve_id: number;
	eve_option_id: string;
	action: string;
}

@Component({
	selector: 'activite-magasin',
	templateUrl: './activite-magasin.html',
	providers: [DatePipe, TypeEvenementPipe]
})
export class ActiviteMagasinComponent {

	@ViewChild('tableEvenements') table: Table;
	@ViewChild('terminerAttenteMenu', {static: true}) terminerAttenteMenu: Menu;

	values: any[] = [];
	totalRecords: number = 0;

	valuesOptions: any[] = [];
	totalRecordsOptions: number = 0;

	loading: boolean;
	rows: number = 10;
	exportLoading: boolean;

	dateRange: Date[] = [];
	mag_id: number;

	params: {[key: string]: any} = {
		search: null,
		traite: false,
		masque: false
	}

	exclusiveFilters: {[key: string]: any} = {
		attente: false,
		devis_non_vendus: false,
		livres: false,
		ventes: false,
	}

	valueBeforeEdit: any;

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	websocket: Subscription;

	timerMap: Map<number, any> = new Map();
	newRemoteEvents: boolean = false;

	isVendeur: boolean = false;
	canSeeOptionsLivrees: boolean = false;
	droitsCanSeeOptionsLivrees: string[] = [
		'manager',
		'chef_ventes',
		'ag',
		'informatique',
	];

	terminerAttenteMenuItems: MenuItem[] = [];

	constructor(
		private authService: AuthService,
		private confirmationService: ConfirmationService,
		private datePipe: DatePipe,
		private dialogService: DialogService,
		private evenementService: EvenementService,
		private eventManager: EventManagerService,
		private storageService: StorageService,
		private typeEvenementPipe: TypeEvenementPipe,
		private webSocketService: WebSocketService,
	) {

		this.isVendeur =
			!this.authService.user!.uti_resp_saisie
			&& (
				this.authService.user!.uti_vendeur
				|| this.authService.user!.uti_chef_ventes
			);

		this.canSeeOptionsLivrees = this.authService.checkIfHasRight(this.droitsCanSeeOptionsLivrees);

		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.refresh();
			}
		});

	}

	ngOnInit() {
		this.getParamSet();
		if (!this.canSeeOptionsLivrees) {
			this.exclusiveFilters.livres = false;
		}
		this.connectWebsocket();
	}

	ngOnDestroy() {
		this.closeWebsocket();
		this.timerMap.forEach((timerId: number, eve_id: number) => {
			window.clearTimeout(timerId);
		});
	}

	connectWebsocket() {
		this.websocket = this.webSocketService.connect().
		subscribe({
			next: (message: WebSocketMessage<WebSocketMessageActiviteMagasin>) => {
				// console.log('Websocket received', message);
				this.handleWebsocketMessage(message);
			},
			error: (error: any) => {
				console.log('Websocket error - component', error)
			}
		});
	}

	closeWebsocket() {
		if (this.websocket) {
			this.websocket.unsubscribe();
			this.webSocketService.closeConnection();
		}
	}

	handleWebsocketMessage(message: WebSocketMessage<WebSocketMessageActiviteMagasin>): void {
		if (typeof message.objet == 'undefined' || message.objet != 'activite-magasin') {
			// console.log('This message is not for me', message);
		}
		else {
			if (
				message.data
				&& message.data.eve_id
				&& message.data.mag_id
				&& message.data.action
				&& message.data.mag_id == this.mag_id
			) {
				this.startTimerModification(message.data);
			}
		}
	}

	startTimerModification(data: WebSocketMessageActiviteMagasin) {
		if (this.timerMap.has(data.eve_id)) {
			this.removeTimerModification(data.eve_id);
		}
		// console.log('startTimerModification', data);
		const timerId = setTimeout(() => {
			this.handleRemoteEventModification(data);
		}, 2000);
		this.setTimerModification(data.eve_id, timerId);
	}

	setTimerModification(eve_id: number, timerId: number) {
		// console.log('setTimerModification', eve_id, timerId);
		this.timerMap.set(eve_id, timerId);
	}

	removeTimerModification(eve_id: number) {
		const timerId = this.timerMap.get(eve_id);
		if (timerId) {
			// console.log('removeTimerModification', eve_id, timerId);
			window.clearTimeout(timerId);
		}
		// this.timerMap.delete(eve_id); // ?
	}

	handleRemoteEventModification(data: WebSocketMessageActiviteMagasin): any {
		// console.log('handle', data);
		switch (data.action) {
			case 'create':
				return this.newEveDisplay();
				break;
			case 'update':
			case 'mise_en_attente':
			case 'annulation_mise_en_attente':
			case 'fin_mise_en_attente':
				return this.updateEveDisplay(data);
				break;
			case 'delete':
				return this.deleteEveDisplay(data);
				break;
			case 'update_option_evenement':
				return this.fetchAndUpdateOptionEvenementDisplay(data.mag_id, data.eve_option_id);
				break;
		}
		this.timerMap.delete(data.eve_id);
	}

	updateEveDisplay(data: Evenement|WebSocketMessageActiviteMagasin): void {
		const index = this.getEvenementIndex(data);
		if (index < 0) {
			// console.log('Not currently displayed, will not update',  data);
			return;
		}
		else {
			this.evenementService.get(data.mag_id, data.eve_id)
			.subscribe({
				next: (response: any) => {
					let tmp: Evenement = this.prepareEvenement(response);
					const index = this.getEvenementIndex(data);

					if (index > -1) {
						this.values[index].updated = false;

						// set value if not editing
						if (!this.valueBeforeEdit || this.valueBeforeEdit.eve_id != data.eve_id) {
							this.values[index] = tmp;
						}

						this.values[index].updated = true;
					}
				}
			});
		}
	}

	fetchNewEvenement(eve_id: number) {
		this.evenementService.get(this.mag_id, eve_id)
		.subscribe({
			next: (response: Evenement) => {
				let tmp: Evenement = this.prepareEvenement(response);
				tmp.updated = true;
				let index = this.values.findIndex((one: any) => {
					return one.eve_date_evenement < tmp.eve_date_evenement;
				});
				if (index < 0) {
					index = Infinity;
				}
				this.values.splice(index, 0, tmp);
			}
		});
	}

	deleteEveDisplay(data: any) {
		// change event color
		const index = this.getEvenementIndex(data);
		if (index > -1) {
			// change color
			this.values[index].deleted = true;
			// prevent further edition
			this.values[index].readonly = true;
		}
	}

	newEveDisplay() {
		// let the user know that there's new events
		this.newRemoteEvents = true;
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		this.rows = this.storageService.get('activite-magasin-tableRows', this.rows);
		if (tableParams) {
			for (let prop in this.params) {
				if (tableParams[prop]) this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
		}
		this.mag_id = this.storageService.get('mag_id');
		this.exclusiveFilters = this.storageService.getForCurrentState('exclusiveFilters', this.exclusiveFilters, true);
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		this.paramChanged();
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);
			this.storageService.set('activite-magasin-tableRows', this.table.rows);
		}
		this.storageService.set('mag_id', this.mag_id);
		this.storageService.setForCurrentState('exclusiveFilters', this.exclusiveFilters, true);
	}

	paramChanged() {
		this.debouncedLoad.next(true);
	}

	reset() {
		this.params.search = null;
	}

	refresh(stayOnPage?: boolean) {
		if (this.table) {
			if (!stayOnPage) this.table._first = 0;
			this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
		}
	}

	load($event: any) {
		this.newRemoteEvents = false;
		// il faut un magasin
		// il faut une date de fin
		if (this.mag_id && this.dateRange && this.dateRange[1]) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);
			this.saveParamSet();

			let tmpParams = Object.assign({
				date_debut: simpleDateToString(startOfDay(this.dateRange[0])),
				date_fin: simpleDateToString(endOfDay(this.dateRange[1])),
			}, $event, this.params);

			this.prepareParams(tmpParams);

			if (this.exclusiveFilters.livres) {
				// OPTIONS LIVREES
				this.evenementService.getOptionsEvenement(tmpParams, this.mag_id)
				.subscribe({
					next: (response: any) => {
						this.totalRecordsOptions = response.total;
						this.valuesOptions = response.options_evenements;
					},
				})
				.add(() => { this.loading = false; });
			}
			else {
				// DEFAULT: les évènements
				this.evenementService.getList(tmpParams, this.mag_id)
				.subscribe({
					next: (response: any) => {
						this.totalRecords = response.total;
						this.values = this.prepareEvenements(response.evenements);
					},
				})
				.add(() => { this.loading = false; });
			}

		}
	}

	hasExclusiveFilters() {
		for (const [key, value] of Object.entries(this.exclusiveFilters)) {
			if (value) return true;
		}
		return false;
	}

	toggleExclusiveFilter(attr: string) {
		for (const [key, value] of Object.entries(this.exclusiveFilters)) {
			if (key != attr) {
				this.exclusiveFilters[key] = false;
			}
		}
		this.refresh();
	}

	prepareParams(params: any) {
		if (this.hasExclusiveFilters()) {
			params.masque = false;
			params.traite = false;
			for (const [key, value] of Object.entries(this.exclusiveFilters)) {
				if (value) {
					params[key] = value;
					break;
				}
			}
			// si on filtre sur les évènements en attente, on les affiche tous
			if (params['attente']) {
				params.date_debut = simpleDateToString(new Date(1970,0,1));
				params.date_fin = simpleDateToString(endOfDay(new Date()));
			}
		}
	}

	prepareEvenements(evenements: Evenement[]) {
		evenements.forEach((evenement: Evenement, index: number) => {
			evenements[index] = this.prepareEvenement(evenement);
		});
		return evenements;
	}

	prepareEvenement(evenement: Evenement) {
		let tmp = clone(evenement);
		const dateString = this.datePipe.transform(tmp.aud_date_modification, 'dd/MM/yyyy HH:mm');
		tmp.label = this.typeEvenementPipe.transform(tmp.eve_type);
		if (tmp.eve_date_attente_debut && !tmp.eve_date_attente_fin) {
			tmp.label += ' en attente ';
		}
		tmp.label += ` - Dernière modification ${dateString}`;
		if (this.canTerminerAttente(tmp)) {
			tmp.terminerAttenteMenuItems = [
				{
					label: 'Valider l\'option',
					icon: 'pi pi-check',
					command: (event: any) => {
						this.terminerAttente(tmp);
					}
				},
				{
					label: 'Annuler l\'attente',
					icon: 'pi pi-trash',
					command: (event: any) => {
						this.annulerAttente(tmp);
					}
				}
			];
		}
		if (tmp.eve_taux_marge_commerciale) {
			tmp.eve_taux_marge_commerciale = tmp.eve_taux_marge_commerciale * 100;
		}
		return tmp;
	}

	showEntreeLibreDialog(eve_entree: boolean) {
		const ref = this.dialogService.open(EntreeLibreDialogComponent, {
			data: {
				mag_id: this.mag_id,
				eve_entree: eve_entree,
				uti_id: this.authService.user!.uti_id,
				force_vendeur: !!this.isVendeur
			},
			header: eve_entree? 'Nouvelle entrée' : 'Nouveau retour',
		});

		ref.onClose.subscribe((entree: any) =>{
			if (typeof entree != 'undefined') {
				this.fetchNewEvenement(entree.eve_id);
			}
		});
	}

	showMilieuDialogClick(evenement: Evenement, attribute?: string) {
		this.showMilieuDialog(evenement, attribute)
		.subscribe()
	}

	showMilieuDialog(evenement: Evenement, attribute?: string) {
		this.valueBeforeEdit = clone(evenement);

		let header = 'Modification du milieu';
		if (!evenement.eve_milieu_modifie_le) {
			header = 'Confirmation du milieu';
		}

		const ref = this.dialogService.open(MilieuDialogComponent, {
			data: {
				evenement: clone(evenement)
			},
			header: header,
			style: {'max-width': '20rem'}
		});

		return new Observable<any>((subscriber: any) => {
			ref.onClose.subscribe((dialogResult: any) => {
				if (typeof dialogResult == 'undefined') {
					subscriber.error('milieu_canceled');
					subscriber.complete()
				}
				else {
					const index = this.getEvenementIndex(dialogResult);
					this.values[index] = this.prepareEvenement(dialogResult);
					subscriber.next(dialogResult);
					subscriber.complete();
				}
			});
		});
	}

	showVendeurDialog(evenement: Evenement) {
		this.valueBeforeEdit = clone(evenement);

		let header = 'Modification du vendeur';

		const ref = this.dialogService.open(VendeurDialogComponent, {
			data: {
				mag_id: evenement.mag_id,
				uti_vendeur: evenement.uti_vendeur,
			},
			header: header,
		});

		ref.onClose.subscribe((dialogResult: any) => {
			if (typeof dialogResult == 'undefined') {
				this.onEditCancel({data: evenement});
			}
			else {
				evenement.uti_vendeur = dialogResult;
				this.onEditComplete({data: evenement});
			}
		});
	}

	getEvenementIndex(evenement: Evenement|WebSocketMessageActiviteMagasin) {
		return this.values.findIndex((one: Evenement) => { return evenement.eve_id == one.eve_id;});
	}

	onEditComplete(event: any) {
		const evenement =	event.data;
		if (!simpleComparison(evenement, this.valueBeforeEdit)) {
			if (event.field == 'eve_mt_budget_ttc') {
				this.checkBudget(evenement)
				.subscribe(
					(ok: any) => {
						this.putEvenement(evenement).subscribe();
					},
					(nope: any) => {
						this.onEditCancel(event)
					}
				)
			}
			else {
				this.putEvenement(evenement).subscribe();
			}
		}
	}

	putEvenement(evenement: Evenement) {
		this.loading = true;
		return this.evenementService.putEvenement(evenement)
		.pipe(
			map(
				(response: Evenement) => {
					const index = this.getEvenementIndex(evenement);
					this.values[index] = this.prepareEvenement(response.evenement);
					if (response.message) {
						this.eventManager.emit('toast', {severity: 'info', summary: response.message});
					}
				}
			),
			catchError(
				(error: any) => {
					this.onEditCancel({data: evenement});
					this.editEnd();
					return throwError(error);
				}
			),
			tap((nimp: any) => {
				this.editEnd();
			})
		);
	}

	editEnd() {
		this.loading = false;
		this.valueBeforeEdit = null;
	}

	onEditCancel(event?: any) {
		const index = this.getEvenementIndex(event.data);
		if (this.values[index].updated) {
			// quelqu'un d'autre a mis à jour l'évènement
			// on le recharge
			this.valueBeforeEdit = null;
			this.updateEveDisplay(event.data);
		}
		else {
			this.values[index] = this.valueBeforeEdit;
			this.valueBeforeEdit = null;
		}
	}

	onEditInit(event: any) {
		this.valueBeforeEdit = clone(event.data);
	}

	maybeConfirmerMilieu(evenement: Evenement, attribute?: string) {
		if (evenement.eve_type == 'O' && !evenement.eve_milieu_modifie_le) {
			this.eventManager.emit('toast', {severity: 'info', summary: 'Veuillez d\'abord confirmer le milieu'});
			const index = this.getEvenementIndex(evenement);
			this.values[index] = this.valueBeforeEdit;
			return this.showMilieuDialog(this.valueBeforeEdit, attribute);
		}
		return of(evenement);
	}

	checkBudget(evenement: Evenement, budgetRequired?: boolean) {
		if (
			evenement.eve_type == 'O'
			&& !evenement.eve_mt_budget_ttc
			&& (
				budgetRequired
				|| evenement.eve_devis
				|| evenement.eve_commande
			)
		) {
			let message = 'Un devis ou une commande doit avoir un budget';
			if (budgetRequired) {
				message = 'Veuillez saisir un budget';
			}
			this.eventManager.emit('toast', {severity: 'info', summary: message });
			return throwError('budget_missing');
		}
		return of(evenement);
	}

	onEditCheckbox(event: any, evenement: Evenement, attribute: string) {
		let valueBefore: any = clone(evenement);
		if (typeof event.checked != 'undefined') {
			valueBefore[attribute] = !event.checked;
		}

		this.onEditInit({data: valueBefore});

		// si on est en train de cocher une de ces cases
		// il faut renseigner le budget au préalable
		const requiredFields: string[] = [
			'eve_devis',
			'eve_commande'
		];
		let budgetRequired: boolean = attribute && requiredFields.includes(attribute) && event.checked;

		this.checkBudget(evenement, budgetRequired)
		.pipe(
			switchMap(()=>{
				return this.maybeConfirmerMilieu(evenement, attribute)
			})
		)
		.subscribe(
			(edited: Evenement) => {
				// les contrôles sont passés, on peut modifier la coche pour de bon
				edited[attribute] = !!event.checked;
				this.onEditComplete({data: edited})
			},
			(checksErrored: any) => {
				// console.log('checksErrored', checksErrored);
				this.onEditCancel({data: evenement});
			}
		);
	}

	toggleMasqueEvenement(evenement: Evenement) {
		this.onEditInit({data: evenement});
		evenement.eve_masque = !evenement.eve_masque;
		this.onEditComplete({data: evenement});
	}

	isOwner(evenement: Evenement) {
		return this.authService.user!.uti_id == evenement.uti_vendeur;
	}

	canEdit(evenement: Evenement) {
		return !this.isVendeur || this.isOwner(evenement);
	}

	canEditVendeur(evenement: Evenement) {
		return !evenement.readonly
			&& !evenement.eve_masque
			&& !evenement.eve_date_attente_debut
			&& !this.isVendeur
		;
	}

	canEditMilieu(evenement: Evenement) {
		return !evenement.readonly
			&& !evenement.eve_date_attente_debut
			&& !evenement.eve_masque
			&& !evenement.eve_parent_attente
			&& this.canEdit(evenement)
		;
	}

	showCheckboxEntree(evenement: Evenement) {
		return evenement.eve_type != 'V' && !evenement.eve_parent_attente;
	}

	disableCheckboxEntree(evenement: Evenement) {
		return evenement.readonly
			|| evenement.eve_masque
			|| evenement.eve_prise_rdv
			|| evenement.eve_retours
			|| !!evenement.eve_date_attente_debut
			|| !!evenement.eve_parent_attente
			|| !this.canEdit(evenement)
		;
	}

	showCheckboxRetour(evenement: Evenement) {
		return evenement.eve_type != 'V' && !evenement.eve_parent_attente;
	}

	disableCheckboxRetour(evenement: Evenement) {
		return evenement.readonly
			|| evenement.eve_masque
			|| evenement.eve_entree
			|| !!evenement.eve_date_attente_debut
			|| !!evenement.eve_parent_attente
			|| !this.canEdit(evenement)
		;
	}

	disableCheckboxRdv(evenement: Evenement) {
		return evenement.readonly
			|| evenement.eve_masque
			|| evenement.retours
			|| !!evenement.eve_date_attente_debut
			|| !!evenement.eve_parent_attente
			|| !this.canEdit(evenement)
		;
	}

	disableBudgetTTC(evenement: Evenement) {
		return evenement.readonly
			|| evenement.eve_masque
			|| evenement.eve_type != 'O'
			|| !!evenement.eve_date_attente_debut
			|| !!evenement.eve_parent_attente
			|| !this.canEdit(evenement)
		;
	}

	showCheckboxDevis(evenement: Evenement) {
		return evenement.eve_type == 'O' && !evenement.eve_parent_attente;
	}

	disableCheckboxDevis(evenement: Evenement) {
		return evenement.readonly
			|| evenement.eve_masque
			|| !!evenement.eve_date_attente_debut
			|| !!evenement.eve_parent_attente
			|| !this.canEdit(evenement)
		;
	}

	showCheckboxCommande(evenement: Evenement) {
		return evenement.eve_type == 'O' || evenement.eve_type == 'V';
	}

	disableCheckboxCommande(evenement: Evenement) {
		return evenement.readonly
			|| evenement.eve_masque
			|| !!evenement.eve_date_attente_debut
			|| this.isVendeur
		;
	}

	showCheckboxB2B(evenement: Evenement) {
		return evenement.eve_commande;
	}

	disableCheckboxB2B(evenement: Evenement) {
		return evenement.readonly
			|| evenement.eve_masque
			|| !!evenement.eve_date_attente_debut
			|| this.isVendeur
		;
	}

	showCheckboxMasque(evenement: Evenement) {
		return !evenement.readonly
			&& !evenement.eve_date_attente_debut
			&& !this.isVendeur
		;
	}

	disableCheckboxMasque(evenement: Evenement) {
		return evenement.readonly
			|| evenement.eve_entree
			|| evenement.eve_retours
			|| evenement.eve_devis
			|| evenement.eve_commande
			|| evenement.eve_prise_rdv
			|| !!evenement.eve_date_attente_debut
		;
	}

	canSupprimer(evenement: Evenement) {
		return !this.isVendeur && evenement.eve_type == 'L';
	}

	canMettreEnAttente(evenement: Evenement) {
		return !this.isVendeur
			&& evenement.eve_type == 'O'
			&& !evenement.eve_commande
			&& !evenement.eve_date_attente_debut
			&& !evenement.eve_masque
		;
	}

	canTerminerAttente(evenement: Evenement) {
		return !this.isVendeur
			&& evenement.eve_type == 'O'
			&& evenement.eve_date_attente_debut
			&& !evenement.eve_date_attente_fin
		;
	}

	deleteEvenement(evenement: Evenement) {
		let message = `Souhaitez-vous vraiment supprimer cette entrée ?`;
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.evenementService.deleteEvenement(evenement)
				.subscribe(
					ok => {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Entrée supprimée'});
						this.refresh(true);
					}
				)
			}
		});
	}

	mettreEnAttenteClick(evenement: Evenement) {

		this.onEditInit({data: evenement});

		this.checkBudget(evenement, true)
		.pipe(
			switchMap(()=>{
				return this.maybeConfirmerMilieu(evenement);
			})
		)
		.subscribe(
			(edited: Evenement) => {
				// les contrôles sont passés, on peut mettre en attente
				this.avantDeMettreEnAttente(edited);
			},
			(checksErrored: any) => {
				// osef
			}
		);
	}

	avantDeMettreEnAttente(evenement: Evenement) {
		if (!evenement.eve_entree && !evenement.eve_retours) {
			this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: `Avez-vous pensé à cocher Entrée ou Retour ?`,
				acceptLabel: 'Ce n\'est pas nécessaire pour cet évènement',
				rejectLabel: 'Je veux le faire maintenant',
				accept: () => {
					setTimeout(() => {
						this.mettreEnAttente(evenement);
					}, 250);
				}
			});
		}
		else {
			this.mettreEnAttente(evenement);
		}
	}

	mettreEnAttente(evenement: Evenement) {
		const dateString = this.datePipe.transform(evenement.eve_date_evenement, 'yyyy/MM/dd HH:mm');
		let message = `Souhaitez-vous vraiment mettre l'option du ${dateString} en attente ?`;
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.evenementService.attenteEvenement(evenement, 'creer')
				.subscribe(
					ok => {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Option mise en attente'});
						this.refresh(true);
					}
				)
			}
		});
	}

	annulerAttente(evenement: Evenement) {
		const dateString = this.datePipe.transform(evenement.eve_date_evenement, 'yyyy/MM/dd HH:mm');
		let message = `Souhaitez-vous vraiment anuler l'attente sur l'option du ${dateString} ?`;
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.evenementService.attenteEvenement(evenement, 'annuler')
				.subscribe(
					ok => {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Attente annulée'});
						this.refresh(true);
					}
				)
			}
		});
	}

	terminerAttente(evenement: Evenement) {
		const dateString = this.datePipe.transform(evenement.eve_date_evenement, 'yyyy/MM/dd HH:mm');
		const ref = this.dialogService.open(TerminerAttenteDialogComponent, {
			data: {
				evenement: evenement
			},
			header: `Validation d'option`,
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (typeof dialogResult != 'undefined') {
				this.updateEveDisplay(evenement);
				this.fetchNewEvenement(dialogResult.eve_id);
			}
		});
	}

	showCommentaireDNVDialog(evenement: Evenement) {
		const ref = this.dialogService.open(CommentaireDNVDialogComponent, {
			data: {
				evenement: clone(evenement)
			},
			header: `DNV option ${evenement.option_evenement!.eve_option_id}`
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (typeof dialogResult != 'undefined') {
				this.updateOptionEvenementDisplay(dialogResult);
			}
		});
	}

	updateOptionEvenement(optionEvenement: OptionEvenement) {
		this.evenementService.putOptionEvenement(optionEvenement)
		.subscribe({
			next: (response: any) => {
				this.updateOptionEvenementDisplay(optionEvenement);
			}
		})
	}

	fetchAndUpdateOptionEvenementDisplay(mag_id: number, eve_option_id: string) {
		this.evenementService.getOptionEvenement(mag_id, eve_option_id)
		.subscribe({
			next: (response: OptionEvenement) => {
				this.updateOptionEvenementDisplay(response);
			}
		});
	}

	updateOptionEvenementDisplay(optionEvenement: OptionEvenement) {
		// replace option sur les évènements qui ont cette option
		this.values.forEach((one: Evenement, index: number) => {
			if (one.eve_option_id == optionEvenement.eve_option_id) {
				one.option_evenement = clone(optionEvenement);
			}
		})

		this.valuesOptions.forEach((one: Evenement, index: number) => {
			if (one.eve_option_id == optionEvenement.eve_option_id) {
				one.updated = false;
				one = Object.assign(one, optionEvenement);
				one.updated = true;
			}
		})
	}

	formatCommentaireDNV(optionEvenement: OptionEvenement) {
		return optionEvenement.ope_commentaire? `Mes prochaines actions\n\n${optionEvenement.ope_commentaire}` : '';
	}

	export() {
		this.exportLoading = true;
		let tmpParams = Object.assign({
			date_debut: simpleDateToString(startOfDay(this.dateRange[0])),
			date_fin: simpleDateToString(endOfDay(this.dateRange[1])),
		}, this.params);

		this.prepareParams(tmpParams);

		let apiCall: Observable<any>;

		if (this.exclusiveFilters.livres) {
			apiCall = this.evenementService.exportOptionsEvenement(tmpParams, this.mag_id);
		}
		else {
			apiCall = this.evenementService.export(tmpParams, this.mag_id);
		}

		apiCall.subscribe( {
			next: (response: any) => {
				createDownloadFromHttpResponse(response);
			},
		})
		.add(() => { this.exportLoading = false; });
	}
}
@NgModule({
	declarations: [
		ActiviteMagasinComponent
	],
	exports: [
		ActiviteMagasinComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		InputTextModule,
		MenuModule,
		TableModule,
		TooltipModule,
		GlobalModule,
		DaterangeSelectorModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		PrintButtonModule,
		StatutOptionSelectorModule,
		UtilisateurSelectorModule,
	],
})
export class ActiviteMagasinModule { }
