import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { BehaviorSubject } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { AnneeSelectorModule } from '@app/annee-selector';
import { ConfigService } from '@global/config.service';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { IndicateurService } from '@app/indicateur/indicateur.service';
import { StorageService } from '@global/storage.service';

import { RdvConfirmesDialogComponent } from '@app/evenement/rdv-confirmes-dialog';

import {
	arrayOfMonths,
	capitalize,
	clone,
	endOfMonth,
	padString,
	simpleDateToString,
} from '@helpers/utils';

@Component({
	selector: 'indicateurs-digital',
	templateUrl: './indicateurs-digital.html',
	providers: [DatePipe],
})
export class IndicateursDigitalComponent {

	loading: boolean = false;
	exportLoading: boolean = false;

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	values: any[];

	columns: any[];
	frozenColumns: any[];
	collapsedRows: {[key: string]: any} = {};

	rangeMois: Date[] = [];

	today: Date = new Date();
	mode: 'annee'|'libre';
	dateRange: Date[] = [];
	annee: number;

	minYear: number;
	maxDate: Date = new Date();

	mag_id: number;

	indicateurs = [
		{header: 'Performances page magasin', separator: true, logo: 'logo-enseigne'},
		{ind_code: 'bim_nb_consultations_page_mag'},
		{ind_code: 'bim_nb_rdv_confirmes'},
		{ind_code: 'taux_nb_rdv_confirmes_nb_rdv_demandes'},
		{ind_code: 'bim_nb_rdv_annules'},
		{ind_code: 'bim_nb_projet_distance'},
		{ind_code: 'bim_nb_clics_tel_page_mag'},

		{header: 'Performances page Google My Business', separator: true, logo: 'google-my-business'},
		{ind_code: 'bim_nb_vues_fiche_google'},
		{ind_code: 'bim_nb_clics_vers_site'},
		{ind_code: 'bim_nb_appels_tel_direct'},
		{ind_code: 'bim_nb_avis_google'},
	];

	title: string = '';

	constructor(
		private configService: ConfigService,
		private datePipe: DatePipe,
		private dialogService: DialogService,
		private indicateurService: IndicateurService,
		private storageService: StorageService,
	) {
		this.title = this.configService.getCurrentModuleTitle();
		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.load();
			}
		});
	}

	ngOnInit() {
		this.minYear = this.configService.config.annee_reference;
		this.getParamSet();
	}

	getParamSet() {
		this.mag_id = this.storageService.getForCurrentState('mag_id');
	}

	saveParamSet() {
		this.storageService.setForCurrentState('mag_id', this.mag_id);
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		let previousMode = this.mode;
		this.mode = event.mode;
		this.annee = this.dateRange[0].getFullYear();
		this.paramChanged();
	}

	paramChanged() {
		this.debouncedLoad.next(true);
	}

	load(event?: any) {
		if (this.mag_id) {
			let tmpParams = {
				mode: this.mode,
				date_debut: simpleDateToString(this.dateRange[0], true),
				date_fin: simpleDateToString(this.dateRange[1], true)
			};

			this.saveParamSet();

			this.loading = true;

			this.indicateurService.getIndicateursDigital(tmpParams, this.mag_id)
			.subscribe({
				next: (response: any) => {
					this.prepareColumns();
					this.values = this.arrayOfMonthsToArrayOfIndicateurs(response);
				}
			})
			.add(()=>{ this.loading = false; });
		}
	}

	prepareColumns() {
		this.columns = [];

		this.frozenColumns = [
			{header: ' ', frozen: true}
		];

		if (this.mode == 'annee') {
			this.rangeMois = arrayOfMonths(this.annee, 12);
			const todayMonthString = this.datePipe.transform(this.today, 'yyyy-MM');

			let nbMonths = this.rangeMois.length;
			for (let i = 0; i < nbMonths ; i++) {
				let oneMonth = this.rangeMois[i];
				const currentMonth = todayMonthString == this.datePipe.transform(oneMonth, 'yyyy-MM');
				const monthNumber = padString((oneMonth.getMonth() + 1).toString(), '0',  2);
				this.columns.push(
					{
						header: capitalize(this.datePipe.transform(oneMonth, 'MMMM'), true),
						class: 'col-mois',
						currentMonth: currentMonth
					}
				);
			}
		}
		else {
			this.rangeMois = arrayOfMonths(this.annee, 1);
			let strDebut = this.datePipe.transform(this.dateRange[0], 'dd/MM/yy');
			let strFin = this.datePipe.transform(this.dateRange[1], 'dd/MM/yy');
			this.columns.push(
				{
					header: `Du ${strDebut} au ${strFin}`,
					class: 'nowrap',
				}
			);
		}
	}

	arrayOfMonthsToArrayOfIndicateurs(data: any) {
		let arrayOfIndicateurs: any[] = [];

		this.indicateurs.forEach((indicateur: any) => {
			if (indicateur.separator) {
				arrayOfIndicateurs.push(clone(indicateur));
			}
			else {
				let mois = [];
				for (let i = 0; i < this.rangeMois.length; i++) {
					let tmp: any = {};
					if (data[i]) {
						tmp = {
							annee: data[i].annee,
							mois: data[i].mois,
							value: data[i][indicateur.ind_code],
						};
					}
					mois.push(tmp);
				}
				arrayOfIndicateurs.push(
					Object.assign({}, indicateur, {
						mois: mois
					})
				);
			}

		});
		return arrayOfIndicateurs;
	}

	export() {
		let tmpParams = {
			mode: this.mode,
			date_debut: simpleDateToString(this.dateRange[0], true),
			date_fin: simpleDateToString(this.dateRange[1], true)
		};

		this.exportLoading = true;
		this.indicateurService.exportIndicateursDigital(tmpParams, this.mag_id)
		.subscribe()
		.add(() => {
			this.exportLoading = false;
		});
	}

	showRdvConfirmeDialog(mois: number) {
		let dateRange: Date[];
		dateRange = clone(this.dateRange);
		if (this.mode == 'annee') {
			dateRange[0].setMonth(mois);
			dateRange[1] = endOfMonth(dateRange[0]);
		}

		const ref = this.dialogService.open(RdvConfirmesDialogComponent, {
			data: {
				dateRange: dateRange,
				mag_id: this.mag_id,
			},
			header: 'RDV Confirmés',
			maximizable: true
		});

		ref.onClose.subscribe(dialogResult =>{

		});
	}

}
@NgModule({
	declarations: [
		IndicateursDigitalComponent
	],
	exports: [
		IndicateursDigitalComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		TableModule,
		AnneeSelectorModule,
		DaterangeSelectorModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		MagasinSelectorModule,
	],
})
export class IndicateursDigitalModule { }
