import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnalyticsService } from '@global/analytics.service';

import {
	clone,
	convertDateFieldsToDate,
	convertDateFieldsToString,
	createDownloadFromHttpResponse,
	prepareQueryParams,
} from '@helpers/utils';

export type ObjectifNational = {
	[key: string]: any;
	ona_id?: number;
	ens_id?: number;
	ona_annee?: number;
	ona_mois?: number;
	ona_note_google?: number;
	ona_satisfaction_q1?: number;
	ona_satisfaction_q2?: number;
	ona_satisfaction_q3?: number;
	ona_satisfaction_q3_nps?: number;
	ona_satisfaction_q4?: number;
	ona_satisfaction_q4_nps?: number;
}

export class ObjectifMagasin {
	oma_id?: number;
	mag_id?: number;
	oma_annee?: number;
	oma_mois?: number;
	oma_ca_prise_commande_htple?: number|null;
	restant?: number|null;
	total?: number|null;
}

export type ObjectifVendeur = {
	ove_id?: number;
	mag_id?: number;
	ove_annee?: number;
	ove_mois?: number;
	oma_ca_prise_commande_htple?: number;
}

export type ObjectifAction = {
	[key: string]: any;
	obv_id?: number;
	mag_id: number;
	uti_id: number|null;
	obv_taux_nb_ventes_nb_entrees: number|null;
	obv_ca_prise_commande_htple: number|null;
	obv_taux_marge_commerciale: number|null;
	obv_marge_commerciale: number|null;
	obv_taux_marge_facture: number|null;
	obv_note_google: number|null;
	obv_nb_avis_google: number|null;
	obv_note_wizville: number|null;
}

export class DetailPlanActions {
	dpa_id?: number;
	pla_id: number;
	mag_id: number;
	dpa_date_debut: Date;
	dpa_date_fin: Date|null = null;
	dpa_libelle: string = '';
	dpa_personnes_impliquees: string|null = null;
	dpa_chiffre: string|null = null;
	dpa_detail: string|null = null;
	dpa_date_terminee?: Date|null = null;

	aud_date_creation: Date|null = null;

	constructor(pla_id: number, mag_id: number) {
		this.pla_id = pla_id;
		this.mag_id = mag_id;
		this.dpa_date_debut = new Date();
	}
}

export class PlanActions {
	pla_id?: number;
	mag_id: number;
	uti_id: number|null;
	pla_libelle: string;
	pla_chiffre: string;
	details: DetailPlanActions[];

	constructor(mag_id: number, uti_id: number|null = null) {
		this.mag_id = mag_id;
		this.uti_id = uti_id;
	}
}

@Injectable({ providedIn: 'root' })
export class ObjectifsService {

	constructor(
		private analyticsService: AnalyticsService,
		private http: HttpClient,
	) {

	}

	getObjectifsNationaux(params: any, analytics: boolean = true) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_objectifs_nationaux', tmpParams.params);
		return this.http.get<any>(`/objectifs_nationaux`, tmpParams)
	}

	exportObjectifsNationaux(params: any) {
		let tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_objectifs_nationaux', tmpParams.params);
		return this.http.get<any>(`/objectifs_nationaux/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	putObjectifNational(objectif: ObjectifNational) {
		this.analyticsService.trackEvent('edit_objectif_national', {
			ona_annee: objectif.ona_annee,
			ona_mois: objectif.ona_mois,
		});
		return this.http.put<any>(`/objectifs_nationaux`, objectif);
	}

	getObjectifsMagasins(params: any) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_objectifs_magasins', tmpParams.params);
		return this.http.get<any>(`/objectifs_magasins`, tmpParams)
		.pipe(map((response: any) => {
			this.prepareMagasinFromServer(response, params.annee);
			return response;
		}));
	}

	exportObjectifsMagasins(params: any) {
		let tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_objectifs_magasins', tmpParams.params);
		return this.http.get<any>(`/objectifs_magasins/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	putObjectifMagasin(objectif: ObjectifMagasin) {
		this.analyticsService.trackEvent('edit_objectif_magasin', {
			mag_id: objectif.mag_id,
			oma_annee: objectif.oma_annee,
			oma_mois: objectif.oma_mois,
		});
		return this.http.put<any>(`/objectifs_magasins`, objectif);
	}

	prepareMagasinFromServer(magasin: any|any[], annee?: number) {
		if (Array.isArray(magasin)) {
			magasin.forEach((one: any) => {
				this.prepareMagasinFromServer(one, annee);
			});
		}
		else {
			if (annee) {
				if (!magasin.objectifs_magasins) magasin.objectifs_magasins = [];
				let tmpObjectifsMagasins = [];
				for (let i = 1 ; i <= 12; i++) {
					let existingIndex = magasin.objectifs_magasins.findIndex((one: any) => {
						return one.oma_mois == i;
					});
					if (existingIndex > -1) {
						tmpObjectifsMagasins.push(magasin.objectifs_magasins[existingIndex]);
					}
					else {
						let tmpObjectif: ObjectifMagasin = {
							oma_annee: annee,
							oma_mois: i,
							oma_ca_prise_commande_htple: null,
						}
						tmpObjectifsMagasins.push(tmpObjectif);
					}
				}
				magasin.objectifs_magasins = tmpObjectifsMagasins;
			}
		}

	}

	getObjectifsVendeursParMagasin(params: any) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_objectifs_vendeurs', tmpParams.params);
		return this.http.get<any>(`/objectifs_vendeurs`, tmpParams)
		.pipe(map((response: any) => {
			this.prepareMagasinFromServer(response, params.annee);
			return response;
		}));
	}

	exportObjectifsVendeursParMagasin(params: any) {
		let tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_objectifs_vendeurs', tmpParams.params);
		return this.http.get<any>(`/objectifs_vendeurs/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	putObjectifVendeur(objectif: ObjectifVendeur) {
		this.analyticsService.trackEvent('edit_objectif_vendeur', {
			mag_id: objectif.mag_id,
			ove_annee: objectif.ove_annee,
			ove_mois: objectif.ove_mois,
		});
		return this.http.put<any>(`/objectifs_vendeurs`, objectif);
	}


	getObjectifAction(mag_id: number, uti_id: number|null = null) {
		let tmpParams = prepareQueryParams({uti_id: uti_id});
		return this.http.get<any>(`/magasins/${mag_id}/objectifs`, tmpParams);
	}

	putObjectifAction(objectif: ObjectifAction) {
		return this.http.put<any>(`/magasins/${objectif.mag_id}/objectifs/${objectif.obv_id}`, objectif);
	}


	getPlanActions(mag_id: number, uti_id: number|null = null) {
		let tmpParams = prepareQueryParams({uti_id: uti_id});
		return this.http.get<any>(`/magasins/${mag_id}/plans_actions`, tmpParams)
		.pipe(map(
			(response: any) => {
				this.preparePlansActions(response.plans_actions);
				return response;
			})
		);
	}

	postPlanActions(planActions: PlanActions) {
		return this.http.post<any>(`/magasins/${planActions.mag_id}/plans_actions`, planActions);
	}

	putPlanActions(planActions: PlanActions) {
		return this.http.put<any>(`/magasins/${planActions.mag_id}/plans_actions/${planActions.pla_id}`, planActions);
	}

	deletePlanActions(planActions: PlanActions) {
		return this.http.delete<any>(`/magasins/${planActions.mag_id}/plans_actions/${planActions.pla_id}`);
	}

	postDetailPlanActions(detailPlanActions: DetailPlanActions) {
		let tmp: DetailPlanActions = this.prepareDetailPlanActions(detailPlanActions, true);
		return this.http.post<any>(`/magasins/${detailPlanActions.mag_id}/plans_actions/${detailPlanActions.pla_id}/details`, tmp);
	}

	putDetailPlanActions(detailPlanActions: DetailPlanActions) {
		let tmp: DetailPlanActions = this.prepareDetailPlanActions(detailPlanActions, true);
		return this.http.put<any>(`/magasins/${detailPlanActions.mag_id}/plans_actions/${detailPlanActions.pla_id}/details/${detailPlanActions.dpa_id}`, tmp);
	}

	deleteDetailPlanActions(detailPlanActions: DetailPlanActions) {
		return this.http.delete<any>(`/magasins/${detailPlanActions.mag_id}/plans_actions/${detailPlanActions.pla_id}/details/${detailPlanActions.dpa_id}`);
	}

	preparePlansActions(items: PlanActions[]) {
		items.forEach((item: PlanActions, index: number) => {
			items[index] = this.preparePlanActions(item);
		})
		return items;
	}

	preparePlanActions(planActions: PlanActions) {
		let tmp = clone(planActions);
		this.prepareDetailsPlanActions(tmp.details);
		tmp.details.forEach((one: DetailPlanActions) => {
			one.mag_id = tmp.mag_id;
		})
		return tmp;
	}

	prepareDetailsPlanActions(items: DetailPlanActions[]) {
		items.forEach((item: DetailPlanActions, index: number) => {
			items[index] = this.prepareDetailPlanActions(item);
		})
	}

	prepareDetailPlanActions(detailPlanActions: DetailPlanActions, forPost: boolean = false) {
		let tmp = clone(detailPlanActions);
		if (forPost) {
			convertDateFieldsToString(tmp, false, true);
		}
		else {
			convertDateFieldsToDate(tmp, false, true);
		}
		return tmp;
	}


}
