<h1 class="flex-shrink-0" *ngIf="title">{{title}}</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<daterange-selector
			#dateRangeSelector
			class="formgrid grid p-fluid"
			[dateRange]="dateRange"
			[useStorage]="true"
			[rangeMode]="true"
			[modes]="['mois','libre']"
			[minDate]="minDate"
			[maxDate]="maxDate"
			[defaultEnd]="defaultMaxDate"
			[disableModeChange]="disableDateRangeModeChange"
			[disabled]="loading || exportLoading"
			(onDateChange)="onDateChange($event)"
			[disabled]="loading"
		></daterange-selector>
	</div>

	<div class="field col-fixed">
		<label for="challenge">Challenge</label>
		<challenge-selector
			name="challenge"
			[(ngModel)]="params.cha_id"
			[required]="false"
			[nullOption]="true"
			[nullIfInvalid]="true"
			[disabled]="loading || exportLoading"
			(onSelectionChange)="onChallengeChange($event)"
		></challenge-selector>
	</div>

	<div class="field col-fixed">
		<label for="region_commerciale">Région</label>
		<region-commerciale-selector
			name="region_commerciale"
			[(ngModel)]="params.region_commerciale"
			[required]="false"
			[nullOption]="true"
			[nullIfInvalid]="true"
			[disabled]="loading || exportLoading"
			placeholder="France"
			(ngModelChange)="paramChanged()"
		></region-commerciale-selector>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="refresh()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end" *ngIf="canExport">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>
</div>

<div class="min-table-height">
	<p-table
		#tableClassement
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[paginator]="canExport"
		[rows]="rows"
		[rowsPerPageOptions]="[20,50,{showAll: 'Tous'}]"
		[showCurrentPageReport]="true"
		[totalRecords]="totalRecords"
		currentPageReportTemplate="{totalRecords} élément(s)"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover"
	>
		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th class="text-center col-rang not-last-frozen" pFrozenColumn>Rang</th>
				<th class="text-center" pFrozenColumn>Vendeur</th>
				<th class="text-center">Poule Challenge</th>
				<th class="text-center">Magasin</th>
				<th class="text-center col-indicateur">
					<indicateur-label-display ind_code="ca_prise_commande_htple" [tooltip]="true"></indicateur-label-display>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-vendeur>
			<tr>
				<td class="text-center col-rang not-last-frozen" pFrozenColumn>
					{{ vendeur.rang }}
				</td>
				<td class="" pFrozenColumn>
					{{ vendeur.uti_prenom }} {{ vendeur.uti_nom }}
				</td>
				<td class="text-center" [ngStyle]="{'background-color': vendeur?.pou_couleur}">
					<span [ngStyle]="{'color': vendeur.pou_couleur_texte}" *ngIf="vendeur.pou_libelle">
						{{vendeur.pou_libelle}}
					</span>
				</td>
				<td class="">
					{{ vendeur.mag_nom_court }} ({{ vendeur.mag_id }})
				</td>

				<td class="text-right">
					<ng-container *ngIf="vendeur.masque">
						<i class="pi pi-ban" title="Vous n'êtes pas autorisé à voir cette valeur"></i>
					</ng-container>
					<ng-container *ngIf="!vendeur.masque">
						<indicateur-value-display ind_code="ca_prise_commande_htple" [value]="vendeur.ca_prise_commande_htple"></indicateur-value-display>
					</ng-container>
				</td>

			</tr>
		</ng-template>
	</p-table>
</div>
