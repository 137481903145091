import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';

import { BlockUIModule } from '@app/primeng-overrides/blockui';
import { ButtonModule } from 'primeng/button';

import { NgxMasonryModule } from 'ngx-masonry';

import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { BlocRatiosModule } from '@app/cockpit/bloc-ratios';
import { BlocValeursModule } from '@app/cockpit/bloc-valeurs';
import { BlocObjectifsModule } from '@app/cockpit/bloc-objectifs';
import { BlocPanierModule } from '@app/cockpit/bloc-panier';
import { BlocEvolutionCAModule } from '@app/cockpit/bloc-evolution-ca';
import { BlocIndicateursParMilieuModule } from '@app/cockpit/bloc-indicateurs-par-milieu';
import { DetailVentesComponent } from '@app/detail-ventes';
import { IndicateurService } from '@app/indicateur/indicateur.service';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { StorageService } from '@global/storage.service';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';

import {
	endOfMonth,
	simpleDateToString,
} from '@helpers/utils';

@Component({
	selector: 'cockpit',
	templateUrl: './cockpit.html',
	providers: []
})
export class CockpitComponent {

	loading: boolean;

	cockpitMode: string;
	dateRange: Date[] = [];
	mag_id: number|null|undefined = null;
	uti_id: number|null|undefined = null;

	values: any = {};
	evolutions: any;
	objectifs: any = {};
	maxDate: Date = endOfMonth(new Date());

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private dialogService: DialogService,
		private indicateurService: IndicateurService,
		private storageService: StorageService,
	) {

		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.load();
			}
		});

	}

	ngOnInit() {
		this.getParamSet();
	}

	getParamSet() {
		this.mag_id = this.storageService.get('mag_id');
		this.uti_id = this.storageService.get('uti_id');
	}

	saveParamSet() {
		this.storageService.set('mag_id', this.mag_id);
		this.storageService.set('uti_id', this.uti_id);
	}

	paramChanged() {
		this.debouncedLoad.next(true);
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		let previousMode = this.cockpitMode;
		this.cockpitMode = event.mode;
		this.paramChanged();
	}

	load() {
		this.saveParamSet();
		let tmpParams = {
			mode: this.cockpitMode,
			mag_id: this.mag_id,
			uti_vendeur: this.uti_id,
			date_debut: simpleDateToString(this.dateRange[0], true),
			date_fin: simpleDateToString(this.dateRange[1], true)
		};

		this.loading = true;
		forkJoin(
			this.loadValeurs(tmpParams),
			this.loadEvolution(tmpParams),
		)
		.subscribe()
		.add(() => {this.loading = false;})
	}

	loadValeurs(params: any): Observable<any> {
		return this.indicateurService.getCockpit(params)
		.pipe(
			map(
				(response: any) => {
					this.values = response;
					this.objectifs = {
						'N': this.prepareObjectif(this.values, 'N'),
						'N-1': this.prepareObjectif(this.values, 'N-1')
					}
				}
			)
		);
	}

	prepareObjectif(obj: any, attr: string) {
		return {
			ca_prise_commande_htple: obj[attr].ca_prise_commande_htple,
			ca_prise_commande_htple_b2b: obj[attr].ca_prise_commande_htple_b2b,
			objectif_ca_prise_commande_htple: obj[attr].objectif_ca_prise_commande_htple,
			taux_mt_ventes_htple_mt_objectif_htple: (obj[attr].objectif_ca_prise_commande_htple == 0)? Infinity: obj[attr].ca_prise_commande_htple/obj[attr].objectif_ca_prise_commande_htple *100,
			mt_devis_htple: obj[attr].mt_devis_htple,
			taux_mt_ventes_htple_mt_devis_htple: (obj[attr].mt_devis_htple == 0)? Infinity : obj[attr].ca_prise_commande_htple/obj[attr].mt_devis_htple *100,
		}
	}

	loadEvolution(params: any): Observable<any> {
		return this.indicateurService.getEvolutionCA(params)
		.pipe(
			map(
				(response: any) => {
					this.evolutions = response;
				}
			)
		);
	}

	showDetailVentesClick(event: any) {
		const ref = this.dialogService.open(DetailVentesComponent, {
			data: {
				dateRange: this.dateRange,
				mag_id: this.mag_id,
				uti_id: this.uti_id
			},
			header: 'Détail des ventes',
			maximizable: true
		});

		ref.onClose.subscribe(dialogResult =>{

		});
	}

}
@NgModule({
	declarations: [
		CockpitComponent
	],
	exports: [
		CockpitComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		BlockUIModule,
		NgxMasonryModule,
		DaterangeSelectorModule,
		MagasinSelectorModule,
		UtilisateurSelectorModule,
		BlocRatiosModule,
		BlocValeursModule,
		BlocObjectifsModule,
		BlocPanierModule,
		BlocEvolutionCAModule,
		BlocIndicateursParMilieuModule,
	],
})
export class ChoixEvenementsModule { }
