import { Component, NgModule, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';

import { AnalyticsService } from '@global/analytics.service';
import { EventManagerService } from '@global/event-manager.service';
import { isChrome, printElement } from '@helpers/utils';

@Component({
	selector: 'print-button',
	templateUrl: './print-button.html',
	providers: [DatePipe]
})
export class PrintButtonComponent implements OnInit {

	@Input() printableQuerySelector: string;
	@Input() filename: string|Function;
	@Input() autoSuffixDatetime: boolean = true;
	@Input() disabled: boolean;
	@Input() dimensions: any;

	@Output() printStart: EventEmitter<any> = new EventEmitter();
	@Output() printEnd: EventEmitter<any> = new EventEmitter();

	ready: boolean;
	printMenuItems: MenuItem[] = [];
	isChrome: boolean = false;
	printing: boolean;

	constructor(
		private eventManager: EventManagerService,
		private datePipe: DatePipe
	) {
		this.isChrome = isChrome();
	}

	ngOnInit() {

		if (!this.printableQuerySelector) {
			console.log('PrintButtonComponent', 'Need a query selector');
		}
		else if (!this.filename) {
			console.log('PrintButtonComponent', 'Need a filename');
		}
		else {
			this.printMenuItems = [
				{
					label: 'Presse-papier',
					command: (event: any) => {
						this.print(true);
					}
				},
				{
					label: 'Sauvegarder',
					command: (event: any) => {
						this.print();
					}
				},
			];
			this.ready = true;
		}
	}

	suffixDatetime(str: string, separator?: string): string {
		let nowStr = this.datePipe.transform(new Date(), 'yyyyMMdd_HHmmss');
		let sep = separator? separator : '_';
		return `${str}${sep}${nowStr}`;
	}


	print(toClipboard?: boolean) {
		let tmpFilename = (typeof this.filename == 'function')? this.filename() : this.filename;
		if (this.autoSuffixDatetime) {
			tmpFilename = this.suffixDatetime(tmpFilename);
		}
		tmpFilename = tmpFilename + '.png';
		this.printStart.emit();
		printElement(this.printableQuerySelector, tmpFilename, toClipboard, this.dimensions)
		.then(
			() => {
				this.printEnd.emit();
				if (toClipboard) {
					this.eventManager.emit('toast', {severity: 'info', summary: 'Image copiée dans le presse-papier'});
				}
			},
			() => {this.printEnd.emit();},
		)
	}

}

@Component({
	selector: 'print-button-simple',
	template: `
		<button
			type="button"
			pButton
			class="ml-2 hide-print"
			label="Imprimer"
			icon="pi pi-print"
			(click)="print()"
			title="Imprimer"
		></button>
	`
})
export class PrintButtonSimpleComponent {

	@Input() track: boolean = false;
	@Input() track_mag_id: number|null = null;

	constructor(private analyticsService: AnalyticsService) {

	}

	print() {
		if (this.track) {
			this.analyticsService.track('I', this.track_mag_id);
		}
		window.print();
	}
}

@NgModule({
	declarations: [
		PrintButtonComponent,
		PrintButtonSimpleComponent
	],
	exports: [
		PrintButtonComponent,
		PrintButtonSimpleComponent
	],
	imports: [
		CommonModule,
		ButtonModule,
		MenuModule,
	],
})
export class PrintButtonModule { }
