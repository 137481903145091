import { Component, NgModule, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { TableModule } from '@app/primeng-overrides/table';
import { SelectButtonModule } from 'primeng/selectbutton';

import { AuthService } from '@app/auth/auth.service';
import { ConfigService } from '@global/config.service';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { ExportGraphiqueDialogComponent } from '@app/export-graphique-dialog';
import { GlobalModule } from '@global/global.module';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { ReportingService } from '@app/reporting/reporting.service';
import { StorageService } from '@global/storage.service';

import {
	endOfMonth,
	isEmpty,
	simpleDateToString
} from '@helpers/utils';

@Component({
	selector: 'reporting-activite',
	templateUrl: './reporting-activite.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportingActiviteComponent {

	loading: boolean = false;
	exportLoading: boolean = false;
	loaded: {[key: string]: boolean} = {
		region: false,
		magasin: false,
		vendeur: false
	};
	totalRecords: number;

	mode: string;
	dateRange: Date[] = [];
	regroupement: 'region'|'magasin'|'vendeur' = 'magasin';
	affichage: string = 'indicateur';
	values: any[];

	expandedRowKeys: {[key: string]: any} = {};

	optionsRegroupement = [
		{label: 'Région', value:'region'},
		{label: 'Magasin', value:'magasin'},
		{label: 'Vendeur', value:'vendeur'},
	];

	optionsAffichage = [
		{label: 'Indicateurs', value:'indicateur'},
		{label: 'Ratios', value:'ratio'},
	];

	droitsCanSeeMargeAchat: string[] = [
		'ag',
		'chef_ventes',
		'direction',
		'informatique',
		'manager',
	];

	columnsIndicateur = [
		{
			ind_code: 'nb_rdv_demandes',
			ind_code_label: 'nb_rdv_demandes',
			regroupements: ['magasin', 'region']
		},
		{
			ind_code: 'taux_nb_rdv_confirmes_nb_rdv_demandes',
			ind_code_label: 'taux_nb_rdv_confirmes_nb_rdv_demandes',
			regroupements: ['magasin', 'region']
		},
		{
			ind_code: 'nb_rdv_internet_periode',
			ind_code_label: 'nb_rdv_internet_periode',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'nb_entrees',
			ind_code_label: 'nb_entrees',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'nb_prises_rdv',
			ind_code_label: 'nb_prises_rdv',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'nb_retours',
			ind_code_label: 'nb_retours',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'nb_devis',
			ind_code_label: 'nb_devis',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'nb_ventes',
			ind_code_label: 'nb_ventes',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'mt_budget_ttc',
			ind_code_label: 'mt_budget_ttc',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'mt_devis_ttc',
			ind_code_label: 'mt_devis_ttc',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'mt_devis_htple',
			ind_code_label: 'mt_devis_htple',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'ca_prise_commande_htple',
			ind_code_label: 'ca_prise_commande_htple',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'ca_prise_commande_htple_b2b',
			ind_code_label: 'label_ca_prise_commande_htple_b2b',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'objectif_ca_prise_commande_htple',
			ind_code_label: 'objectif_ca_prise_commande_htple',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'taux_mt_ventes_htple_mt_objectif_htple',
			ind_code_label: 'taux_mt_ventes_htple_mt_objectif_htple',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'mt_marge_commerciale_ht',
			ind_code_label: 'mt_marge_commerciale_ht',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'taux_marge_commerciale',
			ind_code_label: 'taux_marge_commerciale',
			regroupements: ['magasin', 'region', 'vendeur']
		},
		{
			ind_code: 'mt_facture_htple',
			ind_code_label: 'label_report_activite_mt_facture_htple',
			regroupements: ['magasin', 'region', 'vendeur'],
			droits: this.droitsCanSeeMargeAchat
		},
		{
			ind_code: 'mt_marge_achat_ht',
			ind_code_label: 'label_report_activite_mt_marge_achat_ht',
			regroupements: ['magasin', 'region', 'vendeur'],
			droits: this.droitsCanSeeMargeAchat
		},
		{
			ind_code: 'taux_marge_achat_ht',
			ind_code_label: 'label_report_activite_taux_marge_achat_ht',
			regroupements: ['magasin', 'region', 'vendeur'],
			droits: this.droitsCanSeeMargeAchat
		},
	];

	columnsRatios = [
		{ind_code: 'taux_nb_ventes_nb_entrees'},
		{ind_code: 'taux_nb_devis_nb_entrees'},
		{ind_code: 'taux_nb_ventes_nb_devis'},
		{ind_code: 'taux_marge_achat_ht'},
		{ind_code: 'avg_vente_htple'},
		{ind_code: 'avg_devis_htple'},
	];

	title: string = '';

	constructor(
		private authService: AuthService,
		private configService: ConfigService,
		private dialogService: DialogService,
		private reportingService: ReportingService,
		private storageService: StorageService,
		private changeDetector: ChangeDetectorRef
	) {
		this.title = this.configService.getCurrentModuleTitle();
	}

	ngOnInit() {
		this.getParamSet();
		this.prepareColumns();
	}

	getParamSet() {
		this.regroupement = this.storageService.getForCurrentState('regroupement', 'magasin');
		this.affichage = this.storageService.getForCurrentState('affichage', 'indicateur');
		this.expandedRowKeys = this.storageService.getForCurrentState('expandedRowKeys', this.expandedRowKeys);
		// this.loaded = this.storageService.getForCurrentState('loaded', this.loaded);
	}

	saveParamSet() {
		this.storageService.setForCurrentState('regroupement', this.regroupement);
		this.storageService.setForCurrentState('affichage', this.affichage);
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
		// this.storageService.setForCurrentState('loaded', this.loaded);
	}

	prepareColumns() {
		this.columnsIndicateur = this.columnsIndicateur.filter((item: any) => {
			return typeof item.droits == 'undefined'
				|| this.authService.checkIfHasRight(item.droits)
		});
	}

	onRowExpand(event: any) {
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
	}

	onRowCollapse(event: any) {
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		let previousMode = this.mode;
		this.mode = event.mode;
		this.load();
	}

	load(event?: any) {
		let tmpParams = {
			mode: this.mode,
			regroupement: this.regroupement,
			date_debut: simpleDateToString(this.dateRange[0], true),
			date_fin: simpleDateToString(this.dateRange[1], true)
		};

		this.loading = true;
		this.reportingService.getReportingActivite(tmpParams)
		.subscribe({
			next: (response: any) => {
				if (!this.loaded[this.regroupement]) {
					if (isEmpty(this.expandedRowKeys)) {
						response.forEach((one: any) => {
							this.expandedRowKeys[one.key] = true;
						});
					}
					this.loaded[this.regroupement] = true;
				}
				this.values = response;
				this.saveParamSet();
			},
		})
		.add(() => {
			this.loading = false;
			this.changeDetector.detectChanges();
		})
	}

	margeDisponible(data: any) {
		return data.mag_date_migration && this.dateRange[1] && this.dateRange[1] > endOfMonth(data.mag_date_migration);
	}

	export() {
		let tmpParams = {
			mode: this.mode,
			regroupement: this.regroupement,
			date_debut: simpleDateToString(this.dateRange[0], true),
			date_fin: simpleDateToString(this.dateRange[1], true)
		};

		this.exportLoading = true;
		this.reportingService.exportReportingActivite(tmpParams)
		.subscribe()
		.add(() => {
			this.exportLoading = false;
			this.changeDetector.detectChanges();
		});
	}

	showExportGraphiqueDialog() {
		const ref = this.dialogService.open(ExportGraphiqueDialogComponent, {
			data: {
				// mag_id: this.mag_id,
				exportFunc: this.reportingService.exportReportingActiviteGraphique
			},
			header: 'Export Graphiques Cockpit Room',
		});

		ref.onClose.subscribe((dialogResult: any) =>{

		});
	}

}
@NgModule({
	declarations: [
		ReportingActiviteComponent
	],
	exports: [
		ReportingActiviteComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		SelectButtonModule,
		TableModule,
		GlobalModule,
		DaterangeSelectorModule,
		IndicateurValueDisplayModule,
		IndicateurTooltipModule,
	],
})
export class ReportingActiviteModule { }
