import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { CommunicationLocaleService, CommunicationLocale } from '@app/communication-locale/communication-locale.service';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { GlobalModule } from '@global/global.module';
import { MediaCommunicationSelectorModule } from '@app/communication-locale/media-communication-selector';
import { NumberDisplayModule } from '@helpers/number-display';


@Component({
	selector: 'communication-locale-dialog',
	templateUrl: './communication-locale-dialog.html'
})
export class CommunicationLocaleDialogComponent implements OnInit, IEventListener {

	communication: CommunicationLocale;

	loading: boolean = false;

	today: Date = new Date();


	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private communicationLocaleService: CommunicationLocaleService,
		private eventManager: EventManagerService,
	) {
		this.communication	= this.config.data.communicationLocale;

	}

	ngOnInit(): void {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		this.loading = true;
		if (!this.communication.clo_id) {
			this.communicationLocaleService.postCommunicationLocale(this.communication)
			.subscribe({
				next: (response: any) => {
					this.eventManager.emit('toast', {severity: 'success', summary: `Contrat créé`});
					this.dialogRef.close(this.communication);
				}
			})
			.add(() => {this.loading = false;});
		}
		else {
			this.communicationLocaleService.putCommunicationLocale(this.communication)
			.subscribe({
				next: (response: any) => {
					this.eventManager.emit('toast', {severity: 'success', summary: `Contrat modifié`});
					this.dialogRef.close(this.communication);
				}
			})
			.add(() => {this.loading = false;});
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		InputTextModule,
		InputTextareaModule,
		GlobalModule,
		MediaCommunicationSelectorModule,
		NumberDisplayModule,
	],
	exports: [CommunicationLocaleDialogComponent],
	declarations: [CommunicationLocaleDialogComponent]
})
export class CommunicationLocaleDialogModule { }

