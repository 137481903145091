import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UIRouterGlobals } from '@uirouter/angular';

import { AuthService } from '@app/auth/auth.service';

import {
	clone,
	simpleUTCDateTimeToString,
} from '@helpers/utils';
import { environment, enseigne } from '@environments/environment';

declare const gtag: {
	(...args: any[]): () => void;
};

export interface EventProperties {
	[key: string]: any;
	timestamp: number;
	page?: string ;
	mag_id?: number;
	uti_email?: string;
	uti_id?: number;
	uti_uid?: string;
}

export class SuiviUtilisationAvanet {
	sua_date: string;
	mag_id: number|null;
	// uti_id: number;
	sua_code_module: string;
	sua_action: string;

	constructor(sua_code_module: string, sua_action: string, mag_id: number|null = null) {
		const now = new Date();
		this.sua_date = simpleUTCDateTimeToString(now);
		this.sua_code_module = sua_code_module;
		this.sua_action = sua_action;
		this.mag_id = mag_id;
	}
}

// "O": ouverture d'un module
// "I": impression
// "E": export excel
// "J": export jasper
export type ActionSuivi = 'O'|'I'|'E';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private uiRouterGlobals: UIRouterGlobals,
	) {
		this.loadAnalytics();
	}

	public loadAnalytics() {
		if (environment[enseigne].analyticsId) {
			// register google tag manager
			const gTagManagerScript = document.createElement('script');
			gTagManagerScript.async = true;
			gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment[enseigne].analyticsId}`;
			document.head.appendChild(gTagManagerScript);

			// register google analytics
			const gaScript = document.createElement('script');
			gaScript.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag() { dataLayer.push(arguments); }
				gtag('js', new Date());
				gtag('config', '${environment[enseigne].analyticsId}', {
					'enseigne': '${enseigne}'
				});
			`;
			document.head.appendChild(gaScript);
		}
	}

	private setCommonEventProperties(eventProperties: EventProperties) {
		const now = new Date();
		eventProperties.timestamp = Math.round(now.getTime()/1000);
		eventProperties.page = this.uiRouterGlobals.current.url;
		if (this.authService.user) {
			eventProperties.uti_email = this.authService.user.uti_email;
			eventProperties.uti_id = this.authService.user.uti_id;
		}
	}

	public trackEvent(name: string, eventProperties: any = {}) {
		if (window.hasOwnProperty('gtag')) {
			let tmpData = clone(eventProperties);
			this.setCommonEventProperties(tmpData);
			gtag('event', name, tmpData);
		}
	}

	public track(action: ActionSuivi, mag_id: number|null = null) {
		if (this.uiRouterGlobals.current.name) {
			const suivi: SuiviUtilisationAvanet = new SuiviUtilisationAvanet(this.uiRouterGlobals.current.name, action, mag_id);
			this.http.post<any>('/suivi_utilisation_module', suivi)
			.subscribe();
		}
	}

}
