import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { Observable, forkJoin, of } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from '@app/primeng-overrides/table';

import { ConfigService } from '@global/config.service';
import { GlobalModule } from '@global/global.module';
import { NumberDisplayModule } from '@helpers/number-display';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { PrintButtonModule } from '@app/print-button';
import { SimulateurService } from '@app/simulateur/simulateur.service';
import { MagasinSelectorModule, MagasinSelectorComponent } from '@app/magasin/magasin-selector';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';

import { enseigne } from '@environments/environment';
import { simpleDateToString } from '@helpers/utils';


export class SaisieNoteGoogle {
	objectif_note_google: number|null = null;
	vendeurs: any[] = new Array();

	constructor(nb_vendeurs: number = 1, nb_mois: number = 6) {
		this.vendeurs.length = nb_vendeurs;
		for (let i =0; i< nb_vendeurs; i++) {
			this.vendeurs[i] = new Array(nb_mois);
		}
	}

	dirty() {
		return this.vendeurs.some((oneVendeur: any) => {
			return oneVendeur.some((oneSaisie: any) => { return !!oneSaisie });
		});
	}
}

@Component({
	selector: 'note-google',
	templateUrl: './note-google.html',

})
export class SimulateurNoteGoogleComponent {

	@ViewChild('magasinSelector') magasinSelector: MagasinSelectorComponent;

	loading: boolean = false;

	simulation: any;
	saisies: SaisieNoteGoogle = new SaisieNoteGoogle();
	mag_id: number;
	previous_mag_id: number = 0;

	nb_mois: number = 6;
	vendeurs: Utilisateur[] = [];
	note_max: number = 5;

	today: Date = new Date();
	todayString: string = simpleDateToString(this.today, true);
	config: any;

	title: string = '';

	constructor(
		private configService: ConfigService,
		private confirmationService: ConfirmationService,
		private simulateurService: SimulateurService,
		private utilisateurService: UtilisateurService,
	) {
		this.title = this.configService.getCurrentModuleTitle();
		this.config = this.configService.config['config_simulateurs'][enseigne]['note_google'];
	}

	ngOnInit() {

	}

	paramChanged() {
		if (this.mag_id) {
			if (this.saisies.dirty()) {
				this.confirmationService.confirm({
				defaultFocus: 'reject',
					message: 'Le changement de magasin va effacer les saisies. Souhaitez-vous continuer ?',
					accept: () => {
						this.load();
					},
					reject: () => {
						this.mag_id = this.previous_mag_id;
					}
				});
			}
			else {
				this.load();
			}
		}
	}

	load() {
		this.loading = true;
		forkJoin([this.loadUsers(), this.loadSimulation()])
		.subscribe()
		.add(() => {
			this.previous_mag_id = this.mag_id;
			this.loading = false;
		});
	}

	loadUsers(): Observable<any> {
		let params = {
			mag_id: this.mag_id,
			date_debut: this.todayString,
			date_fin: this.todayString
		};
		return of(this.utilisateurService.getVendeursPeriode(params)
			.subscribe({
				next: (response: any) => {
					this.vendeurs = response;
					this.saisies = new SaisieNoteGoogle(this.vendeurs.length, this.nb_mois);
				}
			})
		);
	}

	loadSimulation(): Observable<any> {
		return of(this.simulateurService.getNoteGoogle(this.mag_id)
			.subscribe({
				next: (response: any) => {
					if (response) {
						this.simulation = response;
						// if l'une ou l'autre valeur == null
					}
				}
			})
		);
	}

	starsNeeded() {
		let objectif = this.saisies.objectif_note_google? this.saisies.objectif_note_google : 0;

		if (objectif <= 0 || objectif <= this.simulation.note_google_actuelle || objectif > this.note_max) {
			return null;
		}

		const divider = this.note_max - objectif;
		if (divider > 0) {
			return Math.ceil(
					(
						(this.simulation.nb_avis_actuel * objectif)
						- (this.simulation.nb_avis_actuel * this.simulation.note_google_actuelle)
					) / (this.note_max - objectif)
				)
			;
		}
		return Infinity;
	}

	objectifMois(mois: number) {
		let result = this.saisies.vendeurs.reduce(
  		(accumulator: number, currentValue: any) => {
  			if (!currentValue[mois]) return accumulator;
  			return accumulator + currentValue[mois];
  		},
	  	0
		);
		return result;
	}

}
@NgModule({
	declarations: [
		SimulateurNoteGoogleComponent
	],
	exports: [
		SimulateurNoteGoogleComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		TableModule,
		GlobalModule,
		IndicateurValueDisplayModule,
		IndicateurTooltipModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		PrintButtonModule
	],
})
export class SimulateurNoteGoogleModule { }
