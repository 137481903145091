import {Injectable, Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'timeAgo'})
export class TimeAgoPipe implements PipeTransform, OnDestroy {

	private timer: number|null;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private ngZone: NgZone
	) {

	}

	transform(value: string|Date, autorefresh: boolean = false, prefix: string = '') {

		let d = new Date(value);
		let now = new Date();
		let seconds = Math.round(Math.abs((now.getTime() - d.getTime())/1000));

		if (autorefresh) {
			this.removeTimer();
			let timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) *1000;
			this.timer = this.ngZone.runOutsideAngular(() => {
				if (typeof window !== 'undefined') {
					return window.setTimeout(() => {
						this.ngZone.run(() => this.changeDetectorRef.markForCheck());
					}, timeToUpdate);
				}
				return null;
			});
		}

		let minutes = Math.round(Math.abs(seconds / 60));
		let hours = Math.round(Math.abs(minutes / 60));
		let days = Math.round(Math.abs(hours / 24));
		let weeks = Math.round(Math.abs(days / 7));
		let months = Math.round(Math.abs(days/30.416));
		let years = Math.round(Math.abs(days/365));

		let result: string = '';
		if (Number.isNaN(seconds)){
			return '';
		}
		else if (seconds <= 45) {
			result = 'quelques secondes';
		}
		else if (seconds <= 90) {
			result = 'une minute';
		}
		else if (minutes <= 45) {
			result = `${minutes} minutes`;
		}
		else if (minutes <= 90) {
			result = 'une heure';
		}
		else if (hours <= 22) {
			result = `${hours} heures`;
		}
		else if (hours <= 36) {
			result = 'un jour';
		}
		else if (days <= 6) {
			result = `${days} jours`;
		}
		else if (days <= 14) {
			result = 'une semaine';
		}
		else if (days <= 29) {
			result = `${weeks} semaines`;
		}
		else if (days <= 45) {
			result = 'un mois';
		}
		else if (days <= 345) {
			result = `${months} mois`;
		}
		else if (days <= 545) {
			result = 'un an';
		}
		else { // (days > 545)
			result = `${years} ans`;
		}

		return `${prefix}${result}`;

	}

	ngOnDestroy(): void {
		this.removeTimer();
	}

	private removeTimer() {
		if (this.timer) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
	}

	private getSecondsUntilUpdate(seconds:number) {
		let min = 60;
		let hr = min * 60;
		let day = hr * 24;
		if (seconds < min) { // less than 1 min, update every 2 secs
			return 2;
		}
		else if (seconds < hr) { // less than an hour, update every 30 secs
			return 30;
		}
		else if (seconds < day) { // less then a day, update every 5 mins
			return 300;
		}
		else { // update every hour
			return 3600;
		}
	}
}
