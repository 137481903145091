<h1 class="flex-shrink-0" *ngIf="title">{{title}}</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<daterange-selector
			class="formgrid grid p-fluid"
			[disabled]="loading || exportLoading"
			[useStorage]="true"
			[rangeMode]="true"
			[modes]="['mois','libre']"
			(onDateChange)="onDateChange($event)"
		></daterange-selector>
	</div>

	<div class="field col-fixed">
		<label for="regroupement">Regroupement</label>
		<p-selectButton
			name="regroupement"
			[options]="optionsRegroupement"
			[(ngModel)]="regroupement"
			[disabled]="loading || exportLoading"
			(ngModelChange)="load()"
		></p-selectButton>
	</div>

	<div class="field col-fixed">
		<label for="affichage">Affichage</label>
		<p-selectButton
			name="mode"
			[options]="optionsAffichage"
			[(ngModel)]="affichage"
			[disabled]="loading || exportLoading"
			styleClass="block"
			(ngModelChange)="load()"
		></p-selectButton>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="load()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export graphiques"
			icon="pi pi-chart-line"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			(click)="showExportGraphiqueDialog()"
		></button>
	</div>

</div>

<div class="min-table-height flex flex-column">
	<p-table
		#reportingActivite
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-reporting-activite"
		dataKey="key"
		[expandedRowKeys]="expandedRowKeys"
		(onRowExpand)="onRowExpand($event)"
		(onRowCollapse)="onRowCollapse($event)"
	>
		<ng-template pTemplate="emptymessage">
			<tr class="text-center">
				<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr class="surface-0">
				<th class="text-center" pFrozenColumn ></th>

				<ng-container *ngIf="affichage == 'indicateur'">
					<ng-container *ngFor="let col of columnsIndicateur">
						<th
							class="text-center col-indicateur"
							[ngClass]="{'bg-primary-soft': col.ind_code == 'ca_prise_commande_htple'}"
							*ngIf="col.regroupements.includes(regroupement)"
						>
							<ng-container>
								{{col.ind_code_label | indicateur:'libelle'}} <indicateur-tooltip [ind_code]="col.ind_code_label"></indicateur-tooltip>
							</ng-container>


						</th>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="affichage == 'ratio'">
					<ng-container *ngFor="let col of columnsRatios">
						<th class="text-center col-indicateur">
							{{col.ind_code | indicateur:'libelle'}} <indicateur-tooltip [ind_code]="col.ind_code"></indicateur-tooltip>
						</th>
					</ng-container>
				</ng-container>
			</tr>
		</ng-template>


		<ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-expanded="expanded">

			<tr [ngClass]="{'row-total': rowIndex == 0, 'row-group': row.group}">

				<td class="text-left nowrap" [ngClass]="{'cell-th': row.children}" pFrozenColumn>
					<div class="flex align-items-center">
						<ng-container *ngIf="row.group == true">
							<button
								type="button"
								pButton
								pRipple
								[pRowToggler]="row"
								class="p-button-text p-button-rounded p-button-sm"
								[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
							></button>
						</ng-container>
						{{ row.label }}
					</div>
				</td>

				<ng-container *ngIf="affichage == 'indicateur'">
					<ng-container *ngFor="let col of columnsIndicateur">
						<ng-container *ngIf="col.regroupements.includes(regroupement)">
							<td class="text-right" [ngClass]="{'bg-primary-soft': col.ind_code == 'ca_prise_commande_htple'}">
								<indicateur-value-display
									[value]="row[col.ind_code]"
									[ind_code]="col.ind_code"
									[hideZero]="true"
								></indicateur-value-display>
							</td>
						</ng-container>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="affichage == 'ratio'">
					<ng-container *ngFor="let col of columnsRatios">
						<td class="text-right">
							<indicateur-value-display
								[value]="row[col.ind_code]"
								[ind_code]="col.ind_code"
								[hideZero]="true"
							></indicateur-value-display>
						</td>
					</ng-container>
				</ng-container>
			</tr>

		</ng-template>

		<ng-template pTemplate="rowexpansion" let-group>
			<tr class="surface-0" *ngFor="let row of group.children">
				<td class="text-left" pFrozenColumn >{{row.label}}</td>
				<ng-container *ngIf="affichage == 'indicateur'">
					<ng-container *ngFor="let col of columnsIndicateur">
						<ng-container *ngIf="col.regroupements.includes(regroupement)">
							<td class="text-right" [ngClass]="{'bg-primary-soft': col.ind_code == 'ca_prise_commande_htple'}">
								<indicateur-value-display
									[value]="row[col.ind_code]"
									[ind_code]="col.ind_code"
									[hideZero]="true"
								></indicateur-value-display>
								<span *ngIf="
									!row[col.ind_code]
									&& ['mt_marge_facture_ht', 'taux_marge_commerciale'].includes(col.ind_code)
									&& !margeDisponible(row)
								">Indisponible</span>
							</td>
						</ng-container>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="affichage == 'ratio'">
					<ng-container *ngFor="let col of columnsRatios">
						<td class="text-right">
							<indicateur-value-display
								[value]="row[col.ind_code]"
								[ind_code]="col.ind_code"
								[hideZero]="true"
							></indicateur-value-display>
						</td>
					</ng-container>
				</ng-container>
			</tr>
		</ng-template>

	</p-table>
	<div class="text-sm p-2">
	• Les valeurs affichées peuvent différer suivant le regroupement sélectionné. En mode "Vendeur" les données sont issues des indicateurs journaliers; en mode "Magasin" et "Région" les données sont issues des déclarations mensuelles si elles existent, sinon des indicateurs journaliers.
	</div>
</div>

