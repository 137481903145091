import { NgModule, Component, Input, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable } from 'rxjs';

import {
	NgForm,
	FormsModule,
	Validators,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule, Dropdown } from '@app/primeng-overrides/dropdown';


@Component({
	selector: 'statut-vendeur-selector',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => StatutVendeurSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			optionValue="libelle"
			optionLabel="libelle"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class StatutVendeurSelectorComponent implements OnInit, ControlValueAccessor {

	@Input('value') innerValue: string|null|undefined;
	@Input() name: string;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() autoSelectIfSingle: boolean;
	@Input() autoSelectFirst: boolean;
	@Input() silentInitialChangeIfSame: boolean;
	@Input() options: any[] = [];

	constructor(

	) {

	}

	ngOnInit() {
		this.setInitialValue();
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: string|null|undefined) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(value: string|null|undefined) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

	setInitialValue() {
		const incoming = this.innerValue;
		if (!this.options.length) {
			this.innerValue = null;
		}
		else {
			if (this.options.findIndex(one => {return one.libelle == this.innerValue; }) < 0) {
				if (this.nullIfInvalid) {
					this.innerValue = null;
				}
				if (this.autoSelectFirst || this.autoSelectIfSingle && this.options.length === 1) {
					this.innerValue = this.options[0].libelle;
				}
			}
		}
		if (incoming != this.innerValue || !this.silentInitialChangeIfSame) {
			this.value = this.innerValue;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [StatutVendeurSelectorComponent],
	declarations: [StatutVendeurSelectorComponent]
})
export class StatutVendeurSelectorModule { }
