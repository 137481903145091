import { TransitionService, Transition, StateObject } from '@uirouter/core';
import { AuthService } from '@app/auth/auth.service';
import { ConfigService } from '@global/config.service';
import { AnalyticsService } from '@global/analytics.service';

import { EventManagerService } from '@global/event-manager.service';


// This hook redirects to FOC oauth when no token was found in session storage
export const requiresAuthHook = function(transitionService: TransitionService) {
	// Matches if the destination state's data property contains certain values
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.requiresAuth; }
	};
	const redirect = (transition: Transition) => {
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		if (!authService.hasToken()) {
			console.log('No token', 'redirect to FOC authorization');
			authService.getAuthorizationCode(transition.to().name);
			return false;
		}
		return true;
	};
	transitionService.onBefore(criteria, redirect, {priority: 1000});
}


// hook to prevent accessing auth.* states when user is already logged in
export function forbiddenWhenAuthHook(transitionService: TransitionService) {
		// Matches if the destination state's data property contains certain values
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.forbiddenWhenAuth; }
	};
	const redirect = (transition: Transition) => {
		// console.log('forbiddenWhenAuthHook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		if (authService.hasToken()) {
			return stateService.target('home', undefined, {location: true});
		}
		return true;
	};
	transitionService.onBefore(criteria, redirect, {priority: 11});
}


// hook to force the fetching of the current user info before entering the substates
export function meGuardHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'meGuard' property
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.meGuard; }
	};
	const redirect = (transition: Transition) => {
		// console.log('meGuardHook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		const currentUser = authService.user;
		if (!!!currentUser) {
			return authService.getMe().toPromise()
			.then(
				ok => {
					return true;
				},
				err => {
					authService.logout({force: true});
					return Promise.reject();
				}
			);
		}
		return true;
	};
	transitionService.onBefore(criteria, redirect, {priority: 800});
}


// hook to prevent the transition to a state the user shouldn't access
export function canAccessHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => { return state.data && state.data.droits; }
	};
	const redirect = (transition: Transition) => {
		// console.log('canAccessHook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		const to = transition.to();
		if (authService.checkIfHasRight(to.data.droits)) {
			// console.log('Can access', to.name, to.data.droits);
			return true;
		}
		console.log('Cannot access', to.name, to.data.droits);
		return stateService.target('home', undefined, {location: true});
	};
	transitionService.onBefore(criteria, redirect, {priority: 10});
}


export function moduleActiveHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => {
			return state.parent.includes['root'] && state.name != 'root' ; // all states under "app.root"
		}
	};
	const redirect = (transition: Transition) => {
		// console.log('moduleActiveHook');
		const configService: ConfigService = transition.injector().get(ConfigService);
		const stateService = transition.router.stateService;
		const to = transition.to();
		if (configService.config && configService.isModuleActif(to.name)) {
			return true;
		}
		console.log('Module inactif', to.name);
		return stateService.target('home', undefined, {location: true});
	};
	transitionService.onFinish(criteria, redirect, {priority: 10});
}

export function logModuleAccessHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => {
			return true; // all states
		}
	};
	const logAccess = (transition: Transition) => {
		// console.log('moduleActiveHook');
		const configService: ConfigService = transition.injector().get(ConfigService);
		const to = transition.to();
		if (to.name && configService.getModuleConf(to.name)) {
			const analyticsService: AnalyticsService = transition.injector().get(AnalyticsService);
			analyticsService.track('O');
		}

	};
	transitionService.onSuccess(criteria, logAccess, {priority: 10});
}
