
<h1 class="flex align-items-center flex-shrink-0">
	Objectifs et Plan d'actions vendeur
	<print-button-simple [track]="true" [track_mag_id]="mag_id"></print-button-simple>
</h1>

<form class="grid flex-shrink-0" #objectifForm="ngForm">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Magasin</td>
				<td class="text-right">
					<magasin-selector
						#magasinSelector
						name="magasin"
						[(ngModel)]="mag_id"
						[nullOption]="false"
						[nullIfInvalid]="false"
						[mesMagasins]="false"
						[autoSelectFirst]="true"
						[silentInitialChangeIfSame]="true"
						class="hide-print"
					></magasin-selector>
					<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
				</td>
			</tr>
			<tr>
				<td class="cell-th">Vendeur</td>
				<td class="text-right">
					<utilisateur-selector
						#vendeurSelector
						name="vendeur"
						[(ngModel)]="uti_id"
						[nullOption]="true"
						[nullIfInvalid]="true"
						[mag_id]="mag_id"
						placeholder="Tous les vendeurs"
						(ngModelChange)="paramChanged()"
						class="hide-print"
					></utilisateur-selector>
					<span class="show-print">{{vendeurSelector.getEntityName()}}</span>
				</td>
			</tr>
		</ng-template>
	</p-table>


	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col flex justify-content-left mb-2 print-table-w-full"
		styleClass="p-datatable-sm p-datatable-gridlines"
		*ngIf="objectifs"
	>
		<ng-template pTemplate="header">
			<tr>
				<th colspan="2">Objectifs</th>
				<th>Commentaires</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body">
			<ng-container *ngFor="let indicateur of indicateurs">
				<tr
					*ngIf="
						indicateur.modes.includes('vendeur') && uti_id
						|| indicateur.modes.includes('magasin') && !uti_id
					"
				>
					<td class="cell-th nowrap">
						{{indicateur.label}}
					</td>
					<td class="text-right col-indicateur" [ngClass]="{'cell-input': indicateur.ind_code != 'mt_marge_facture'}">
						<div class="p-input-icon-right input-container">

							<ng-container *ngIf="indicateur.ind_code == 'mt_marge_facture'">
								<number-display
									[value]="objectifs.obv_ca_prise_commande_htple * objectifs.obv_taux_marge_commerciale / 100"
									type="short_currency"
									*ngIf="objectifs.obv_ca_prise_commande_htple && objectifs.obv_taux_marge_commerciale"
								></number-display>
							</ng-container>

							<ng-container *ngIf="indicateur.ind_code != 'mt_marge_facture'" [ngSwitch]="indicateur.type">
								<ng-container *ngSwitchCase="'currency'">
									<div class="p-input-icon-right input-container hide-print">
										<i class="pi ff-reset">€</i>
										<input
											type="text"
											pInputText
											name="{{indicateur.ind_code}}"
											gzInputNumberFormatter
											[(ngModel)]="objectifs[indicateur.ind_code]"
											[currency]="true"
											[decimals]="2"
											class="text-right"
											(onDebounce)="saisieChanged()"
											[debounce]="500"
										>
									</div>
									<number-display
										[value]="objectifs[indicateur.ind_code]"
										type="short_currency"
										class="show-print"
									></number-display>
								</ng-container>
								<ng-container *ngSwitchCase="'percent'">
									<div class="p-input-icon-right input-container hide-print">
										<i class="pi ff-reset">%</i>
										<input
											type="text"
											pInputText
											name="{{indicateur.ind_code}}"
											gzInputNumberFormatter
											[(ngModel)]="objectifs[indicateur.ind_code]"
											[currency]="true"
											[decimals]="2"
											class="text-right"
											(onDebounce)="saisieChanged()"
											[debounce]="500"
										>
									</div>
									<number-display
										[value]="objectifs[indicateur.ind_code]"
										type="short_percent"
										class="show-print"
									></number-display>
								</ng-container>
								<ng-container *ngSwitchCase="'quantity'">
									<input
										type="text"
										pInputText
										name="{{indicateur.ind_code}}"
										gzInputNumberFormatter
										[(ngModel)]="objectifs[indicateur.ind_code]"
										[currency]="false"
										[decimals]="0"
										class="text-right hide-print"
										(onDebounce)="saisieChanged()"
										[debounce]="500"
									>
									<number-display
										[value]="objectifs[indicateur.ind_code]"
										class="show-print"
									></number-display>
								</ng-container>
								<ng-container *ngSwitchCase="'short_decimal'">
									<input
										type="text"
										pInputText
										name="{{indicateur.ind_code}}"
										gzInputNumberFormatter
										[(ngModel)]="objectifs[indicateur.ind_code]"
										[currency]="false"
										[decimals]="2"
										class="text-right hide-print"
										(onDebounce)="saisieChanged()"
										[debounce]="500"
									>
									<number-display
										[value]="objectifs[indicateur.ind_code]"
										type="short_decimal"
										class="show-print"
									></number-display>
								</ng-container>
							</ng-container>

						</div>

					</td>
					<td class="cell-input min-w-30rem max-w-30rem">
						<textarea
							pInputTextarea
							name="{{ indicateur.commentAttr }}"
							[(ngModel)]="objectifs[indicateur.commentAttr]"
							[autoResize]="true"
							[rows]="1"
							(blur)="saisieChanged()"
							*ngIf="indicateur.commentAttr"
							class="w-full hide-print"
						></textarea>
						<pre class="show-print">{{objectifs[indicateur.commentAttr]}}</pre>
					</td>
				</tr>
			</ng-container>
		</ng-template>
	</p-table>

</form>

<table-plan-actions
	[mag_id]="mag_id"
	[uti_id]="uti_id"
	class="flex flex-column"
	*ngIf="mag_id"
></table-plan-actions>
