import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

import { ConfigService } from '@global/config.service';
import { EvenementService, Evenement } from '@app/evenement/evenement.service';
import { MagasinService } from '@app/magasin/magasin.service';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';

import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { isDateString, hasTouch, startOfMonth } from '@helpers/utils';

@Component({
	selector: 'entree-libre-dialog',
	templateUrl: './entree-libre-dialog.html'
})
export class EntreeLibreDialogComponent implements OnInit, IEventListener {

	mag_id: number;
	uti_id: number;
	force_vendeur: boolean;

	today: Date = new Date();

	datePivot: Date = new Date();
	minDate: Date = startOfMonth(new Date());
	maxDate: Date = new Date();

	evenement: Evenement = new Evenement();

	loading: boolean = false;
	hasTouch: boolean = hasTouch();

	constructor(
		private config: DynamicDialogConfig,
		private configService: ConfigService,
		private dialogRef: DynamicDialogRef,
		private evenementService: EvenementService,
		private eventManager: EventManagerService,
		private magasinService: MagasinService,
	) {
		this.mag_id = config.data.mag_id;
		this.uti_id = config.data.uti_id;
		this.force_vendeur = config.data.force_vendeur;

		this.evenement.mag_id = this.mag_id;
		this.evenement.uti_vendeur = this.uti_id;

		this.evenement.eve_entree = !!config.data.eve_entree;
		this.evenement.eve_retours = !this.evenement.eve_entree;
		this.evenement.eve_prise_rdv = false;

		this.setMinDate();

	}

	ngOnInit(): void {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	setMinDate() {
		this.loading = true;

		this.datePivot.setDate(this.configService.config.pivot_no_jour);
		if (this.today <= this.datePivot) {
			this.minDate.setMonth(this.minDate.getMonth() -1);
		}

		this.magasinService.getDateButoir(this.mag_id)
		.subscribe({
			next: (response: any) => {
				if (isDateString(response)) {
					this.minDate = new Date(response);
				}
			}
		})
		.add(() => { this.loading = false; });
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		// TODO
		// Vérifier que la date saisie n’est pas dans le mois précédent si la date système est > <paramètre applicatif du
		// dernier jour déclaration mensuelle> du mois.
		// Vérifier que l’heure saisie est comprise entre 07:00 et 22:00
		this.loading = true;
		this.evenementService.postEvenement(this.evenement)
		.subscribe({
			next: (response: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Entrée créée'});
				this.evenement.eve_id = response.eve_id;
				this.dialogRef.close(this.evenement);
			}
		})
		.add(() => { this.loading = false; });
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		InputTextModule,
		UtilisateurSelectorModule,
	],
	exports: [EntreeLibreDialogComponent],
	declarations: [EntreeLibreDialogComponent]
})
export class EntreeLibreDialogModule { }

