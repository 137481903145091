<h1 class="flex-shrink-0" *ngIf="title">{{title}}</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<daterange-selector
			#dateRangeSelector
			class="formgrid grid p-fluid"
			singleMode="mois"
			label="Mois"
			[maxDate]="maxDate"
			[defaultStart]="defaultDate"
			[defaultEnd]="defaultDate"
			[usePivot]="false"
			(onDateChange)="onDateChange($event)"
			[disabled]="loading || exportLoading"
		></daterange-selector>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<div class="p-inputgroup">
			<input pInputText type="text" [(ngModel)]="search" (onDebounce)="filter()" placeholder="Rechercher..." />
			<button
				type="button"
				class="p-inputgroup-addon"
				icon="pi pi-times"
				pButton
				label=""
				[disabled]="!search || loading || exportLoading"
				(click)="resetFilter()"
			></button>
		</div>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="refresh()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>
</div>


<div class="flex justify-content-center" *ngIf="!loading && !nodes.length">
	<p-message severity="info" text="Aucun magasin actif sur la période" styleClass="mr-2"></p-message>
</div>

<div class="min-table-height">
	<p-treeTable
		#tableDeclarations
		[value]="nodes"
		[rowTrackBy]="indexTracker"
		[lazy]="true"
		[loading]="loading || exportLoading"
		[columns]="columns"
		[frozenColumns]="frozen_columns"
		[scrollable]="true"
		scrollHeight="flex"
		frozenWidth="300px"
		(onNodeExpand)="onNodeExpand($event)"
		(onNodeCollapse)="onNodeCollapse($event)"
		(onEditComplete)="onEditComplete($event)"
		(onEditCancel)="onEditCancel($event)"
		(onEditInit)="onEditInit($event)"
		styleClass="p-treetable-sm p-datatable-gridlines table-hover table-declarations"
		*ngIf="nodes.length"
	>

		<ng-template pTemplate="colgroup" let-columns>
			<colgroup>
				<!-- <col class=""> -->
				<col *ngFor="let col of columns; index as i; trackBy: indexTracker" [ngClass]="col.class">
				<!-- <col class=""> -->
			</colgroup>
		</ng-template>

		<ng-template pTemplate="frozenheader" let-columns>
			<tr class="">
				<th
					class="text-left flex flex-column justify-content-center height-6rem"
				>
					<div>Magasins sans déclaration&nbsp;: {{countManquant}}</div>
					<div>Reste à valider&nbsp;: {{countResteAValider}}</div>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr class="">
				<th
					class="text-center"
					*ngFor="let col of columns; let i = index; trackBy: indexTracker; last as isLast"
				>
					<ng-container *ngIf="!isLast">
						<indicateur-label-display [ind_code]="col.ind_code" [tooltip]="true" ></indicateur-label-display>
						<i class="pi pi-info-circle middle" [pTooltip]="tooltipEcartPrixAchat" *ngIf="col.ind_code == 'dme_ecart_prix_achat'"></i>
					</ng-container>
					<span *ngIf="isLast">{{col.header}}</span>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="frozenbody" let-rowNode let-rowData="rowData">
			<ng-container *ngIf="!rowData.mag_id && !rowData.hidden">
				<!-- region -->
				<tr class="row-group">
					<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis" title="{{ rowData.mag_region_commerciale }}">
						<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
						<b>{{ rowData.mag_region_commerciale }}</b>
					</td>
				</tr>
			</ng-container>
			<ng-container *ngIf="rowData.mag_id && !rowData.hidden">
				<!-- declaration -->
				<tr
					class="row-child"
					[ngClass]="{
						'validation-magasin-manquante': !rowData.dme_date_validation_magasin,
						'validation-animateur-manquante': !rowData.dme_date_controle_animateur
					}"
				>
					<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis" title="{{rowData.infoValidation}}">
						{{ rowData.mag_nom_court }} ({{rowData.mag_id}})
					</td>
				</tr>
			</ng-container>
		</ng-template>

		<ng-template pTemplate="body" let-rowNode let-node="node" let-rowData="rowData" >

			<ng-container *ngIf="!rowData.mag_id && !rowData.hidden">
				<!-- region -->
				<tr	class="row-group">
					<td
						class="text-right col-indicateur"
						*ngFor="let col of columns; index as i; trackBy: indexTracker; last as isLast"
						[ngClass]="{'font-italic': col.ind_code && col.ind_code.startsWith('dme_') && rowData[col.ind_code+'_origine'] != rowData[col.ind_code]}"
					>
						<ng-container *ngIf="!isLast">

							<indicateur-value-display
								[value]="rowData[col.ind_code]"
								[ind_code]="col.ind_code"
							></indicateur-value-display>

						</ng-container>

						<ng-container *ngIf="!isLast">
							<!-- action -->
						</ng-container>
					</td>
				</tr>
			</ng-container>

			<ng-container *ngIf="rowData.mag_id && !rowData.hidden">
				<!-- declaration -->

				<tr
					class="row-child"
					[ngClass]="{
						'validation-magasin-manquante': !rowData.dme_date_validation_magasin,
						'validation-animateur-manquante': !rowData.dme_date_controle_animateur
					}"
					*ngIf="!rowData.dme_date_validation_magasin && rowData.mag_declaration_par_animateur"
				>
					<td [attr.colspan]="columns.length -1">
						La déclaration du mois pour ce magasin n'existe pas encore
						<button
							type="button"
							pButton
							class="p-button-sm middle"
							label="cliquez ici pour la créer"
							(click)="createDeclaration(rowData.mag_id)"
							title="Créer la déclaration pour ce magasin"
						></button>
					</td>
					<td class="text-center">
						<button
							type="button"
							pButton
							class="p-button-sm middle"
							label="Créer"
							(click)="createDeclaration(rowData.mag_id)"
							title="Créer la déclaration pour ce magasin"
						></button>
					</td>
				</tr>

				<tr
					class="row-child"
					[ngClass]="{
						'validation-magasin-manquante': !rowData.dme_date_validation_magasin,
						'validation-animateur-manquante': !rowData.dme_date_controle_animateur
					}"
					*ngIf="rowData.dme_date_validation_magasin || !rowData.mag_declaration_par_animateur"
				>
					<ng-container *ngFor="let col of columns; index as i; trackBy: indexTracker; last as isLast">
						<ng-container *ngIf="!isLast">

							<ng-container *ngIf="
								!col.editable
								|| !rowData.validatable
							">
								<td class="text-right col-indicateur" [ngClass]="{'font-italic': col.ind_code && col.ind_code.startsWith('dme_') && rowData[col.ind_code+'_origine'] != rowData[col.ind_code]}">
									<indicateur-value-display
										[value]="rowData[col.ind_code]"
										[ind_code]="col.ind_code"
									></indicateur-value-display>
								</td>
							</ng-container>
							<ng-container *ngIf="col.editable && rowData.validatable">
								<td
									class="text-right col-mois cell-input"
									[ngClass]="{'font-italic': col.ind_code && col.ind_code.startsWith('dme_') && rowData[col.ind_code+'_origine'] != rowData[col.ind_code]}"
									[ttEditableColumn]="rowData"
									[ttEditableColumnField]="col.ind_code"
								>
									<p-treeTableCellEditor>
										<ng-template pTemplate="input">
											<div class="p-input-icon-right input-container">
												<i class="pi ff-reset">{{col.ind_code|indicateur:'suffixe'}}</i>
												<input
													type="text"
													pInputText
													gzInputNumberFormatter
													[(ngModel)]="rowData[col.ind_code]"
													[ngModelOptions]="{standalone: true}"
													[decimals]="2"
													class="text-right"
												>
											</div>
										</ng-template>
										<ng-template pTemplate="output">
											<div class="flex align-items-baseline justify-content-end">
												<indicateur-value-display
													class=""
													[value]="rowData[col.ind_code]"
													[ind_code]="col.ind_code"
												></indicateur-value-display>
												<button
													type="button"
													pButton
													icon="pi pi-pencil"
													class="p-button-xs p-button-text"
												></button>
											</div>
										</ng-template>
									</p-treeTableCellEditor>

								</td>
							</ng-container>

						</ng-container>
						<ng-container *ngIf="isLast">
							<!-- action -->
							<td class="text-center">
								<ng-container *ngIf="!rowData.validatable">
									<i class="pi pi-info-circle middle" [pTooltip]="rowData.infoValidation"></i>
								</ng-container>
								<button
									type="button"
									pButton
									class="p-button-sm middle"
									label="Valider"
									(click)="validerDeclaration(rowData)"
									[pTooltip]="rowData.infoValidation"
									*ngIf="rowData.validatable"
								></button>
							</td>
						</ng-container>
					</ng-container>
				</tr>
			</ng-container>

		</ng-template>

		<!-- total -->
		<ng-template pTemplate="frozenfooter">
			<ng-container *ngIf="nodes && nodes.length > 1">
				<tr class="row-total">
					<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis">
						<b>Total ({{nbDeclarationsExistantes}} déclaration<span *ngIf="nbDeclarationsExistantes>1">s</span>)</b>
					</td>
				</tr>
			</ng-container>
		</ng-template>


		<ng-template pTemplate="footer" let-columns>

			<ng-container *ngIf="nodes && nodes.length > 1 && !!total">
				<tr	class="row-total">
					<td
						class="text-right col-indicateur"
						*ngFor="let col of columns; index as i; trackBy: indexTracker; last as isLast"
						[ngClass]="{'font-italic': col.ind_code && col.ind_code.startsWith('dme_') && total[col.ind_code+'_origine'] != total[col.ind_code]}"
					>
						<ng-container *ngIf="!isLast">
							<indicateur-value-display
								[value]="total[col.ind_code]"
								[ind_code]="col.ind_code"
							></indicateur-value-display>
						</ng-container>

						<ng-container *ngIf="!isLast">
							<!-- action -->
						</ng-container>
					</td>
				</tr>
			</ng-container>

		</ng-template>

	</p-treeTable>
</div>
