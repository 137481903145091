export const menuItems = [
	{
		label: 'Accueil',
		icon: 'pi pi-home',
		state: {
			name: 'cockpit'
		}
	},

	{
		label: 'Activité Magasin',
		state: {
			name: 'activite-magasin'
		}
	},

	// DECLARATIONS
	{
		label: 'Déclaration mensuelle',
		state: {
			abstract: 'declarations'
		},
		items: [
			{
				label: 'Synthèse annuelle',
				state: {
					name: 'synthese-annuelle'
				}
			},
			{
				label: 'Valider ma déclaration',
				state: {
					name: 'declaration-mensuelle-magasin'
				}
			},
			{
				label: 'Contrôle des déclarations',
				state: {
					name: 'controle-declaration-mensuelle-magasin'
				}
			}
		]
	},


	// STATISTIQUES
	{
		label: 'Statistiques',
		state: {
			abstract: 'reporting'
		},
		items: [
			{
				label: 'Reporting activité',
				state: {
					name: 'reporting-activite'
				}
			},
			{
				label: 'Classement magasins',
				state: {
					name: 'classement-magasins'
				}
			},
			{
				label: 'Classement vendeurs',
				state: {
					name: 'classement-vendeurs'
				}
			},
			{
				label: 'Classement SATCLI',
				state: {
					name: 'classement-satcli'
				}
			},
			{
				label: 'Indicateurs Digital',
				state: {
					name: 'indicateurs-digital'
				}
			},
			{
				label: 'Parrainage YuccanLead',
				state: {
					name: 'parrainage-yuccanlead'
				}
			},
		]
	},

	// MOA
	{
		label: 'MOA',
		state: {
			abstract: 'moa'
		},
		keepIfHasSingleChild: true,
		items: [
			// MA COMMUNICATION
			{
				label: 'Ma communication',
				state: {
					abstract: 'ma-communication'
				},
				keepIfHasSingleChild: true,
				items: [
					{
						label: 'Suivi communication locale',
						state: {
							name: 'suivi-communication-locale'
						}
					},
					{
						label: 'Statistiques communication/codes postaux',
						state: {
							name: 'stats-par-code-postal'
						}
					},

				]
			},

			// MON PILOTAGE
			{
				label: 'Mon pilotage',
				state: {
					abstract: 'mon-pilotage'
				},
				keepIfHasSingleChild: true,
				items: [
					{
						label: 'Potentiel CA magasin',
						state: {
							name: 'simulateur-potentiel-ca-magasin'
						}
					},
					{
						label: 'Préparation des objectifs',
						state: {
							name: 'simulateur-preparation-objectif-ca-annuel'
						}
					},
					{
						label: 'Objectifs vendeur',
						state: {
							name: 'objectifs-vendeur'
						}
					},
					{
						label: 'Note Google',
						state: {
							name: 'simulateur-note-google'
						}
					},
					{
						label: `Plan d'actions`,
						state: {
							name: 'plans-actions'
						}
					},
					{
						label: `Revue mensuelle d'activité commerciale`,
						state: {
							name: 'revue-mensuelle-activite-commerciale'
						}
					},
				]
			},

			// MA FINANCE
			{
				label: 'Ma finance',
				state: {
					abstract: 'ma-finance'
				},
				keepIfHasSingleChild: false,
				items: [
					{
						label: `Historique des encaissements`,
						state: {
							name: 'historique-encaissements'
						}
					},
					{
						label: `Projections des encaissements`,
						state: {
							name: 'projection-encaissements'
						}
					},
				]
			}
		]
	},

	// OBJECTIFS
	{
		label: 'Objectifs',
		state: {
			abstract: 'objectifs'
		},
		items: [
			{
				label: 'Objectifs magasin',
				state: {
					name: 'objectifs-magasin'
				}
			},
			{
				label: 'Objectifs nationaux',
				state: {
					name: 'objectifs-nationaux'
				}
			},
		]
	},

];
