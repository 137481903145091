import { Injectable } from '@angular/core';

import { DatePipe } from '@angular/common';

import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, flatMap, catchError } from 'rxjs/operators';

import { TimeAgoPipe } from '@helpers/time-ago.pipe';

import { AnalyticsService } from '@global/analytics.service';
import { MagasinService } from '@app/magasin/magasin.service';
import {
	calculateTaux,
	calculateTauxMarge,
	convertDateFieldsToDate,
	createDownloadFromHttpResponse,
	clone,
	isDefined,
	padString,
	percentTauxAndEvo,
	prepareQueryParams,
	stringSort,
} from '@helpers/utils';

export class Encaissement {
	ebi_id: number;
	mag_id: number;
	ebi_client: string;
	ebi_option: string;
	ebi_date: Date|null;
	annee: number;
	mois: number;
	ebi_type: string;
	ebi_montant: number|null;
}

export class MoisEncaissement {
	mag_id: number;
	annee: number;
	mois: number;
	A: Encaissement[] = [];
	S: Encaissement[] = [];
	P: Encaissement[] = [];
	total_encaissements: number;
	total_soldes: number;
	total_projections: number;
	projection_total_acomptes: number;
}

export class ProjectionEncaissement {
	pje_id: number|null = null;
	mag_id: number;
	pje_annee: number;
	pje_mois: number;
	pje_montant: number|null;

	constructor(
		mag_id: number,
		pje_annee: number,
		pje_mois: number,
		pje_montant: number|null = null
	) {
		this.mag_id = mag_id;
		this.pje_annee = pje_annee;
		this.pje_mois = pje_mois;
		this.pje_montant = pje_montant;
	}
}

@Injectable({ providedIn: 'root' })
export class ReportingService {

	indicateursReportingActivite: string[] = [
		'avg_devis_htple',
		'avg_vente_htple',
		// 'taux_nb_rdv_confirmes_nb_rdv_demandes',
		'nb_rdv_confirmes_magasin',
		'nb_rdv_confirmes_vendeur',
		'nb_rdv_internet_periode',
		'ca_prise_commande_htple',
		'ca_prise_commande_htple_b2b',
		'mt_achat_facture_ht',
		'mt_budget_ttc',
		'mt_devis_htple',
		'mt_devis_ttc',
		'mt_marge_achat_ht',
		'mt_ventes_htple',
		'nb_devis',
		'nb_entrees',
		'nb_prises_rdv',
		'nb_rdv_demandes',
		'nb_retours',
		'nb_ventes',
		'objectif_ca_prise_commande_htple',
		'rdv_internet',
		'mt_facture_htple',
		'mt_achats_commandes_ht',
		'mt_marge_commerciale_ht',
		// 'taux_marge_commerciale'
		// 'taux_marge_achat_ht',
	];

	indicateursParrainageYuccanLead: string[] = [
		'nb_ambassadeurs',
			'date_dernier_ambassadeur',
		'nb_preinscriptions_envoyees',
			'date_derniere_preinscription_envoyee',
		'nb_preinscriptions_acceptees',
			'date_derniere_preinscription_acceptee',
		'nb_mises_en_relation',
			'date_derniere_mise_en_relation',
		'nb_mises_en_relation_non_contacte',
		'nb_mises_en_relation_prospect_contacte',
		'nb_mises_en_relation_bon_commande_signe',
		// 'taux_concretisation',
	];

	constructor(
		private analyticsService: AnalyticsService,
		private datePipe: DatePipe,
		private http: HttpClient,
		private magasinService: MagasinService,
		private timeAgoPipe: TimeAgoPipe,
	) {

	}

	getReportingActivite(params: any) {
		let tmpParams = clone(params);
		if (tmpParams.regroupement == 'region') {
			tmpParams.regroupement = 'magasin';
		}
		tmpParams = prepareQueryParams(tmpParams);
		this.analyticsService.trackEvent('get_reporting_activite', tmpParams.params);
		return this.http.get<any>(`/reporting/activite`, tmpParams)
		.pipe(
			map((response: any) =>{
				response = this.prepareReportingActivite(response, params.regroupement);
				return response;
			})
		);
	}

	exportReportingActivite(params: any) {
		const tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_reporting_activite', tmpParams.params);
		return this.http.get<any>(`/reporting/activite/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	exportReportingActiviteGraphique = (params: number) => {
		let tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_graphique_cockpit_room', tmpParams.params);
		return this.http.get<any>(`/reporting/activite/export_graphique`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	prepareReportingActivite(activite: any, regroupement: string) {
		let tmpActivites: any[] = [];

		if (!activite.details.length) {
			return [];
		}

		if (regroupement == 'region' || regroupement == 'vendeur') {

			let tmpGroups: {[key: string]: any} = {};

			activite.details.forEach((detail: any) => {
				let groupKey: string;
				let groupLabel: string;

				if (regroupement == 'region') {
					groupKey = detail.mag_region_commerciale;
					groupLabel = detail.mag_region_commerciale;
				}
				else {
					// regrouper les vendeurs par magasins
					groupKey = detail.mag_nom_court;
					groupLabel = `${detail.mag_nom_court} (${detail.mag_id})`;
				}

				// préparation d'un groupe
				if (!tmpGroups[groupKey]) {
					tmpGroups[groupKey] = {
						key: groupKey,
						label: groupLabel,
						group: true,
						children: [],
					};
					this.indicateursReportingActivite.forEach((ind_code: string) => {
						tmpGroups[groupKey][ind_code] = 0;
					});
				}

				percentTauxAndEvo(detail);
				convertDateFieldsToDate(detail);

				// ajout des valeurs au total du groupe
				this.indicateursReportingActivite.forEach((ind_code: string) => {
					if (isDefined(detail[ind_code])) {
						tmpGroups[groupKey][ind_code] += detail[ind_code];
					}
				});

				if (regroupement == 'region' || regroupement == 'vendeur') {
					tmpGroups[groupKey].children.push(clone(detail));
				}
			});

			for (let groupKey in tmpGroups) {
				// calcul des taux des groupes
				this.calculateReportingActiviteGroup(tmpGroups[groupKey], regroupement);

				tmpGroups[groupKey].children.forEach((child: any) => {
					if (regroupement == 'region') {
						child.label = this.magasinService.getMagasinLabel(child);
					}
					else if (regroupement == 'vendeur') {
						child.label = `${child.uti_prenom} ${child.uti_nom}`;
					}
				});

				tmpActivites.push(tmpGroups[groupKey]);
			}

			stringSort(tmpActivites, 'key');

		}
		else {
			tmpActivites = [...activite.details];
		}

		if (regroupement == 'magasin') {
			tmpActivites.forEach((one: any)=>{
				one.label = this.magasinService.getMagasinLabel(one);
			});
		}

		activite.total.label = 'Total';

		let result = [activite.total, ...tmpActivites];
		percentTauxAndEvo(result);
		return result;
	}

	calculateReportingActiviteGroup(obj: any, regroupement: string) {
		calculateTaux(obj, 'mt_ventes_htple', 'objectif_ca_prise_commande_htple', 'taux_mt_ventes_htple_mt_objectif_htple');
		calculateTaux(obj, 'nb_ventes', 'nb_entrees');
		calculateTaux(obj, 'nb_devis', 'nb_entrees');
		calculateTaux(obj, 'nb_ventes', 'nb_devis');
		calculateTaux(obj, 'ca_prise_commande_htple', 'nb_ventes', 'avg_vente_htple');
		calculateTaux(obj, 'mt_devis_htple', 'nb_devis', 'avg_devis_htple');
		calculateTauxMarge(obj, 'ca_prise_commande_htple', 'mt_achats_commandes_ht', 'taux_marge_commerciale');
		calculateTauxMarge(obj, 'mt_ventes_htple', 'mt_achat_facture_ht', 'taux_marge_achat_ht');

		if (regroupement == 'vendeur') {
			calculateTaux(obj, 'nb_rdv_confirmes_vendeur', 'nb_rdv_demandes', 'taux_nb_rdv_confirmes_nb_rdv_demandes');
		}
		else {
			calculateTaux(obj, 'nb_rdv_confirmes_magasin', 'nb_rdv_demandes', 'taux_nb_rdv_confirmes_nb_rdv_demandes');
		}

	}

	prepareReportingResultat(resultat: any) {

		if (resultat['N']) {
			resultat['N'].mois.forEach((mois: any, index: number) => {
				percentTauxAndEvo(mois);
			});
			percentTauxAndEvo(resultat['N'].total);
		}

		if (resultat['N-1']) {
			resultat['N-1'].mois.forEach((mois: any, index: number) => {
				percentTauxAndEvo(mois);
			});
			percentTauxAndEvo(resultat['N-1'].total);
		}

		return resultat;
	}

	prepareReportingResultats(resultats: any[]) {
		resultats.forEach((one: any)=> {
			one = this.prepareReportingResultat(one);

		});
		return resultats;
	}

	getReportingResultat(params: any) {
		let tmpParams = clone(params);
		tmpParams = prepareQueryParams(tmpParams);
		this.analyticsService.trackEvent('get_synthese_annuelle', tmpParams.params);
		return this.http.get<any>(`/reporting/resultat`, tmpParams)
		.pipe(
			map((response: any) =>{
				response.magasins = this.prepareReportingResultats(response.magasins);
				response.total = this.prepareReportingResultat(response.total);
				return response;
			})
		);
	}

	exportReportingResultat(params: any) {
		let tmpParams = prepareQueryParams(params, true);
		this.analyticsService.trackEvent('export_synthese_annuelle', tmpParams.params);
		return this.http.get<any>(`/reporting/resultat/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	getRevueMensuelleActiviteCommerciale(params: any, mag_id: number) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/magasins/${mag_id}/revue_mensuelle`, tmpParams)
		.pipe(map((response: any) => {
				if (response['mois'] && response['cumul']) {
					this.prepareRevueMensuelleActivite(response['mois']);
					this.prepareRevueMensuelleActivite(response['cumul']);
				}
				return response;
			})
		)
	}

	prepareRevueMensuelleActivite(obj: any) {
		calculateTaux(obj, 'mt_ventes_htple', 'objectif_ca_prise_commande_htple', 'taux_mt_ventes_htple_mt_objectif_htple');
		calculateTaux(obj, 'nb_ventes', 'nb_entrees');
		calculateTaux(obj, 'nb_devis', 'nb_entrees');
		calculateTaux(obj, 'nb_ventes', 'nb_devis');
		calculateTaux(obj, 'ca_prise_commande_htple', 'nb_ventes', 'avg_vente_htple');
		calculateTaux(obj, 'mt_devis_htple', 'nb_devis', 'avg_devis_htple');
		calculateTauxMarge(obj, 'ca_prise_commande_htple', 'mt_achats_commandes_ht', 'taux_marge_commerciale');
		calculateTaux(obj, 'mt_marge_facture_ht', 'mt_facture_ht', 'taux_marge_facture');

		percentTauxAndEvo(obj);
	}

	getTop10(params: any, mag_id: number) {
		const tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/magasins/${mag_id}/stats_par_code_postal/top_10`, tmpParams)
	}

	getHistoriqueEncaissements(params: any, mag_id: number) {
		const tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/magasins/${mag_id}/historiques_encaissements`, tmpParams);
	}

	exportHistoriqueEncaissements(params: any, mag_id: number) {
		const tmpParams = prepareQueryParams(params, true);
		return this.http.get<any>(`/magasins/${mag_id}/historiques_encaissements/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

	getProjectionEncaissements(params: any, mag_id: number) {
		const tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/magasins/${mag_id}/projections_encaissements`, tmpParams)
	}

	postProjectionEncaissements(projection: ProjectionEncaissement) {
		return this.http.post<any>(`/magasins/${projection.mag_id}/projections_encaissements`, projection)
	}

	exportProjectionEncaissements(params: any, mag_id: number) {
		const tmpParams = prepareQueryParams(params, true);
		return this.http.get<any>(`/magasins/${mag_id}/projections_encaissements/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}



	getParrainageYuccanlead(params: any) {
		let tmpParams = prepareQueryParams(params);
		if (tmpParams.regroupement == 'region') {
			tmpParams.regroupement = 'magasin';
		}
		return this.http.get<any>(`/parrainage_yuccanlead`, tmpParams)
		.pipe(
			map((response: any) =>{
				response = this.prepareParrainageYuccanlead(response, params.regroupement);
				return response;
			})
		);
	}

	prepareParrainageYuccanlead(data: any, regroupement: string) {

		let tmpDetails: any[] = [];

		if (!data.details.length) {
			return {};
		}

		data.details.forEach((detail: any) => {
			this.prepareSingleParrainage(detail);
		});

		if (regroupement == 'region') {

			let tmpGroups: {[key: string]: any} = {};

			data.details.forEach((detail: any) => {

				let groupKey: string;
				let groupLabel: string;

				if (regroupement == 'region') {
					groupKey = detail.mag_region_commerciale;
					groupLabel = detail.mag_region_commerciale;

					// préparation d'un groupe
					if (!tmpGroups[groupKey]) {
						tmpGroups[groupKey] = {
							key: groupKey,
							label: groupLabel,
							group: true,
							children: [],
						};

						this.indicateursParrainageYuccanLead.forEach((ind_code: string) => {
							// préparation des champs date
							if (ind_code.startsWith('date_')) {
								tmpGroups[groupKey][ind_code] = null;
								tmpGroups[groupKey][`label_short_${ind_code}`] = '';
								tmpGroups[groupKey][`label_long_${ind_code}`] = '';
							}
							else {
								// et les autres
								tmpGroups[groupKey][ind_code] = 0;
							}
						});
					}

					// ajout des valeurs au total du groupe
					this.indicateursParrainageYuccanLead.forEach((ind_code: string) => {
						if (isDefined(detail[ind_code])) {

							if (ind_code.startsWith('date_')) {
								// on conserve les dates les plus récentes pour le groupe
								if (
									detail[ind_code]
									&& (
										!tmpGroups[groupKey][ind_code]
										|| tmpGroups[groupKey][ind_code] < detail[ind_code]
									)
								) {
									tmpGroups[groupKey][ind_code] = new Date(detail[ind_code]);
								}
							}

							else {
								// ou bien on somme
								tmpGroups[groupKey][ind_code] += detail[ind_code];
							}

						}
					});

					tmpGroups[groupKey].children.push(clone(detail));
				}

			});


			for (let groupKey in tmpGroups) {
				this.prepareSingleParrainage(tmpGroups[groupKey]);

				tmpGroups[groupKey].children.forEach((child: any) => {
					if (regroupement == 'region') {
						child.label = this.magasinService.getMagasinLabel(child);
					}
					else if (regroupement == 'vendeur') {
						child.label = `${child.uti_prenom} ${child.uti_nom}`;
					}
				});

				tmpDetails.push(tmpGroups[groupKey]);
			}

			stringSort(tmpDetails, 'key');

		}
		else {
			tmpDetails = [...data.details];
		}

		if (regroupement == 'magasin') {
			tmpDetails.forEach((one: any)=>{
				one.label = this.magasinService.getMagasinLabel(one);
			});
		}
		else if (regroupement == 'vendeur') {
			tmpDetails.forEach((one: any)=>{
				one.label = `${one.uti_prenom} ${one.uti_nom}`;
			});
		}

		data.details = tmpDetails;
		data.total.label = 'Total';
		percentTauxAndEvo(data.total);
		return data;
	}

	prepareSingleParrainage(item: any) {
		convertDateFieldsToDate(item);

		calculateTaux(
			item,
			'nb_mises_en_relation_bon_commande_signe',
			'nb_mises_en_relation_prospect_contacte',
			'taux_concretisation'
		);

		percentTauxAndEvo(item);

		this.indicateursParrainageYuccanLead.forEach((ind_code: string) => {
			if (ind_code.startsWith('date_') && item[ind_code]) {
				item[ind_code] = new Date(item[ind_code]);
				const dateString: string|null = this.datePipe.transform(item[ind_code], 'dd/MM/yyyy');
				item[`label_short_${ind_code}`] = this.timeAgoPipe.transform(item[ind_code]);

				let prefixe: string = 'il y a ';

				switch (ind_code) {
					case 'date_dernier_ambassadeur':
							prefixe = `Dernière inscription d’un ambassadeur ${prefixe}`;
						break;
					case 'date_derniere_preinscription_acceptee':
						prefixe = `Dernière invitation acceptée ${prefixe}`;
						break;
					case 'date_derniere_preinscription_envoyee':
						prefixe = `Dernière invitation envoyée ${prefixe}`;
						break;
					case 'date_derniere_mise_en_relation':
						prefixe = `Dernière mise en relation ${prefixe}`;
						break;
				}

				item[`label_long_${ind_code}`] = this.timeAgoPipe.transform(item[ind_code], false, prefixe) + ` (${dateString})`;
			}
		});
	}



	exportParrainageYuccanlead(params: any) {
		const tmpParams = prepareQueryParams(params, true);
		return this.http.get<any>(`/parrainage_yuccanlead/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}


}
